import React, { useEffect }                                        from "react";
import { InscriptionExpeditionTypeProps }                          from "../../../types/components/Hotel/InscriptionExpedition";
import { components, OptionProps, SingleValueProps, StylesConfig } from "react-select";
import ListeExpedition                                             from "./ListeExpedition";
import ListeOuvrier                                                from "./ListeOuvrier";
import SvgIcone                                                    from "../../../components/generality/SvgIcone";
import chroma                                                      from "chroma-js";
import { SoifType }                                                from "../../../types/components/Outils/OutilsExpeditionType";
import { InscriptionExpeditionContext }                            from "../../../types/Context/Hotel/Expedition/InscriptionExpeditionContext";
import { useGeneralContext }                                       from "../../../types/Context/GeneralContext";
import { CustomPagination }                                        from "../../../components/generality/Pagination";
import { InscriptionExpeditionApi }                                from "../../../services/api/InscriptionExpeditionApi";
import { Status_error, usePopUp }                                  from "../../../types/Context/PopUpContext";
import chargement                                                  from "../../../../img/chargement.svg";
import { CitoyensDTO }                                             from "../../../types/models/citoyens.dto";
import { TypeDispoDTO }                                            from "../../../types/models/typeDispo.dto";
import { ThemeUserDTO }                                            from "../../../types/models/themeUser.dto";
import { useTranslation }                                          from "react-i18next";
import { useMHFormat }                                             from "../../../services/hook/useMHFormat";
import { BoutonCopy }                                              from "../../../components/generality/ComposantGeneral";
import { UserPersoCouleurDTO }                                     from "../../../types/models/userPersoCouleur.dto";

const { SingleValue, Option } = components;


export interface InscriptionExpeditionProps {
    inscription: InscriptionExpeditionTypeProps;
}

export interface SizeCustomStyle {
    widthContainer?: number,
    widthInput?: number,
    widthIndicatorsContainer?: number,
    widthClearIndicator?: number,
    widthDropdownIndicator?: number,
    widthMenu?: number,
    widthControl?: number,
    widthSingleValue?: number,
    widthValueContainer?: number,
    height?: number
    minHeight?: number
}

export const customStyle = (theme: ThemeUserDTO, size?: SizeCustomStyle, feedBack?: boolean): StylesConfig => {
    if (!size) {
        size = {
            widthContainer          : 60,
            widthMenu               : 300,
            height                  : 20,
            minHeight               : 20,
            widthClearIndicator     : 20,
            widthControl            : 40,
            widthDropdownIndicator  : 20,
            widthIndicatorsContainer: 20,
            widthInput              : 20,
            widthSingleValue        : 20,
            widthValueContainer     : 20,
        };
    }
    if (!feedBack) {
        feedBack = false;
    }
    return {
        container          : (base) => ({
            ...base,
            width : `${size.widthContainer}px`,
            height: `${size.height}px`,
        }),
        input              : (base) => ({
            ...base,
            width: `${size.widthInput}px`,
        }),
        menu               : (base) => ({
            ...base,
            width          : `${size.widthMenu}px`,
            backgroundColor: "white",
            cursor         : "pointer",
            color          : "black",
            zIndex         : 100,
        }),
        menuList           : (provided: any) => ({
            ...provided,
            backgroundColor: "white",
            cursor         : "pointer",
            color          : "black",
        }),
        menuPortal         : (provided: any) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
            cursor         : "pointer",
        }),
        control            : (base) => ({
            ...base,
            width         : `${size.widthControl}px`,
            height        : `${size.height}px`,
            minHeight     : `${size.height}px`,
            display       : "flex",
            alignItems    : "center",
            justifyContent: "center",
            alignContent  : "center",
            padding       : 0,
            border        : feedBack ? `3px solid ${theme.succes_color}` : "1px solid black",
        }),
        indicatorsContainer: (base) => ({
            ...base,
            width         : `${size.widthIndicatorsContainer}px`,
            height        : `${size.height}px`,
            display       : (size.widthIndicatorsContainer === 0) ? "none" : "flex",
            justifyContent: "flex-end",
        }),
        clearIndicator     : (base) => ({
            ...base,
            width  : `${size.widthClearIndicator}px`,
            padding: 0,
            height : `${size.height}px`,
        }),
        dropdownIndicator  : (base) => ({
            ...base,
            width  : `${size.widthDropdownIndicator}px`,
            padding: 0,
            height : `${size.height}px`,
        }),
        singleValue        : (base) => {
            return {
                ...base,
                borderRadius   : 5,
                display        : "flex",
                flexDirection  : "row",
                alignItems     : "center",
                justifyContent : "flex-start",
                alignContent   : "center",
                backgroundColor: "white",
                background     : "none",
                color          : "black",
                width          : `${size.widthSingleValue}px`,
                padding        : 0,
                height         : `${size.height}px`,
            };
        },
        valueContainer     : (base) => ({
            ...base,
            color  : "white",
            width  : `${size.widthValueContainer}px`,
            padding: 0,
            height : `${size.height}px`,
        }),
        option             : (provided: any, { isSelected, isFocused, isDisabled }) => {
            const color = chroma("#2f6095");
            return {
                ...provided,
                display        : "flex",
                flexDirection  : "row",
                alignItems     : "center",
                backgroundColor: isDisabled ? "lightgrey" : isSelected ? color.alpha(0.3).css() : isFocused ? color.alpha(0.1).css() : "white",
                color          : isDisabled ? "grey" : "black",
                ":active"      : {
                    ...provided[":active"],
                    backgroundColor: !isDisabled && (isSelected ? color.alpha(0.3).css() : "white"),
                },
            };
        },
    };
};

export const customStyleDispo = (theme: ThemeUserDTO, size?: SizeCustomStyle, feedBack?: boolean, color?: string): StylesConfig<TypeDispoDTO, false> => {
    if (!size) {
        size = {
            widthContainer          : 60,
            widthMenu               : 300,
            height                  : 20,
            minHeight               : 20,
            widthClearIndicator     : 20,
            widthControl            : 40,
            widthDropdownIndicator  : 20,
            widthIndicatorsContainer: 20,
            widthInput              : 20,
            widthSingleValue        : 20,
            widthValueContainer     : 20,
        };
    }
    if (!feedBack) {
        feedBack = false;
    }
    return {
        container          : (base) => ({
            ...base,
            width : `${size.widthContainer}px`,
            height: `${size.height}px`,
        }),
        input              : (base) => ({
            ...base,
            width: `${size.widthInput}px`,
        }),
        menu               : (base) => ({
            ...base,
            width          : `${size.widthMenu}px`,
            backgroundColor: "white",
            //color          : "black",
            zIndex: 100,
            cursor: "pointer",
        }),
        menuList           : (provided: any) => ({
            ...provided,
            backgroundColor: "white",
            //color          : "black",
            cursor: "pointer",
        }),
        menuPortal         : (provided: any) => ({
            ...provided,
            backgroundColor: "white",
            //color          : "black",
            cursor: "pointer",
        }),
        control            : (base) => ({
            ...base,
            width          : `${size.widthControl}px`,
            height         : `${size.height}px`,
            minHeight      : `${size.height}px`,
            display        : "flex",
            alignItems     : "center",
            justifyContent : "center",
            alignContent   : "center",
            padding        : 0,
            border         : feedBack ? "3px solid lime" : "1px solid black",
            backgroundColor: color ? color : "white",
        }),
        indicatorsContainer: (base) => ({
            ...base,
            width  : `${size.widthIndicatorsContainer}px`,
            height : `${size.height}px`,
            display: (size.widthIndicatorsContainer === 0) ? "none" : "flex",
        }),
        clearIndicator     : (base) => ({
            ...base,
            width  : `${size.widthClearIndicator}px`,
            padding: 0,
            height : `${size.height}px`,
        }),
        dropdownIndicator  : (base) => ({
            ...base,
            width  : `${size.widthDropdownIndicator}px`,
            padding: 0,
            height : `${size.height}px`,
        }),
        singleValue        : (base) => {
            return {
                ...base,
                marginLeft     : 0,
                display        : "flex",
                flexDirection  : "row",
                alignItems     : "center",
                justifyContent : "center",
                alignContent   : "center",
                backgroundColor: "white",
                background     : "none",
                color          : "black",
                fontWeight     : "bold",
                width          : "100%",
                padding        : 0,
                height         : `${size.height}px`,
            };
        },
        option             : (styles, { data, isDisabled, isFocused, isSelected }) => {
            const colorCalc = calculColorDispo(data, theme.user_perso_couleur);
            const color = chroma(colorCalc);
            return {
                ...styles,
                backgroundColor: isSelected ? colorCalc : (isFocused ? color.alpha(0.3).css() : undefined),
                color          : isDisabled ? "#ccc" : isSelected ? chroma.contrast(colorCalc, "white") > 2 ? "white" : "black" : "black",
                cursor         : isDisabled ? "not-allowed" : "default",
                ":active"      : {
                    ...styles[":active"],
                    backgroundColor: isDisabled ? undefined : isSelected ? colorCalc : color.alpha(0.3).css(),
                },
            };
        },
        valueContainer     : (base) => ({
            ...base,
            color  : "white",
            width  : `${size.widthValueContainer}px`,
            padding: 0,
            height : `${size.height}px`,
        }),
    };
};

export const PseudoSingleValue = (props: SingleValueProps<CitoyensDTO>) => {
    return <SingleValue {...props}>
        <span>
            <span className={props.data.ban ? "color-red" : ""}>{props.data.citoyen.pseudo}</span>
            {props.data.ban && <SvgIcone icone={"h_ban"} />}
        </span>
    </SingleValue>;
};

export const PseudoOption = (props: OptionProps<CitoyensDTO>) => {
    return <Option {...props}>
        <span>
            <span className={props.data.ban ? "color-red" : ""}>{props.data.citoyen.pseudo}</span>
            {props.data.ban && <SvgIcone icone={"h_ban"} />}
        </span>
    </Option>;
};

export const SoifSingleValue = (props: SingleValueProps<SoifType>) => {
    return <SingleValue {...props}>
        <span><SvgIcone icone={props.data.icon} /></span>
    </SingleValue>;
};

export const SoifOption = (props: OptionProps<SoifType>) => {
    return <Option {...props}>
        <span><SvgIcone icone={props.data.icon} />{props.data.label}</span>
    </Option>;
};

export const DispoSingleValue = (props: SingleValueProps<TypeDispoDTO>) => {
    const { t } = useTranslation();
    return <SingleValue {...props}>
        <span>{t(props.data.nom, { ns: "jump" })}</span>
    </SingleValue>;
};

export const DispoOption = (props: OptionProps<TypeDispoDTO>) => {
    const { t } = useTranslation();
    return <Option {...props}>
        <span>{t(props.data.nom, { ns: "jump" })}</span>
    </Option>;
};

export const styleJob = {
    widthContainer          : 45,
    widthMenu               : 120,
    height                  : 24,
    minHeight               : 24,
    widthClearIndicator     : 20,
    widthControl            : 45,
    widthDropdownIndicator  : 20,
    widthIndicatorsContainer: 20,
    widthInput              : 20,
    widthSingleValue        : 24,
    widthValueContainer     : 24,
};
export const stylePseudo = {
    widthContainer          : 150,
    widthMenu               : 200,
    height                  : 24,
    minHeight               : 24,
    widthClearIndicator     : 20,
    widthControl            : 150,
    widthDropdownIndicator  : 0,
    widthIndicatorsContainer: 20,
    widthInput              : 20,
    widthSingleValue        : 150,
    widthValueContainer     : 150,
};

export const styleDispo = {
    widthContainer          : 40,
    widthMenu               : 70,
    height                  : 24,
    minHeight               : 24,
    widthClearIndicator     : 0,
    widthControl            : 40,
    widthDropdownIndicator  : 0,
    widthIndicatorsContainer: 0,
    widthInput              : 20,
    widthSingleValue        : 40,
    widthValueContainer     : 40,
};

export const calculColorDispo = (dispo: TypeDispoDTO, userOption: UserPersoCouleurDTO) => {
    if (!dispo) {
        return "#FFFFFF";
    }
    if (dispo.id === 24) {
        return userOption.color_dispo1;
    } else if (dispo.id === 14) {
        return userOption.color_dispo2;
    } else {
        return userOption.color_dispo3;
    }
};

export default function InscriptionExpedition({ inscription }: InscriptionExpeditionProps) {
    const { t } = useTranslation();
    const { general } = useGeneralContext();
    const { formatPseudoMH } = useMHFormat();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const [expeditions, setExpeditions] = React.useState(inscription.expeditions);
    const [ouvriers, setOuvriers] = React.useState(inscription.ouvriers);
    const [jourActuel, setJourActuel] = React.useState(general.ville.jour);
    const [faoDirection, setFaoDirection] = React.useState(inscription.direction_fao);
    const [loadData, setLoadData] = React.useState(false);
    const [ouvrierOpen, setOuvrierOpen] = React.useState(inscription.ouvert_ouvrier);
    
    
    const handlechangeJour = (jour: number) => {
        setLoadData(true);
        const inscriptionApi = new InscriptionExpeditionApi(general.ville.map_id);
        inscriptionApi.archive(general.ville.map_id, jour).then((response) => {
            setExpeditions(response.data.archive.expeditions);
            setOuvriers(response.data.archive.ouvriers);
            setFaoDirection(response.data.archive.direction_fao);
            setOuvrierOpen(response.data.archive.ouvert_ouvrier);
            setJourActuel(jour);
            setLoadData(false);
        }).catch((error) => {
            setStatus(Status_error);
            setMessagePopUp(error.data?.message || error.message);
            setShowPop(true);
        });
        
    };
    
    useEffect(() => {
        setExpeditions(inscription.expeditions);
        setOuvriers(inscription.ouvriers);
    }, [inscription.expeditions, inscription.ouvriers]);
    useEffect(() => {
        setFaoDirection(inscription.direction_fao);
    }, [inscription.direction_fao]);
    useEffect(() => {
        setOuvrierOpen(inscription.ouvert_ouvrier);
    }, [inscription.ouvert_ouvrier]);
    
    const optionsSoif: SoifType[] = [
        { value: 0, label: t("Non", { ns: "app" }), icon: "status_clean" },
        { value: 1, label: t("Oui", { ns: "app" }), icon: "r_dwater" },
    ];
    
    const recuperationCitoyensPreinscrit = () => {
        // On balaye les expeditions pour récupérer les citoyens préinscrits et on balaye également les ouvriers
        return Object.values(inscription.preinscritExpe);
    };
    
    const recuperationCitoyensPreinscritOuvrier = () => {
        // On balaye les ouvriers pour récupérer les citoyens préinscrits
        return ouvriers.sort((ouv_a, ouv_b) => {
            return ouv_a?.citoyen?.citoyen?.pseudo.localeCompare(ouv_b?.citoyen?.citoyen?.pseudo);
        }).map((ouvrier) => {
            if (ouvrier.citoyen !== null) {
                return ouvrier.citoyen;
            }
        });
    };
    
    const preinscrit = [...recuperationCitoyensPreinscrit(), ...recuperationCitoyensPreinscritOuvrier()];
    
    // Calcul des citoyens non inscrit en expédition ou en ouvrier
    const citoyensNonInscrit = inscription.citoyens.filter((citoyen) => {
        return !expeditions.some((expedition) => expedition.expedition_parts[0].expeditionnaires.some((expeditionnaire) => expeditionnaire?.citoyen?.citoyen.id === citoyen.citoyen.id)) && !ouvriers.some((ouvrier) => ouvrier?.citoyen?.citoyen.id === citoyen.citoyen.id);
    });
    
    // Fonction permettant de copier la liste des citoyens non inscrits pour le forum
    const handleCopiedFormatForum = (): string => {
        let listeCitoyens = "";
        citoyensNonInscrit.forEach((citoyen) => {
            listeCitoyens += `${formatPseudoMH(citoyen.citoyen)}<br/>`;
        });
        return listeCitoyens;
    };
    
    if (loadData) {
        return <div id={"zone_outils_expedition"}>
            <div className={"chargement_page"}>
                <img src={chargement} alt="Drapeau" />
                <span>{t("Chargement...", { ns: "app" })}</span>
            </div>
        </div>;
    }
    
    return <div id={"zone-inscription_expedition"}><InscriptionExpeditionContext.Provider
        value={{
            expeditions                          : expeditions,
            setExpeditions                       : setExpeditions,
            ouvriers                             : ouvriers,
            setOuvriers                          : setOuvriers,
            recuperationCitoyensPreinscrit       : recuperationCitoyensPreinscrit,
            recuperationCitoyensPreinscritOuvrier: recuperationCitoyensPreinscritOuvrier,
            optionsSoif                          : optionsSoif,
            jourActuel                           : jourActuel,
            faoDirection                         : faoDirection,
        }}>
        <div id={"zone_choix_jour"}>
            <h2>{t("Jour", { ns: "hotel" })}</h2>
            <CustomPagination jour={jourActuel} jour_max={general.ville.jour + 1} onChoixJour={selectedDay => handlechangeJour(selectedDay)} />
        </div>
        {!ouvrierOpen && expeditions.length === 0 && <h2>{t("Il n'y a pas d'expédition et de place ouvrier encore ouvertes, veuillez patienter !", { ns: "hotel" })}</h2>}
        {ouvrierOpen && expeditions.length === 0 && <h2>{t("Il n'y a pas encore de place en expédition ouverte, veuillez patienter !", { ns: "hotel" })}</h2>}
        {preinscrit.length > 0 && (expeditions.length > 0 || ouvriers.length > 0) && <fieldset>
            <legend>{t("Préinscrits", { ns: "hotel" })}</legend>
            <div id={"preinscrit_zone"}>{preinscrit.sort((cit_a, cit_b) => cit_a?.citoyen?.pseudo.localeCompare(cit_b?.citoyen?.pseudo)).map((citoyen: CitoyensDTO, index) => {
                if (citoyen?.citoyen) {
                    return <div key={citoyen.citoyen.id + "_" + index}>
                        <SvgIcone icone={"h_" + citoyen.job?.icon} />
                        <span>
                                <span className={citoyen.ban ? "color-red" : ""}>{citoyen.citoyen.pseudo}</span>
                            {citoyen.ban && <SvgIcone icone={"h_ban"} />}
                            </span>
                    </div>;
                } else {
                    return "";
                }
            })}</div>
        </fieldset>
        }
        {expeditions.length > 0 &&
            <ListeExpedition inscription={inscription} />
        }
        {
            ouvrierOpen && <ListeOuvrier inscription={inscription} />
        }
        {(expeditions.length > 0 || ouvriers.length > 0) && <>
            <fieldset>
                <legend>{t("Liste des non-inscrits", { ns: "hotel" })}</legend>
                <div id={"preinscrit_zone"}>{citoyensNonInscrit.sort((cit_a, cit_b) => cit_a?.citoyen?.pseudo.localeCompare(cit_b?.citoyen?.pseudo)).map((citoyen: CitoyensDTO, index) => {
                    if (citoyen?.citoyen) {
                        return <div key={citoyen.citoyen.id + "_" + index} className={"d-flex gap-0 align-items-center justify-content-center"}>
                            <SvgIcone icone={"h_" + citoyen.job?.icon} />
                            <span>
                                <span className={citoyen.ban ? "color-red" : ""}>{citoyen.citoyen.pseudo}</span>
                                {citoyen.ban && <SvgIcone icone={"h_ban"} />}
                            </span>
                        </div>;
                    } else {
                        return "";
                    }
                })}</div>
                <div className={"d-flex justify-content-center mt-2"}>
                    <BoutonCopy textAcopier={handleCopiedFormatForum()} libelle={t("Copier la liste version forum", { ns: "hotel" })} />
                </div>
            </fieldset>
        </>
        }
    </InscriptionExpeditionContext.Provider>
    </div>;
}

