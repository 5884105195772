import React, { useEffect, useState } from "react";
import { JumpOptionsListType }        from "../../../types/components/Jump/GestionJumpType";
import HTMLParser                     from "html-react-parser";
import SvgIcone                       from "../../../components/generality/SvgIcone";
import { Avatar, BoutonCopy }         from "../../../components/generality/ComposantGeneral";
import { formatInTimeZone }           from "date-fns-tz";
import { InscriptionJumpApi }         from "../../../services/api/InscriptionJumpApi";
import TriangleSort                   from "../../../components/generality/ComposantGeneral/TriangleSort";
import { Link }                       from "react-router-dom";
import { useGeneralContext }          from "../../../types/Context/GeneralContext";
import { JumpDTO }                    from "../../../types/models/jump.dto";
import { InscriptionJumpDTO }         from "../../../types/models/inscriptionJump.dto";
import { useTranslation }             from "react-i18next";
import { useMHFormat }                from "../../../services/hook/useMHFormat";
import TooltipGH                      from "../../../components/utils/TooltipGH";
import { HerosSkillLevelDTO }         from "../../../types/models/herosSkillLevel.dto";
import { HerosSkillTypeDTO }          from "../../../types/models/herosSkillType.dto";
import CompetenceBadge                from "../../../components/Citoyens/CompetencesBadge";

export default function GestionCandidature({ jump, options, onRetourPop, isArchMode = false }: {
    jump: JumpDTO,
    options: JumpOptionsListType,
    onRetourPop: (isErrorGestion: boolean, showModGestion: boolean, messagePopGestion: string) => void,
    isArchMode?: boolean,
}) {
    const { t } = useTranslation();
    const { general } = useGeneralContext();
    const { formatPseudoMH } = useMHFormat();
    const inscriptionJumpApi = new InscriptionJumpApi();
    
    const [aff_statut, setAff_statut] = useState(() => {
        // Initialisation d'aff_statut à partir d'options.listStatut
        return Object.values(options.listStatut).reduce((acc, statut) => {
            acc[statut.id] = true; // Initialisation de toutes les cases à cocher à true par défaut
            return acc;
        }, {});
    });
    const [sortedColumnCandi, setSortedColumnCandi] = useState(null);
    const [sortedOrderCandi, setSortedOrderCandi] = useState("default");
    const [listCandidature, setListCandidature] = useState(sortAndTriCandidature(jump.inscription_jumps, sortedColumnCandi, sortedOrderCandi));
    const [listJoueur, setListJoueur] = useState("");
    const [idInscUpdate, setIdInscUpdate] = useState(null);
    const [statusExtraction, setStatusExtraction] = useState(options.statutAcc);
    
    
    const handleChangeStatut = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        const statutId = parseInt(e.target.value, 10);
        
        // copie de la liste de candidature
        const listCandi = [...listCandidature];
        const candidature = listCandi[index];
        
        if (statutId === options.statutAcc) {
            const countAcc = Object.values(listCandi).filter((inscription: InscriptionJumpDTO) => inscription.statut.id === options.statutAcc).length;
            
            if (countAcc === options.maxAccepted) {
                onRetourPop(true, true, t("Le nombre de candidature accepté atteins, vous ne pouvez pas en accepter plus.", { ns: "jumpEvent" }));
                return;
            }
            
        }
        
        
        inscriptionJumpApi.majStatutInscription({ userId: candidature.user.id, idJump: jump.id, idStatut: statutId }).then((response) => {
            if (response.codeRetour === 0) {
                setIdInscUpdate(candidature.user.id);
                candidature.statut = options.listStatut.find((statut) => statut.id === statutId);
                listCandi[index] = candidature;
                setListCandidature(sortAndTriCandidature(listCandi, sortedColumnCandi, sortedOrderCandi));
                setTimeout(() => {
                    setIdInscUpdate(null);
                }, 1000);
            } else {
                onRetourPop(true, true, response.libRetour);
            }
        });
    };
    
    const handleGenereList = () => {
        const formattedJoueurList: string[] = listCandidature.filter((inscription: InscriptionJumpDTO) => inscription.statut.id === statusExtraction)
            .map((inscription: InscriptionJumpDTO) => (formatPseudoMH(inscription.user)));
        
        setListJoueur(formattedJoueurList.join(", "));
    };
    
    const handleSortDataCandidature = (column: string) => {
        // Si la colonne cliquée est la même que la colonne triée précédemment,
        // on inverse l'ordre de tri.
        const order = column === sortedColumnCandi && sortedOrderCandi === "asc" ? "desc" : (sortedOrderCandi === "desc" ? "default" : "asc");
        
        if (order === "default") {
            column = "";
        }
        
        // On met à jour les différents états
        setSortedColumnCandi(column);
        setSortedOrderCandi(order);
    };
    
    const getSortIndicatorClassCandidature = (column: string) => {
        if (column === sortedColumnCandi) {
            return sortedOrderCandi === "asc" ? "asc" : "desc";
        } else {
            return "default"; // Retourne une chaîne vide si la colonne n'est pas triée
        }
    };
    
    useEffect(() => {
        const listCandi = [...listCandidature];
        setListCandidature(sortAndTriCandidature(listCandi, sortedColumnCandi, sortedOrderCandi));
    }, [sortedColumnCandi, sortedOrderCandi]);
    
    useEffect(() => {
        setListCandidature(sortAndTriCandidature(jump.inscription_jumps, sortedColumnCandi, sortedOrderCandi));
    }, [jump]);
    
    // On filtre les compétences de l'utilisateur pour ne garder que les compétences de type type et on retourne la première compétence de la liste, et sinon on retourne la compétence de base
    const getLastestSkillLevel = (skillUser: HerosSkillLevelDTO[], skillType: HerosSkillTypeDTO, skillBase: HerosSkillLevelDTO[]) => {
        const skillUserFilter = skillUser.filter(skill => skill.heros_skill_type.id === skillType.id);
        return skillUserFilter.length === 0
            ? skillBase.find(skill => skill.heros_skill_type.id === skillType.id)
            : skillUserFilter.sort((a, b) => a.lvl - b.lvl).at(-1);
    };
    
    return <div id="gestionCandidature" className="fondWhite02">
        <h3>{t("Candidatures au jump", { ns: "jumpEvent" })}</h3>
        <div className="compteurCandidature">
            <h4>{t("Compteur des candidatures :", { ns: "jumpEvent" })}</h4>
            <table className="table table-responsive-sm">
                <thead>
                <tr>
                    <th scope="col"></th>
                    {Object.values(options.listStatut).map((statut) => {
                        return <th scope={"col"} key={"statut_" + statut.id}>{t(statut.nom_gestion_court, { ns: "jump" })}</th>;
                    })}
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th scope="row">{t("Nombre", { ns: "jumpEvent" })}</th>
                    {Object.values(options.listStatut).map((statut) => {
                        return <td className="compteurStatutGestion" key={"compteur_statut_" + statut.id}>{Object.values(listCandidature).filter(
                            (inscription) => inscription.statut.id === statut.id).length}</td>;
                    })}
                </tr>
                </tbody>
            </table>
        </div>
        <div className="filtreCandidature">
            <h4>{t("Afficher les candidatures aux statuts suivants :", { ns: "jumpEvent" })}</h4>
            <div id="filtreFormCandidature">
                <div className="listStatutFiltre">
                    {Object.values(options.listStatut).map((statut) => {
                        return <span key={"statut_" + statut.id}>
							<input
                                type="checkbox"
                                id={"statut_" + statut.id}
                                name={"statut_" + statut.id}
                                checked={aff_statut[statut.id]}
                                onChange={() => {
                                    // Mettez à jour aff_statut lorsque la case à cocher est cochée ou décochée
                                    setAff_statut((prevAffStatut) => ({
                                        ...prevAffStatut,
                                        [statut.id]: !prevAffStatut[statut.id],
                                    }));
                                }}
                            />
							<label htmlFor={"statut_" + statut.id}>{t(statut.nom_gestion_court, { ns: "jump" })}</label>
						</span>;
                    })}
                </div>
            </div>
        </div>
        <div>
            <table id="listingCandidatureTable">
                <thead>
                <tr>
                    <th className="col-candidaturePseudo">
                        <div className={"entete_tri"} onClick={() => handleSortDataCandidature("pseudo")}>
                            <div id={"entete_candidature_pseudo"}>{t("Pseudo", { ns: "jumpEvent" })}</div>
                            <TriangleSort direction={getSortIndicatorClassCandidature("pseudo")} />
                        </div>
                    </th>
                    <th className="col-candidatureBlocNote">{t("Bloc Note", { ns: "jumpEvent" })}</th>
                    <th className="col-candidatureDateCrea">
                        <div className={"entete_tri"} onClick={() => handleSortDataCandidature("date_crea")}>
                            <div id={"entete_candidature_date_crea"}>{t("Date créa.", { ns: "jumpEvent" })}</div>
                            <TriangleSort direction={getSortIndicatorClassCandidature("date_crea")} />
                        </div>
                    </th>
                    <th className="col-candidatureDateMod">
                        <div className={"entete_tri"} onClick={() => handleSortDataCandidature("date_mod")}>
                            <div id={"entete_candidature_date_mod"}>{t("Date mod.", { ns: "jumpEvent" })}</div>
                            <TriangleSort direction={getSortIndicatorClassCandidature("date_mod")} />
                        </div>
                    </th>
                    <th className="col-candidatureStatut-entete">
                        <div className={"entete_tri"} onClick={() => handleSortDataCandidature("statut")}>
                            <div id={"entete_candidature_statut"}>{t("Statut", { ns: "jumpEvent" })}</div>
                            <TriangleSort direction={getSortIndicatorClassCandidature("statut")} />
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {Object.values(listCandidature).filter((inscription) => aff_statut[inscription.statut.id]).map((candidature, index) => {
                    return <tr key={"candidature_" + candidature.user.id}>
                        <td className="col-candidaturePseudo">
                            <div className="zoneAvatarPseudoCandidature">
                                <div className="avatarCandidature"><Avatar url={candidature.user.avatar} /></div>
                                <div className="zonePseudoCandidature">
                                    <div className="pseudoCandidature d-flex gap-1 align-items-center">
                                        <span>
                                            <a href={"/ame/" + candidature.user.id_my_hordes}>{candidature.user.pseudo}</a>
                                            <a href={"https://myhordes.eu/jx/soul/" + candidature.user.id_my_hordes}>
                                                <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                            </a></span>
                                        <TooltipGH>
                                            <span className="infoBulle">
                                                <i className="fas fa-comment"></i>
                                            </span>
                                            <span className="info">{candidature.motivations === null ? "" : HTMLParser(candidature.motivations)}</span>
                                        </TooltipGH>
                                    
                                    </div>
                                    <div className="metierCandidature d-flex gap-1 align-items-center">
                                        <span>{t("Voeux :", { ns: "jumpEvent" })}</span>
                                        <TooltipGH>
                                            <span className="infoBulle">
                                                <SvgIcone icone={"h_" + candidature.voeux_metier1.icon} />
                                            </span>
                                            <span className="info">{t(candidature.voeux_metier1.nom, { ns: "game" })}</span>
                                        </TooltipGH>
                                        
                                        {candidature.voeux_metier2 !== null && <>
                                            <span>/</span>
                                            <TooltipGH>
                                                <span className="infoBulle">
                                                    <SvgIcone icone={"h_" + candidature.voeux_metier2.icon} />
                                                </span>
                                                <span className="info">{t(candidature.voeux_metier2.nom, { ns: "game" })}</span>
                                            </TooltipGH>
                                        </>}
                                        {candidature.voeux_metier3 !== null && <>
                                            <span>/</span>
                                            <TooltipGH>
                                                <span className="infoBulle">
                                                    <SvgIcone icone={"h_" + candidature.voeux_metier3.icon} />
                                                </span>
                                                <span className="info">{t(candidature.voeux_metier3.nom, { ns: "game" })}</span>
                                            </TooltipGH>
                                        </>}
                                    </div>
                                    <div className="dernierPouvoir d-flex gap-1 align-items-center flex-column">
                                        <span>{t("Compétences débloquées:", { ns: "jumpEvent" })}</span>
                                        <span className={"d-flex gap-1 align-items-center"}>{options.listSkill.map((type, index) => {
                                            const userSkill = (candidature.user.skill ?? []).length > 0 ?
                                                getLastestSkillLevel(candidature.user.skill, type, options.listSkillBase) :
                                                options.listSkillBase.find(skill => skill.heros_skill_type.id === type.id);
                                            return <CompetenceBadge skillLevel={userSkill} personnalisation={general.themeUser} key={index} />;
                                        })}</span>
                                    </div>
                                </div>
                                <div className="zoneActionCandidature">
                                    {isArchMode ? <Link to={"/jump/gestion/archive/" + jump.id + "/" + candidature.user.id} style={{ textDecoration: "none", color: "inherit" }}>
                                        <button className="voirCandiGestion" type={"button"}>{t("Voir candidature", { ns: "jumpEvent" })}</button>
                                    </Link> : <Link to={"/jump/gestion/" + jump.id + "/" + candidature.user.id} style={{ textDecoration: "none", color: "inherit" }}>
                                        <button className="voirCandiGestion" type={"button"}>{t("Voir candidature", { ns: "jumpEvent" })}</button>
                                    </Link>}
                                </div>
                            </div>
                        </td>
                        <td className="col-candidatureBlocNote">
                            {candidature.bloc_notes !== null && <div className="gestion_blocNote_candidature">{HTMLParser(candidature.bloc_notes)}</div>}
                        </td>
                        <td className="col-candidatureDateCrea">{formatInTimeZone(new Date(candidature.date_inscription), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
                        <td className="col-candidatureDateMod">{candidature.date_modification === null ? "" : formatInTimeZone(new Date(candidature.date_modification), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
                        <td className="col-candidatureStatut">
                            <div>
                                <div className="bordureSelectStatutGestionJump">
                                    {isArchMode ? <span>{t(candidature.statut.nom_gestion_court, { ns: "jump" })}</span> : (
                                        <select id={"statut_" + candidature.user.id}
                                                value={candidature.statut.id}
                                                onChange={(event) => handleChangeStatut(event, index)}
                                                style={(idInscUpdate === candidature.user.id) ? { border: "5px solid green" } : {}}
                                        >
                                            {Object.values(options.listStatut).map((statut) => {
                                                return <option value={statut.id}
                                                               key={"option_statut_" + candidature.user.id + "_" + statut.id}>{t(statut.nom_gestion_court, { ns: "jump" })}</option>;
                                            })}
                                        </select>)}
                                </div>
                            </div>
                        </td>
                    </tr>;
                })}
                </tbody>
            </table>
        </div>
        <fieldset>
            <legend>{t("Extraction des joueurs selon un statut", { ns: "jumpEvent" })}</legend>
            <div id={"zone-btn-extraction"}>
                <button type={"button"} className={"btn btn-warning btn-sm"} onClick={handleGenereList}>{t("Générer la liste", { ns: "jumpEvent" })}</button>
                <span>{t("pour les joueurs au statut", { ns: "jumpEvent" })}</span>
                <select id={"statutExtraction"} value={statusExtraction} onChange={(event) => setStatusExtraction(parseInt(event.target.value))}>
                    {Object.values(options.listStatut).map((statut) => {
                        return <option value={statut.id} key={"option_statut_" + statut.id}>{t(statut.nom_gestion_court, { ns: "jump" }).toLowerCase()}</option>;
                    })}
                </select>
            </div>
            <div id="contenuTextlistJoueur">{listJoueur}</div>
            <BoutonCopy textAcopier={listJoueur} />
        </fieldset>
    </div>;
    
    
}

function sortAndTriCandidature(listCandidature: InscriptionJumpDTO[], columnName: string, orderColumn: string) {
    
    const sortFunctions = {
        "pseudo"   : (a, b) => a.user.pseudo.localeCompare(b.user.pseudo),
        "date_mod" : (a, b) => {
            const aDateMod = (a.date_modification === null) ? new Date("1970-01-01") : new Date(a.date_modification);
            const bDateMod = (b.date_modification === null) ? new Date("1970-01-01") : new Date(b.date_modification);
            return aDateMod.getTime() - bDateMod.getTime();
        },
        "date_crea": (a, b) => {
            const aDateCrea = (a.date_inscription === null) ? new Date("1970-01-01") : new Date(a.date_inscription);
            const bDateCrea = (b.date_inscription === null) ? new Date("1970-01-01") : new Date(b.date_inscription);
            return aDateCrea.getTime() - bDateCrea.getTime();
        },
        "statut"   : (a, b) => a.statut.id - b.statut.id,
    };
    
    const sortFunction = sortFunctions[columnName];
    if (sortFunction) {
        return Object.values(listCandidature).sort((a, b) => {
            const result = sortFunction(a, b);
            return orderColumn === "asc" ? result : -result;
        });
    } else {
        // Nous trions d'abord par statut, puis par pseudo
        return Object.values(listCandidature).sort((a, b) => {
            if (a.statut.id !== b.statut.id) {
                return a.statut.id - b.statut.id;
            }
            return a.user.pseudo.localeCompare(b.user.pseudo);
        });
    }
    
}