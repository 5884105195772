import React, { useEffect, useState }                   from "react";
import Select, { components }                           from "react-select";
import HTMLParser                                       from "html-react-parser";
import SvgIcone                                         from "../../../components/generality/SvgIcone";
import { FieldControlType, InscriptionOptionsListType } from "../../../types/components/Jump/InscriptionJumpType";
import SelectTimeZone                                   from "../../../components/generality/ComposantGeneral/SelectTimeZone";
import { JobPrototypeDTO }                              from "../../../types/models/jobPrototype.dto";
import { UserDTO }                                      from "../../../types/models/user.dto";
import { TypeLeadDTO }                                  from "../../../types/models/typeLead.dto";
import { InscriptionJumpDTO }                           from "../../../types/models/inscriptionJump.dto";
import { JumpDTO }                                      from "../../../types/models/jump.dto";
import { RoleJumpDTO }                                  from "../../../types/models/roleJump.dto";
import { useTranslation }                               from "react-i18next";
import EditorComponent                                  from "../../../components/editor/editor";
import TooltipGH                                        from "../../../components/utils/TooltipGH";
import CompetenceBadge                                  from "../../../components/Citoyens/CompetencesBadge";
import { useGeneralContext }                            from "../../../types/Context/GeneralContext";
import { HerosSkillTypeDTO }                            from "../../../types/models/herosSkillType.dto";
import { HerosSkillLevelDTO }                           from "../../../types/models/herosSkillLevel.dto";

const { SingleValue, Option } = components;

export default function InscriptionForm({ inscriptionProps, options, jump, user, fieldControl, isOrga, onRetour, isArchMode = false }: {
    inscriptionProps: InscriptionJumpDTO,
    options: InscriptionOptionsListType,
    jump: JumpDTO,
    user: UserDTO,
    fieldControl: FieldControlType,
    isOrga: boolean,
    onRetour: (inscriptionRetour: InscriptionJumpDTO) => void,
    isArchMode?: boolean
}) {
    const { t } = useTranslation();
    const { general } = useGeneralContext();
    const [inscription, setInscription] = useState(inscriptionProps);
    const [isLeadChecked, setIsLeadChecked] = useState(false);
    const [isAppLeadChecked, setIsAppLeadChecked] = useState(false);
    const [fieldErrors, setFieldErrors] = useState<FieldControlType>(fieldControl);
    
    useEffect(() => {
        setFieldErrors(fieldControl);
    }, [fieldControl]);
    
    useEffect(() => {
        setInscription(inscriptionProps);
        Object.values(inscriptionProps.role_joueur_jumps).map((role) => {
            if (role.id === options.role_lead) {
                setIsLeadChecked(true);
            }
            if (role.id === options.role_applead) {
                setIsAppLeadChecked(true);
            }
        });
    }, [inscriptionProps]);
    
    const IconSingleValue = (props: any) => {
        const { t } = useTranslation();
        return <SingleValue {...props}>
            <SvgIcone icone={"h_" + props.data.icon} />
            <span>{t(props.data.alternatif, { ns: "jump" })}</span>
        </SingleValue>;
    };
    
    const IconOption = (props: any) => {
        const { t } = useTranslation();
        return <Option {...props}>
            <SvgIcone icone={"h_" + props.data.icon} />
            <span>{t(props.data.alternatif, { ns: "jump" })}</span>
        </Option>;
    };
    
    const IconSingleValuePouvoir = (props: any) => {
        const { t } = useTranslation();
        return <SingleValue {...props}>
            <SvgIcone icone={props.data.icon} />
            <span>{t(props.data.nom, { ns: "game" })}</span>
        </SingleValue>;
    };
    
    const IconOptionPouvoir = (props: any) => {
        const { t } = useTranslation();
        return <Option {...props}>
            <SvgIcone icone={props.data.icon} />
            <span>{t(props.data.nom, { ns: "game" })}</span>
        </Option>;
    };
    
    const customStyles = {
        option           : (provided: any) => ({
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: "white",
            color          : "black",
        }),
        singleValue      : (provided: any) => ({
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: "white",
            color          : "black",
        }),
        control          : (provided: any) => ({
            ...provided,
            minHeight: "30px",
            height   : "30px",
            minWidth : "170px",
            maxwidth : "200px",
        }),
        input            : (provided: any) => ({
            ...provided,
            gridTemplateColumns: "none",
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            padding: "0",
        }),
        valueContainer   : (provided: any) => ({
            ...provided,
            padding: "0px 8px",
        }),
        menu             : (provided: any) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
        menuList         : (provided: any) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
        menuPortal       : (provided: any) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
    };
    
    
    const customStylesFuseau = {
        option           : (provided: any, state: any) => ({
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: state.isDisabled ? "lightgrey" : "white",
            color          : state.isDisabled ? "grey" : "black",
            ":active"      : {
                ...provided[":active"],
                backgroundColor: !state.isDisabled && (state.isSelected ? "data.color" : "white"),
            },
        }),
        singleValue      : (provided: any) => ({
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: "white",
            color          : "black",
        }),
        control          : (provided: any) => ({
            ...provided,
            minHeight: "24px",
            height   : "24px",
            width    : "400px",
        }),
        input            : (provided: any) => ({
            ...provided,
            gridTemplateColumns: "none",
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            padding: "0",
        }),
        valueContainer   : (provided: any) => ({
            ...provided,
            padding: "0px 8px",
        }),
        menu             : (provided: any) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
            width          : "400px",
        }),
        menuList         : (provided: any) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
        menuPortal       : (provided: any) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
    };
    
    const handleInscriptionChange = (updatedProperties: Partial<InscriptionJumpDTO>) => {
        const inscriptionMod = { ...inscription, ...updatedProperties };
        setInscription(inscriptionMod);
        onRetour(inscriptionMod);
    };
    
    const generateSelect = (type_creneau: number, creneau_id: number, isModifiable: boolean) => {
        const dispoJoueur = Object.values(inscription.dispo);
        const dispoJoueurValue = dispoJoueur.find((dispo) => dispo.type_creneau === type_creneau && dispo.creneau.id === creneau_id);
        return ((isModifiable)) ? (
            <select key={"type_" + type_creneau + "_creneau_" + creneau_id}
                    value={dispoJoueurValue?.dispo?.id}
                    onChange={(event) => {
                        const dispos = [...inscription.dispo];
                        const indexMod = Object.values(dispos).findIndex((dispo) => dispo.type_creneau === type_creneau && dispo.creneau.id === creneau_id);
                        
                        dispos[indexMod].dispo = Object.values(options.listDispo).find((dispo) => dispo.id === parseInt(event.target.value, 10));
                        
                        handleInscriptionChange({ dispo: dispos });
                    }}
            >
                {Object.values(options.listDispo).map((dispo) => {
                    return <option value={dispo.id} key={"type_" + type_creneau + "_creneau_" + creneau_id + "_dispo_" + dispo.id}>{t(dispo.nom, { ns: "jump" })}</option>;
                })}
            </select>
        ) : (
            <div className={"dispoCandidature"}><span>{t(dispoJoueurValue.dispo.nom, { ns: "jump" })}</span></div>
        );
    };
    
    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>, role: RoleJumpDTO) => {
        if (event.target.checked) {
            // Ajouter un rôle
            const newRole = role;
            if (role.id === options.role_lead) {
                setIsLeadChecked(true);
            }
            if (role.id === options.role_applead) {
                setIsAppLeadChecked(true);
            }
            handleInscriptionChange({
                role_joueur_jumps: [...inscription.role_joueur_jumps, newRole],
            });
        } else {
            // Supprimer un rôle
            const filteredRoles = inscription.role_joueur_jumps.filter(r => r.id !== role.id);
            if (role.id === options.role_lead) {
                setIsLeadChecked(false);
            }
            if (role.id === options.role_applead) {
                setIsAppLeadChecked(false);
            }
            handleInscriptionChange({
                role_joueur_jumps: filteredRoles,
            });
        }
    };
    
    const handleLeadChange = (event: React.ChangeEvent<HTMLInputElement>, lead: TypeLeadDTO, isApprenti = false) => {
        if (event.target.checked) {
            // Ajouter un lead
            const newLead = {
                user     : user,
                lead     : lead,
                type_lead: lead,
                apprenti : isApprenti,
            };
            handleInscriptionChange({
                lead_inscriptions: [...inscription.lead_inscriptions, newLead],
            });
        } else {
            // Supprimer un lead
            const filteredLeads = inscription.lead_inscriptions.filter(l => !(l.lead?.id === lead.id));
            handleInscriptionChange({
                lead_inscriptions: filteredLeads,
            });
        }
    };
    
    
    const isModifiable = isArchMode ? false : (isOrga ? false : inscription.statut.id < 10);
    const blocModif = isArchMode ? true : (isOrga ? true : inscription.statut.id === 99);
    const jumpInEvent = jump.event !== null;
    
    let optionOneMetier = false;
    if (jumpInEvent) {
        optionOneMetier = jump.event.one_metier;
    }
    
    let optionJobFix = false;
    if (jumpInEvent) {
        optionJobFix = jump.job_specific;
    }
    
    useEffect(() => {
        if (optionJobFix) {
            handleInscriptionChange({ voeux_metier1: jump.job[0] });
        }
    }, [optionJobFix]);
    
    const getLastestSkillLevel = (skillUser: HerosSkillLevelDTO[], skillType: HerosSkillTypeDTO, skillBase: HerosSkillLevelDTO[]) => {
        const skillUserFilter = skillUser.filter(skill => skill.heros_skill_type.id === skillType.id);
        return skillUserFilter.length === 0
            ? skillBase.find(skill => skill.heros_skill_type.id === skillType.id)
            : skillUserFilter.sort((a, b) => a.lvl - b.lvl).at(-1);
    };
    
    return <>
        <fieldset id={"generalInfoInscriptionJoueur"} disabled={!isModifiable}>
            <legend>{t("Information générale sur vous", { ns: "jumpEvent" })}</legend>
            <div id={"gouleBanCommuInscription"}>
                {!jumpInEvent && <div>
                    <label htmlFor="inscription_jump_gouleCommu">{t("Volontaire goule communautaire :", { ns: "jumpEvent" })}</label>
                    {(isModifiable) ? (
                        <input type="checkbox"
                               id="inscription_jump_gouleCommu"
                               name="inscription_jump[gouleCommu]"
                               checked={inscription.goule_commu ?? false}
                               onChange={(event) => handleInscriptionChange({ goule_commu: event.target.checked })} />
                    ) : (
                        (inscription.goule_commu ?? false) ? (
                            <div><i className="fa-solid fa-check color-green icone_citoyen_centre"></i></div>
                        ) : (
                            <div><i className="fa-solid fa-xmark color-red icone_citoyen_centre fa-lg"></i></div>
                        )
                    )
                    }
                </div>}
                {!jumpInEvent && <div>
                    <label htmlFor="inscription_jump_banCommu">{t("Volontaire banni communautaire :", { ns: "jumpEvent" })}</label>
                    {(isModifiable) ? (
                        <input type="checkbox"
                               id="inscription_jump_banCommu"
                               name="inscription_jump[banCommu]"
                               checked={inscription.ban_commu ?? false}
                               onChange={(event) => handleInscriptionChange({ ban_commu: event.target.checked })} />
                    ) : (
                        (inscription.ban_commu ?? false) ? (
                            <div><i className="fa-solid fa-check color-green icone_citoyen_centre"></i></div>
                        ) : (
                            <div><i className="fa-solid fa-xmark color-red icone_citoyen_centre fa-lg"></i></div>
                        )
                    )
                    }

                </div>}
                {isOrga && <>
                    {/*<div>
                     <label>{t("Légendaire :", { ns: "jumpEvent" })}</label>
                     {(inscription.user.legend ?? false) ? (
                     <div><i className="fa-solid fa-check color-green icone_citoyen_centre"></i></div>
                     ) : (
                     <div><i className="fa-solid fa-xmark color-red icone_citoyen_centre fa-lg"></i></div>
                     )
                     }
                     </div>*/}
                    <div>
                        <label>{t("Témoin de l'arma :", { ns: "jumpEvent" })}</label>
                        {(inscription.user.tem_arma ?? false) ? (
                            <div><i className="fa-solid fa-check color-green icone_citoyen_centre"></i></div>
                        ) : (
                            <div><i className="fa-solid fa-xmark color-red icone_citoyen_centre fa-lg"></i></div>
                        )
                        }
                    </div>
                </>}
            </div>
            <div id={"voeuxMetierInscription"}>
                {!optionJobFix &&
                    <div id="infoMetierInscriptionJump"><em>{t("Mettez les métiers du plus important au moins important pour vous, ne mettre qu'une seule fois le même métier, les métiers seront attribués selon les disponibilités, le nombre de personnes choississant le métier, vos motivations, le type de ville, etc.", { ns: "jumpEvent" })}</em>
                    </div>}
                <div>
                    {optionJobFix && <div className={"listChoixMetier_inscription"}>
                        <div>
                            <label htmlFor="inscription_jump_voeuxMetier1">{t("Inscription pour le métier :", { ns: "jumpEvent" })}</label>
                            <div className={"d-flex gap-1 align-items-center"}><SvgIcone icone={"h_" + (jump.job[0]?.icon ?? "")} /> <span>{t(jump.job[0]?.nom ?? "", { ns: "game" })}</span></div>
                        </div>
                    </div>}
                    {!optionJobFix && <div className={"listChoixMetier_inscription"}>
                        <div>
                            <label htmlFor="inscription_jump_voeuxMetier1">{optionOneMetier ? t("Choix métier :", { ns: "jumpEvent" }) : t("Voeu métier n°1 :", { ns: "jumpEvent" })}</label>
                            {(isModifiable) ? (
                                <>
                                    <Select value={inscription.voeux_metier1}
                                            id={"inscription_jump_voeuxMetier1"}
                                            name={"inscription_jump_voeuxMetier1"}
                                            options={options.listJob}
                                            components={{ SingleValue: IconSingleValue, Option: IconOption }}
                                            getOptionLabel={(job) => job.alternatif}
                                            onChange={(optionSelected: JobPrototypeDTO) => handleInscriptionChange({ voeux_metier1: optionSelected })}
                                            styles={customStyles} />
                                    {fieldErrors.choix_metier1 && <div className={"color-red"} style={{ marginTop: "8px" }}>{fieldErrors.choix_metier1}</div>}
                                </>
                            ) : (
                                <div className={"d-flex gap-1 align-items-center"}><SvgIcone icone={"h_" + inscription.voeux_metier1.icon} /> <span>{t(inscription.voeux_metier1.nom, { ns: "game" })}</span></div>
                            )
                            }
                        </div>
                    </div>}
                    {!optionOneMetier && <div className={"listChoixMetier_inscription"}>
                        <div>
                            <label htmlFor="inscription_jump_voeuxMetier2">{t("Voeu métier n°2 :", { ns: "jumpEvent" })}</label>
                            {(isModifiable) ? (
                                <>
                                    <Select value={inscription.voeux_metier2}
                                            id={"inscription_jump_voeuxMetier2"}
                                            isClearable={true}
                                            name={"inscription_jump_voeuxMetier2"}
                                            options={options.listJob}
                                            components={{ SingleValue: IconSingleValue, Option: IconOption }}
                                            getOptionLabel={(job) => job.alternatif}
                                            onChange={(optionSelected: JobPrototypeDTO) => handleInscriptionChange({ voeux_metier2: optionSelected })}
                                            styles={customStyles} />
                                    {fieldErrors.choix_metier2 && <div className={"color-red"} style={{ marginTop: "8px" }}>{fieldErrors.choix_metier2}</div>}
                                </>
                            ) : (
                                (inscription.voeux_metier2 === null) ? null : (<div className={"d-flex gap-1 align-items-center"}><SvgIcone icone={"h_" + inscription.voeux_metier2.icon} /> <span>{t(inscription.voeux_metier2.nom, { ns: "game" })}</span></div>)
                            )
                            }
                        </div>
                    </div>}
                    {!optionOneMetier && <div className={"listChoixMetier_inscription"}>
                        <div>
                            <label htmlFor="inscription_jump_voeuxMetier3">{t("Voeu métier n°3 :", { ns: "jumpEvent" })}</label>
                            {(isModifiable) ? (
                                <><Select value={inscription.voeux_metier3}
                                          id={"inscription_jump_voeuxMetier3"}
                                          isClearable={true}
                                          name={"inscription_jump_voeuxMetier3"}
                                          options={options.listJob}
                                          components={{ SingleValue: IconSingleValue, Option: IconOption }}
                                          getOptionLabel={(job) => job.alternatif}
                                          onChange={(optionSelected: JobPrototypeDTO) => handleInscriptionChange({ voeux_metier3: optionSelected })}
                                          styles={customStyles} />
                                    {fieldErrors.choix_metier3 && <div className={"color-red"} style={{ marginTop: "8px" }}>{fieldErrors.choix_metier3}</div>}
                                </>
                            ) : (
                                (inscription.voeux_metier3 === null) ? null : (<div className={"d-flex gap-1 align-items-center"}><SvgIcone icone={"h_" + inscription.voeux_metier3.icon} /> <span>{t(inscription.voeux_metier3.nom, { ns: "game" })}</span></div>)
                            )
                            }
                        </div>
                    </div>}
                </div>
            </div>
            <div id="pouvoirLevlRuineInscriptionJump">
                <div id={"listLevelRuine_inscription"}>
                    <label htmlFor="inscription_jump_lvlRuine">{t("Votre niveau de ruine :", { ns: "jumpEvent" })}</label>
                    {(isModifiable) ? (
                        <>
                            <select id="inscription_jump_lvlRuine"
                                    name="inscription_jump_lvlRuine"
                                    value={inscription.lvl_ruine?.id ?? -1}
                                    onChange={(event) => {
                                        const id_lvlRuine = parseInt(event.target.value, 10) ?? 0;
                                        handleInscriptionChange({ lvl_ruine: Object.values(options.listLvlRuine).find((lvlRuine) => lvlRuine.id === id_lvlRuine) });
                                    }}
                            >
                                <option value={-1}>{t("choix niveau de ruine", { ns: "jumpEvent" })}</option>
                                {Object.values(options.listLvlRuine)
                                    .map((lvlRuine) => <option value={lvlRuine.id} key={"level_ruine_" + lvlRuine.id}>{lvlRuine.level} - {t(lvlRuine.nom, { ns: "jump" })}</option>)}
                            </select>
                            <TooltipGH>
                                <span className="infoBulle">
                                    <i className="fas fa-question-circle"></i>
                                </span>
                                <span className="infoInscription">{HTMLParser(options.helpRuine)}</span>
                            </TooltipGH>
                            {fieldErrors.niveau_ruine && <div className={"color-red"} style={{ marginTop: "8px" }}>{fieldErrors.niveau_ruine}</div>}
                        </>
                    ) : (<div>{inscription.lvl_ruine.level} - {t(inscription.lvl_ruine.nom, { ns: "jump" })}</div>)
                    }
                </div>
                <div className={"listPouvoir_inscription"}>
                    <div className={"d-flex gap-2 align-items-center justify-content-start"}>
                        <span className={"d-flex gap-1 align-items-center"}>
                            <span>{t("Vos compétences débloquées", { ns: "jumpEvent" })}</span>
                            <TooltipGH>
                                <span className="infoBulle">
                                    <i className="fas fa-question-circle"></i>
                                </span>
                                <span className="infoInscription">{t("Si ce n'est pas à jour, il vous suffit de mettre à jour dans votre espace personnel", { ns: "jumpEvent" })}</span>
                            </TooltipGH>
                        </span>
                        <span className={"d-flex gap-1 align-items-center"}>{options.listSkill.map((type, index) => {
                            const userSkill = (inscription.user.skill ?? []).length > 0 ?
                                getLastestSkillLevel(inscription.user.skill, type, options.listSkillBase) :
                                options.listSkillBase.find(skill => skill.heros_skill_type.id === type.id);
                            
                            return <CompetenceBadge skillLevel={userSkill} personnalisation={general.themeUser} key={index} />;
                        })}</span>
                    </div>
                </div>
                <div className={"listPouvoir_inscription"}>
                    <div className={"d-flex gap-2 align-items-center justify-content-start"}>
                        <span className={"d-flex gap-1 align-items-center"}>
                            <span>{t("Vos points de compétence actuels :", { ns: "jumpEvent" })}</span>
                            <span className={"d-flex gap-1 align-items-center"}>{inscription.user.nbr_points_competence ?? 0}</span>
                            <TooltipGH>
                                <span className="infoBulle">
                                    <i className="fas fa-question-circle"></i>
                                </span>
                                <span className="infoInscription">{t("Si ce n'est pas à jour, il vous suffit de mettre à jour dans votre espace personnel", { ns: "jumpEvent" })}</span>
                            </TooltipGH>
                        </span>
                    
                    </div>
                </div>
            </div>
        </fieldset>
        {!jumpInEvent && <fieldset id="dispoTypeInscription" disabled={inscription.statut.id >= 10}>
            <legend>{t("Vos disponibilités types", { ns: "jumpEvent" })}</legend>
            <div className="intituleDispoInscription">{t("Disponibilité type en semaine", { ns: "jumpEvent" })}</div>
            <div>
                <div className="ligneDispoInscription">
                    {Object.values(jump.creneau_semaine).map((creneau) => {
                        return <div className="colonneDispoInsciption" key={"creneau_semaine_" + creneau.id}>
                            <div><span>{HTMLParser(creneau.libelle)}</span></div>
                            {generateSelect(1, creneau.id, isModifiable)}
                        </div>;
                    })}
                </div>
            </div>
            {fieldErrors.dispo_semaine && <div className={"color-red"} style={{ marginTop: "8px" }}>{fieldErrors.dispo_semaine}</div>}
            <div className="intituleDispoInscription" id="titreDispoWeekendInscription">{t("Disponibilité type en week-end", { ns: "jumpEvent" })}</div>
            <div>
                <div className="ligneDispoInscription">
                    {Object.values(jump.creneau_weekend).map((creneau) => {
                        return <div className="colonneDispoInsciption" key={"creneau_weekend_" + creneau.id}>
                            <div><span>{HTMLParser(creneau.libelle)}</span></div>
                            {generateSelect(2, creneau.id, isModifiable)}
                        </div>;
                    })}
                </div>
            </div>
            {fieldErrors.dispo_weekend && <div className={"color-red"} style={{ marginTop: "8px" }}>{fieldErrors.dispo_weekend}</div>}
            <div className="fuseau_inscription">
                <label>{t("Votre fuseau horaire :", { ns: "jumpEvent" })}</label>
                <SelectTimeZone timeZone={inscription.fuseau} disabled={blocModif} style={customStylesFuseau}
                                onUpdate={(timezoneChoice) => handleInscriptionChange({ fuseau: timezoneChoice })} />
            </div>
        </fieldset>}
        <fieldset id="roleInscription">
            <legend>{t("Votre ou vos rôle(s) durant la ville", { ns: "jumpEvent" })}</legend>
            <div>
                <div>
                    <label className="required">{t("Choix des roles :", { ns: "jumpEvent" })}</label>
                    <div className={"list_lead_inscription"}>
                        {Object.values(options.listRole).map((role) => {
                            const checked = inscription.role_joueur_jumps.find(r => r.id === role.id) !== undefined;
                            return <div key={"role_" + role.id} className={"groupement_lead_label_input"}>
                                {(blocModif) ? (
                                    (checked) ? (
                                        <div><i className="fa-solid fa-check color-green"></i></div>
                                    ) : (
                                        <div><i className="fa-solid fa-xmark color-red fa-lg"></i></div>
                                    )
                                ) : (
                                    <input id={"inscription_jump_roleVille_" + role.id}
                                           key={"inscription_jump_roleVille_" + role.id}
                                           name={"inscription_jump_roleVille_" + role.id}
                                           type={"checkbox"}
                                           value={role.id}
                                           checked={checked}
                                           onChange={(event) => handleRoleChange(event, role)}
                                    />
                                )}
                                <label htmlFor={"inscription_jump_roleVille_" + role.id} className={"d-flex gap-1 align-items-center"}>{role.icon !== "" && <SvgIcone icone={role.icon} />}<span>{t(role.nom, { ns: "jump" })}</span></label>
                            </div>;
                        })}
                    </div>
                </div>
                {fieldErrors.role && <div className={"color-red"} style={{ marginTop: "8px" }}>{fieldErrors.role}</div>}
            </div>
            {isLeadChecked && <div>
                <div>
                    <label className="required">{t("Choix des leads :", { ns: "jumpEvent" })}</label>
                    <div className={"list_lead_inscription"}>
                        {Object.values(options.listLead).map((lead) => {
                            const checked = inscription.lead_inscriptions.find(
                                l => (l.lead?.id === lead.id) && l.apprenti === false) !== undefined;
                            return <div key={"lead_" + lead.id} className={"groupement_lead_label_input"}>
                                {(blocModif) ? (
                                    (checked) ? (
                                        <div><i className="fa-solid fa-check color-green"></i></div>
                                    ) : (
                                        <div><i className="fa-solid fa-xmark color-red fa-lg"></i></div>
                                    )
                                ) : (
                                    <input id={"inscription_jump_lead_" + lead.id}
                                           key={"inscription_jump_lead_" + lead.id}
                                           name={"inscription_jump_lead_" + lead.id}
                                           type={"checkbox"}
                                           value={lead.id}
                                           checked={checked}
                                           onChange={(event) => handleLeadChange(event, lead, false)}
                                    />
                                )}
                                <label htmlFor={"inscription_jump_lead_" + lead.id} className={"d-flex gap-1 align-items-center"}><SvgIcone icone={lead.icon} /><span>{t(lead.nom, { ns: "jump" })}</span></label>
                            </div>;
                        })}
                    </div>
                </div>
                {fieldErrors.lead && <div className={"color-red"} style={{ marginTop: "8px" }}>{fieldErrors.lead}</div>}
            </div>}
            {isAppLeadChecked && <div>
                <div>
                    <label className="required">{t("Choix des apprentis leads :", { ns: "jumpEvent" })}</label>
                    <div className={"list_lead_inscription"}>
                        {Object.values(options.listLead).map((lead) => {
                            const checked = inscription.lead_inscriptions.find(
                                l => (l.lead?.id === lead.id) && l.apprenti === true) !== undefined;
                            return <div key={"app_lead_" + lead.id} className={"groupement_lead_label_input"}>
                                {(blocModif) ? (
                                    (checked) ? (
                                        <div><i className="fa-solid fa-check color-green"></i></div>
                                    ) : (
                                        <div><i className="fa-solid fa-xmark color-red fa-lg"></i></div>
                                    )
                                ) : (
                                    <input id={"inscription_jump_applead_" + lead.id}
                                           key={"inscription_jump_applead_" + lead.id}
                                           name={"inscription_jump_applead_" + lead.id}
                                           type={"checkbox"}
                                           value={lead.id}
                                           checked={checked}
                                           onChange={(event) => handleLeadChange(event, lead, true)}
                                    />
                                )}
                                <label htmlFor={"inscription_jump_applead_" + lead.id} className={"d-flex gap-1 align-items-center"}><SvgIcone icone={lead.icon} /><span>{t(lead.nom, { ns: "jump" })}</span></label>
                            </div>;
                        })}
                    </div>
                </div>
                {fieldErrors.applead && <div className={"color-red"} style={{ marginTop: "8px" }}>{fieldErrors.applead}</div>}
            </div>}
        </fieldset>
        <fieldset id="argumentaireInscription">
            <legend>{t("Echanges sur la candidature et moyens de contact pendant la ville", { ns: "jumpEvent" })}</legend>
            <div className="textAreaJumpInscription">
                <label htmlFor="inscription_jump_motivations">{t("Vos motivations :", { ns: "jumpEvent" })}</label>
                <div className={"inscription_bloc_text"}>
                    {((isModifiable)) ? (
                        <EditorComponent width={986} data={inscription.motivations ?? ""} lang={user.lang} onTextChange={(text) => {
                            handleInscriptionChange({ motivations: text });
                        }} />
                    ) : (
                        HTMLParser(inscription.motivations ?? "")
                    )}
                </div>
            </div>
            <div className="textAreaJumpInscription">
                <label htmlFor="inscription_jump_commentaires">{t("Commentaires :", { ns: "jumpEvent" })}</label>
                <div className={"inscription_bloc_text"}>
                    {((isModifiable)) ? (
                        <EditorComponent width={986} data={inscription.commentaires ?? ""} lang={user.lang} onTextChange={(text) => {
                            handleInscriptionChange({ commentaires: text });
                        }} />
                    ) : (
                        HTMLParser(inscription.commentaires ?? "")
                    )}
                </div>
            </div>
            <div className="textAreaJumpInscription">
                <label htmlFor="inscription_jump_moyenContact">{HTMLParser(t("Vos moyens de contact autres que les MPs MyHordes (Numéro de tel, ID Discord, ...) : <br/> <span class='warning'>Les données saisies ici sont chiffrées avant d'être mises en base de données</span>", { ns: "jumpEvent" }))}</label>
                <div className={"inscription_bloc_text"}>
                    {((blocModif)) ? (
                        HTMLParser(inscription.moyen_contact ?? "")
                    ) : (
                        <EditorComponent width={986} data={inscription.moyen_contact ?? ""} lang={user.lang} onTextChange={(text) => {
                            handleInscriptionChange({ moyen_contact: text });
                        }} />
                    )}
                </div>
            </div>
            <div id={"bloc_choix_diffusion"}>
                <label htmlFor="inscription_jump_typeDiffusion" className="required">{t("Diffusion des moyens de contact :", { ns: "jumpEvent" })}</label>
                {(blocModif) ? (
                    <div><strong>{inscription.type_diffusion === 1 ? t("Uniquement aux leads / organistateurs", { ns: "jumpEvent" }) : t("Tout le monde", { ns: "jumpEvent" })}</strong></div>
                ) : (
                    <>
                        <select id="inscription_jump_typeDiffusion"
                                name="inscription_jump[typeDiffusion]"
                                value={inscription.type_diffusion ?? 1}
                                onChange={(event) =>
                                    handleInscriptionChange({ type_diffusion: parseInt(event.target.value, 10) ?? 1 })}
                        >
                            <option value={0}>{t("Tout le monde", { ns: "jumpEvent" })}</option>
                            <option value={1}>{t("Uniquement aux leads / organisateurs", { ns: "jumpEvent" })}</option>
                        </select>
                    </>
                )
                }
            </div>
            <hr style={{ width: "80%" }} />
            {isOrga && <div className="textAreaJumpInscription">
                <label htmlFor="inscription_jump_bloc_notes">{t("Bloc-notes (non visible par le joueur) :", { ns: "jumpEvent" })}</label>
                <div className={"inscription_bloc_text"}>
                    {isArchMode ? HTMLParser(inscription.bloc_notes ?? "") :
                        <EditorComponent width={986} data={inscription.bloc_notes ?? ""} lang={user.lang} onTextChange={(text) => {
                            handleInscriptionChange({ bloc_notes: text });
                        }} />}
                </div>
            </div>}
            <div className="textAreaJumpInscription">
                <label htmlFor="inscription_jump_reponses">{t("Retour des organisateurs :", { ns: "jumpEvent" })}</label>
                <div id="retourInscriptionOrganisateur">
                    {isArchMode ? HTMLParser(inscription.reponse ?? "") : (
                        (isOrga) ? (
                            <EditorComponent width={986} data={inscription.reponse ?? ""} lang={user.lang} onTextChange={(text) => {
                                handleInscriptionChange({ reponse: text });
                            }} />
                        ) : (
                            HTMLParser(inscription.reponse ?? "")
                        )
                    )}
                </div>
            </div>
        
        </fieldset>
    </>;
}
