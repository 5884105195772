import React, { useEffect, useState } from "react";
import { useCarteContext }            from "../../../types/Context/CarteContext";
import Tabs                           from "react-bootstrap/Tabs";
import Tab                            from "react-bootstrap/Tab";
import { SoifType }                   from "../../../types/components/Outils/OutilsExpeditionType";
import { ExpeditionApi }              from "../../../services/api/ExpeditionApi";
import { Status_error, usePopUp }     from "../../../types/Context/PopUpContext";
import ListeExpeditions               from "./ListeExpeditions";
import ListeOuvriers                  from "./ListeOuvriers";
import { useGeneralContext }          from "../../../types/Context/GeneralContext";
import chargement                     from "../../../../img/chargement.svg";
import { useGHContext }               from "../../../types/Context/GHContext";
import CompteursMetiers               from "./CompteursMetiers";
import { ExpeditionContext }          from "../../../types/Context/Outils/Expedition/ExpeditionContext";
import GestionExpeditions             from "./GestionExpeditions";
import HTMLParser                     from "html-react-parser";
import { formatInTimeZone }           from "date-fns-tz";
import { BoutonCopy }                 from "../../../components/generality/ComposantGeneral";
import { StylesConfig }               from "react-select";
import chroma                         from "chroma-js";
import { SizeCustomStyle }            from "../../Hotel/Inscription/InscriptionExpedition";
import { OutilsExpeditionDTO }        from "../../../types/models/outilsExpedition.dto";
import { ExpeditionDTO }              from "../../../types/models/expedition.dto";
import { SacExpeditionnaireDTO }      from "../../../types/models/sacExpeditionnaire.dto";
import { OuvriersDTO }                from "../../../types/models/ouvriers.dto";
import { CitoyensDTO }                from "../../../types/models/citoyens.dto";
import { ThemeUserDTO }               from "../../../types/models/themeUser.dto";
import { useTranslation }             from "react-i18next";
import Button                         from "../../../components/utils/Button";
import Modal                          from "react-bootstrap/Modal";
import PopUpSacStock                  from "./PopUpSacStock";

const intialExpedition: ExpeditionDTO = {
    nom             : "",
    min_pdc         : 0,
    expedition_parts: [
        {
            number          : 1,
            ouverte         : false,
            expeditionnaires: [{
                pa_base      : 6,
                job          : null,
                soif         : null,
                preinscrit   : false,
                citoyen      : null,
                sac          : [],
                action_heroic: null,
                position     : 1,
            }],
            trace           : null,
            consignes       : [],
        },
    ],
};

export const customStyleOutils = (theme: ThemeUserDTO, size?: SizeCustomStyle, onDisabled?: (citoyen: CitoyensDTO) => boolean): StylesConfig<CitoyensDTO, false> => {
    if (!size) {
        size = {
            widthContainer          : 60,
            widthMenu               : 300,
            height                  : 20,
            minHeight               : 20,
            widthClearIndicator     : 20,
            widthControl            : 40,
            widthDropdownIndicator  : 20,
            widthIndicatorsContainer: 20,
            widthInput              : 20,
            widthSingleValue        : 20,
            widthValueContainer     : 20,
        };
    }
    
    return {
        container          : (base) => ({
            ...base,
            width : `${size.widthContainer}px`,
            height: `${size.height}px`,
        }),
        input              : (base) => ({
            ...base,
            width: `${size.widthInput}px`,
        }),
        menu               : (base) => ({
            ...base,
            width          : `${size.widthMenu}px`,
            backgroundColor: "white",
            cursor         : "pointer",
            color          : "black",
            zIndex         : 100,
        }),
        menuList           : (provided: any) => ({
            ...provided,
            backgroundColor: "white",
            cursor         : "pointer",
            color          : "black",
        }),
        menuPortal         : (provided: any) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
            cursor         : "pointer",
        }),
        control            : (base) => ({
            ...base,
            width         : `${size.widthControl}px`,
            height        : `${size.height}px`,
            minHeight     : `${size.height}px`,
            display       : "flex",
            alignItems    : "center",
            justifyContent: "center",
            alignContent  : "center",
            padding       : 0,
            border        : "1px solid black",
        }),
        indicatorsContainer: (base) => ({
            ...base,
            width         : `${size.widthIndicatorsContainer}px`,
            height        : `${size.height}px`,
            display       : (size.widthIndicatorsContainer === 0) ? "none" : "flex",
            justifyContent: "flex-end",
        }),
        clearIndicator     : (base) => ({
            ...base,
            width  : `${size.widthClearIndicator}px`,
            padding: 0,
            height : `${size.height}px`,
        }),
        dropdownIndicator  : (base) => ({
            ...base,
            width  : `${size.widthDropdownIndicator}px`,
            padding: 0,
            height : `${size.height}px`,
        }),
        singleValue        : (base) => {
            return {
                ...base,
                borderRadius   : 5,
                display        : "flex",
                flexDirection  : "row",
                alignItems     : "center",
                justifyContent : "flex-start",
                alignContent   : "center",
                backgroundColor: "white",
                background     : "none",
                color          : "black",
                width          : `${size.widthSingleValue}px`,
                padding        : 0,
                height         : `${size.height}px`,
            };
        },
        valueContainer     : (base) => ({
            ...base,
            color  : "white",
            width  : `${size.widthValueContainer}px`,
            padding: 0,
            height : `${size.height}px`,
        }),
        option             : (provided: any, { data, isSelected, isFocused }) => {
            const color = chroma("#2f6095");
            return {
                ...provided,
                display        : "flex",
                flexDirection  : "row",
                alignItems     : "center",
                backgroundColor: onDisabled(data) ? "lightgrey" : isSelected ? color.alpha(0.3).css() : isFocused ? color.alpha(0.1).css() : "white",
                color          : onDisabled(data) ? "grey" : "black",
                ":active"      : {
                    ...provided[":active"],
                    backgroundColor: !onDisabled(data) && (isSelected ? color.alpha(0.3).css() : "white"),
                },
            };
        },
    };
};


export default function ModuleOutilsExpeditions({ onUpdateSeletedExpe }: { onUpdateSeletedExpe: (expedition?: string, forcage?: boolean) => void }) {
    const { t } = useTranslation();
    const { carte, setCarte, jourActuel, setJourActuel } = useCarteContext();
    const { general, setGeneral } = useGeneralContext();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const { refreshKey } = useGHContext();
    const [jourVille, setJourVille] = React.useState(carte.ville.jour);
    const [ongletActuel, setOngletActuel] = React.useState("list");
    const [expedition, setExpedition] = React.useState<ExpeditionDTO | null>(intialExpedition);
    const [outilsExpedition, setOutilsExpedition] = React.useState<OutilsExpeditionDTO>(carte.outilsExpe.outilsExpeditions);
    const [citoyensUser, setCitoyensUser] = React.useState<CitoyensDTO[]>([]);
    const [loadData, setLoadData] = React.useState(false);
    const [expeditions, setExpeditions] = React.useState<ExpeditionDTO[]>(carte.outilsExpe.outilsExpeditions.expeditions);
    const [ouvriers, setOuvriers] = React.useState<OuvriersDTO[]>(carte.outilsExpe.outilsExpeditions.ouvriers);
    const [listingCitoyens, setListingCitoyens] = useState(null);
    const [onDuplicate, setOnDuplicate] = useState(false);
    const [verrouExpes, setVerrouExpes] = useState<string[]>([]);
    const [openSac, setOpenSac] = useState(false);
    const optionsSoif: SoifType[] = [
        { value: 0, label: t("Non", { ns: "app" }), icon: "status_clean" },
        { value: 1, label: t("Oui", { ns: "app" }), icon: "r_dwater" },
    ];
    
    // refresh de les outils expédition
    const refreshOutils = (jour: number) => {
        setLoadData(true);
        // appel de l'API pour refresh les datas
        const expeditionApi = new ExpeditionApi(carte.ville.map_id);
        const data = {
            map_id : carte.ville.map_id,
            id_user: general.user.id,
            jour   : jour,
        };
        expeditionApi.recuperationOutils(data).then((response) => {
            if (onDuplicate) {
                setOngletActuel("gestion");
            } else {
                setOngletActuel("list");
            }
            if (response.data.outils) {
                const carteMod = { ...carte };
                carteMod.outilsExpe = response.data.outils;
                setCarte(carteMod);
                setOutilsExpedition(response.data.outils.outilsExpeditions);
                setVerrouExpes(response.data.outils.expeVerrou);
            }
            if (response.data.general) {
                setGeneral(response.data.general);
            }
            setLoadData(false);
        }).catch((error) => {
            console.error(error);
            setMessagePopUp(error?.data?.error ?? error?.message);
            setShowPop(true);
            setStatus(Status_error);
        });
    };
    
    const recupVerouilleExpedition = () => {
        const expeditionApi = new ExpeditionApi(carte.ville.map_id);
        const data = {
            map_id : carte.ville.map_id,
            id_user: general.user.id,
            jour   : jourActuel,
        };
        expeditionApi.getVerrouExpedition(data).then((response) => {
            if (response.data.verrou) {
                setVerrouExpes(response.data.verrou);
            }
        }).catch((error) => {
            setMessagePopUp(error?.data?.error ?? error?.message);
            setShowPop(true);
            setStatus(Status_error);
        });
    };
    
    // Gestion des changements d'état une fois les composants changés
    useEffect(() => {
        // recombinaison des arrays Citoyen dehors et en ville pour avoir qu'une seule liste
        const citoyens = [...carte.citoyensDehors, ...carte.citoyensVille];
        // extraction de citoyens les utilisateurs pour avoir une liste de pseudo sur le type User
        setCitoyensUser(citoyens.sort((a, b) => {
            return a.citoyen.pseudo.localeCompare(b.citoyen.pseudo);
        }));
        
    }, [refreshKey]);
    useEffect(() => {
        if (jourVille !== carte.ville.jour) {
            setJourVille(carte.ville.jour);
            setJourActuel(carte.ville.jour);
            refreshOutils(carte.ville.jour);
        }
    }, [carte.ville.jour]);
    useEffect(() => {
        if (carte.outilsExpe.jour === jourActuel) {
            setOutilsExpedition(carte.outilsExpe.outilsExpeditions);
        }
    }, [carte.outilsExpe.jour]);
    useEffect(() => {
        if (carte.outilsExpe.jour === jourActuel) {
            setOutilsExpedition(carte.outilsExpe.outilsExpeditions);
            setExpeditions(carte.outilsExpe.outilsExpeditions.expeditions);
            setOuvriers(carte.outilsExpe.outilsExpeditions.ouvriers);
        }
    }, [carte.outilsExpe.outilsExpeditions]);
    useEffect(() => {
        if (carte.outilsExpe.expeVerrou !== verrouExpes) {
            setVerrouExpes(carte.outilsExpe.expeVerrou);
        }
    }, [carte.outilsExpe.expeVerrou]);
    useEffect(() => {
        if (jourActuel !== carte.outilsExpe.jour) {
            refreshOutils(jourActuel);
        }
    }, [jourActuel]);
    
    useEffect(() => {
        // Interroge l'API toutes les 60 secondes (60000 ms)
        const interval = setInterval(() => {
            recupVerouilleExpedition();
        }, 60000);
        
        // Appel initial
        recupVerouilleExpedition();
        
        // Nettoyage de l'intervalle lorsque le composant est démonté
        return () => clearInterval(interval);
    }, []);
    
    
    if (loadData) {
        return <div id={"zone_outils_expedition"}>
            <div className={"chargement_page"}>
                <img src={chargement} alt="Drapeau" />
                <span>{t("Chargement...", { ns: "app" })}</span>
            </div>
        </div>;
    }
    
    const calculExpeditionnaire = (): number => {
        let total = 0;
        expeditions.forEach((expedition) => {
            total += expedition.expedition_parts[0].expeditionnaires.length;
        });
        return total;
    };
    
    const listeCitoyensFV = () => {
        const citoyensPreinscritsFao: CitoyensDTO[] = ouvriers.filter((ouvrier) => ouvrier.preinscrit).sort((ouv_a, ouv_b) => {
            return ouv_a.citoyen.citoyen.pseudo.localeCompare(ouv_b.citoyen.citoyen.pseudo);
        }).map((ouvrier) => {
            if (ouvrier.citoyen !== null) {
                return ouvrier.citoyen;
            }
        });
        const citoyensPreinscritsExpe: CitoyensDTO[] = expeditions.flatMap(expedition => expedition.expedition_parts[0].expeditionnaires.filter((exped) => exped.preinscrit).sort((expedi_a, expedi_b) => {
                return expedi_a.citoyen.citoyen.pseudo.localeCompare(expedi_b.citoyen.citoyen.pseudo);
            }).map((exped) => {
                if (exped.citoyen !== null) {
                    return exped.citoyen;
                }
            }),
        );
        const formattedCitizenListFAO: string[] = citoyensPreinscritsFao.map(
            (citizen: CitoyensDTO) => `@${citizen.citoyen.pseudo.replace(/\s/g, "")}:${citizen.citoyen.id_my_hordes}`,
        );
        const formattedCitizenListExpe: string[] = citoyensPreinscritsExpe.map(
            (citizen: CitoyensDTO) => `@${citizen.citoyen.pseudo.replace(/\s/g, "")}:${citizen.citoyen.id_my_hordes}`,
        );
        const phraseFao = t("{number} préinscrit(s) en FAO :", { ns: "ville" }).replace("{number}", formattedCitizenListFAO.length.toString()).concat(formattedCitizenListFAO.join(", "));
        const phraseExpe = t("{number} préinscrit(s) en expédition :", { ns: "ville" }).replace("{number}", formattedCitizenListExpe.length.toString()).concat(formattedCitizenListExpe.join(", "));
        setListingCitoyens(phraseFao.concat("<br/>").concat("<br/>").concat(phraseExpe));
    };
    
    const calculStockRestant = (stock: number[]): number[] => {
        const stockMod = { ...stock };
        // calcul des sacs utilisés
        expeditions.forEach((expedition) => {
            expedition.expedition_parts.forEach((part) => {
                part.expeditionnaires.forEach((expeditionnaire) => {
                    expeditionnaire.sac.forEach((item: SacExpeditionnaireDTO) => {
                        stockMod[item.item.id * 10 + (item.broken ? 1 : 0)] -= item.nbr;
                    });
                });
            });
        });
        
        // calcul des sacs utilisés par les ouvriers
        ouvriers.forEach((ouvrier) => {
            ouvrier.sac.forEach((item: SacExpeditionnaireDTO) => {
                stockMod[item.item.id * 10 + (item.broken ? 1 : 0)] -= item.nbr;
            });
        });
        
        return stockMod;
    };
    
    return <ExpeditionContext.Provider value={{
        expeditions        : expeditions, setExpeditions: setExpeditions, ouvriers: ouvriers, setOuvriers: setOuvriers, optionsSoif: optionsSoif,
        expedition         : expedition, setExpedition: setExpedition, ongletActuel: ongletActuel, setOngletActuel: setOngletActuel,
        loadData           : loadData, setLoadData: setLoadData, refreshOutils: refreshOutils, citoyensUser: citoyensUser, onDuplicate: onDuplicate, setOnDuplicate: setOnDuplicate, calculStockRestant: calculStockRestant,
        onUpdateSeletedExpe: onUpdateSeletedExpe, verrouExpes: verrouExpes, setVerrouExpes: setVerrouExpes,
    }}>
        <div id={"zone_outils_expedition"}>
            <Tabs onSelect={setOngletActuel} activeKey={ongletActuel}>
                <Tab eventKey="list" title={t("Liste des expeditions", { ns: "ville" })}>
                    <div id={"zone_recap_expe"}>
                        <ListeExpeditions />
                        <CompteursMetiers />
                        <ListeOuvriers />
                        <div className={"d-flex gap-5 justify-content-center align-items-center"}>
                            <div id={"total_place_expedition"}>
                                <div>{t("Total de places créées", { ns: "ville" })} :</div>
                                <div><span>{calculExpeditionnaire() + ouvriers.length}</span><span>/</span><span>{citoyensUser.length}</span></div>
                            </div>
                            <div>
                                <Button taille={"xs"} couleur={"primary"} onClick={() => setOpenSac(true)}>{t("Afficher l'intégralité des sacs", { ns: "app" })}</Button>
                            </div>
                        </div>
                        <div className={"historique_modification_outils_expe"}>
                            {outilsExpedition.created_by &&
                                <span>{HTMLParser(t("Créé par <strong>{createur}</strong> le <em>{date}</em> ", { ns: "ville" }).replace("{createur}", outilsExpedition.created_by?.pseudo).replace("{date}", formatInTimeZone(new Date(Date.parse(outilsExpedition.created_at)), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))))}</span>}
                            {outilsExpedition.modify_by &&
                                <span>{HTMLParser(t("Modifié par <strong>{modificateur}</strong> le <em>{date}</em> ", { ns: "ville" }).replace("{modificateur}", outilsExpedition.modify_by?.pseudo).replace("{date}", formatInTimeZone(new Date(Date.parse(outilsExpedition.modify_at)), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))))}</span>}
                        </div>
                    </div>
                    <div id="zoneGenerationExpeditionList">
                        <div id="boutonGenerationCitoyen">
                            <input
                                type="button"
                                className={"btn btn-xs btn-primary"}
                                value={t("Générer liste pré-inscrit", { ns: "ville" })}
                                name="generateListPuce"
                                onClick={listeCitoyensFV}
                            />
                        </div>
                        {listingCitoyens && (
                            <div className="elementVisible" id="zoneListCitoyensExpedition">
                                <div className="zoneText" id="listText">
                                    {HTMLParser(listingCitoyens)}
                                </div>
                                <div id={"boutonGestionListCitoyen"}>
                                    <BoutonCopy textAcopier={listingCitoyens} />
                                    <input
                                        className={"btn btn-xs btn-warning"}
                                        type="button"
                                        value={t("Fermer liste", { ns: "hotel" })}
                                        name="fermerList"
                                        onClick={() => setListingCitoyens(null)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </Tab>
                {jourActuel >= jourVille && <Tab eventKey="gestion" title={t("Création/Modification expéditions", { ns: "ville" })}>
                    <GestionExpeditions />
                </Tab>}
            </Tabs>
            <Modal show={openSac} onHide={() => setOpenSac(false)} centered key={"modal_stock_sac"}>
                {openSac && <div id={"popUpStockSac"}>
                    <PopUpSacStock ouvriers={ouvriers} expeditions={expeditions} onFermer={() => setOpenSac(false)} />
                </div>}
            </Modal>
        </div>
    </ExpeditionContext.Provider>;
    
    
}

