import React, { useEffect }                                  from "react";
import { useCarteContext }                                   from "../../../types/Context/CarteContext";
import { SoifType }                                          from "../../../types/components/Outils/OutilsExpeditionType";
import SvgIcone                                              from "../../../components/generality/SvgIcone";
import Select, { components, OptionProps, SingleValueProps } from "react-select";
import Spinner                                               from "react-bootstrap/Spinner";
import { Expedition }                                        from "../../../types/components/ville/Expedition";
import { ExpeditionApi }                                     from "../../../services/api/ExpeditionApi";
import { Status_error, Status_success, usePopUp }            from "../../../types/Context/PopUpContext";
import { useGeneralContext }                                 from "../../../types/Context/GeneralContext";
import HTMLParser                                            from "html-react-parser";
import { formatInTimeZone }                                  from "date-fns-tz";
import PopUpMajSacExpeditionnaire                            from "./PopUpMajSacExpeditionnaire";
import { AffNbrIcon }                                        from "../../../components/generality/ComposantGeneral";
import PopUpConsigne                                         from "./PopUpConsigne";
import Modal                                                 from "react-bootstrap/Modal";
import { customStyle }                                       from "../../Hotel/Inscription/InscriptionExpedition";
import CustomSwitchToggle                                    from "../../../components/generality/CustomSwitchToggle";
import { useExpeditionContext }                              from "../../../types/Context/Outils/Expedition/ExpeditionContext";
import CompteursMetiers                                      from "./CompteursMetiers";
import { customStyleOutils }                                 from "./ModuleOutilsExpeditions";
import ConfirmationDialog                                    from "../../../components/generality/ComposantGeneral/ConfirmationDialog";
import { ExpeditionDTO }                                     from "../../../types/models/expedition.dto";
import { ExpeditionnaireDTO }                                from "../../../types/models/expeditionnaire.dto";
import { ExpeditionPartDTO }                                 from "../../../types/models/expeditionPart.dto";
import { ConsigneExpeditionDTO }                             from "../../../types/models/consigneExpedition.dto";
import { OuvriersDTO }                                       from "../../../types/models/ouvriers.dto";
import { CitoyensDTO }                                       from "../../../types/models/citoyens.dto";
import { JobPrototypeDTO }                                   from "../../../types/models/jobPrototype.dto";
import { HerosPrototypeDTO }                                 from "../../../types/models/herosPrototype.dto";
import { useTranslation }                                    from "react-i18next";
import { faCircleQuestion }                                  from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon }                                   from "@fortawesome/react-fontawesome";
import TooltipGH                                             from "../../../components/utils/TooltipGH";

const { SingleValue, Option } = components;

export const styleJob = {
    widthContainer          : 65,
    widthMenu               : 120,
    height                  : 24,
    minHeight               : 24,
    widthClearIndicator     : 20,
    widthControl            : 65,
    widthDropdownIndicator  : 20,
    widthIndicatorsContainer: 40,
    widthInput              : 20,
    widthSingleValue        : 44,
    widthValueContainer     : 24,
};
export const stylePseudo = {
    widthContainer          : 150,
    widthMenu               : 200,
    height                  : 24,
    minHeight               : 24,
    widthClearIndicator     : 20,
    widthControl            : 150,
    widthDropdownIndicator  : 20,
    widthIndicatorsContainer: 40,
    widthInput              : 20,
    widthSingleValue        : 150,
    widthValueContainer     : 150,
};


const customStylesExpe = {
    option           : (provided: any) => ({
        ...provided,
        display        : "flex",
        flexDirection  : "row",
        alignItems     : "center",
        backgroundColor: "white",
        color          : "black",
    }),
    singleValue      : (provided: any) => ({
        ...provided,
        display        : "flex",
        flexDirection  : "row",
        alignItems     : "center",
        backgroundColor: "white",
        color          : "black",
    }),
    control          : (provided: any) => ({
        ...provided,
        minHeight: "30px",
        height   : "30px",
        minWidth : "300px",
        maxWidth : "450px",
    }),
    input            : (provided: any) => ({
        ...provided,
        gridTemplateColumns: "none",
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        padding: "0",
    }),
    valueContainer   : (provided: any) => ({
        ...provided,
        padding: "0px 8px",
    }),
    menu             : (provided: any) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
        zIndex         : 100,
    }),
    menuList         : (provided: any) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
    }),
    menuPortal       : (provided: any) => ({
        ...provided,
        backgroundColor: "white",
        color          : "black",
    }),
};

const ExpeSingleValue = (props: SingleValueProps<Expedition>) => {
    return <SingleValue {...props}>
    <span style={{ display: "flex", gap: "5px" }}><span>{props.data.nom}</span><span
        style={{ display: "flex" }}>(<span>{props.data.pa}</span><SvgIcone icone={"h_pa"} /></span>)</span>
    </SingleValue>;
};

const JobSingleValue = (props: SingleValueProps<JobPrototypeDTO>) => {
    return <SingleValue {...props}>
        <SvgIcone icone={"h_" + props.data.icon} />
    </SingleValue>;
};

const JobOption = (props: OptionProps<JobPrototypeDTO>) => {
    const { t } = useTranslation();
    return <Option {...props}>
        <SvgIcone icone={"h_" + props.data.icon} />
        <span>{t(props.data.nom, { ns: "game" })}</span>
    </Option>;
};

const HerosSingleValue = (props: SingleValueProps<HerosPrototypeDTO>) => {
    const { t } = useTranslation();
    return <SingleValue {...props}>
        <span className={" d-flex gap-1 align-items-center justify-content-center"}>
            <SvgIcone icone={props.data.icon} />
            <span>{t(props.data.nom, { ns: "game" })}</span>
        </span>
    </SingleValue>;
};

const HerosOption = (props: OptionProps<HerosPrototypeDTO>) => {
    const { t } = useTranslation();
    return <Option {...props}>
        <span className={" d-flex gap-1 align-items-center justify-content-center"}>
            <SvgIcone icone={props.data.icon} />
            <span>{t(props.data.nom, { ns: "game" })}</span>
        </span>
    </Option>;
};

const PseudoSingleValue = (props: SingleValueProps<CitoyensDTO>) => {
    return <SingleValue {...props}>
        <span className={" d-flex gap-1 align-items-center justify-content-center"}>
            <SvgIcone icone={"h_" + props.data.job.icon} />
            <span className={props.data.ban ? "color-red" : ""}>{props.data.citoyen.pseudo}</span>
            {props.data.ban && <SvgIcone icone={"h_ban"} />}
        </span>
    </SingleValue>;
};

const PseudoOption = (props: OptionProps<CitoyensDTO>) => {
    return <Option {...props}>
        <span className={" d-flex gap-1 align-items-center justify-content-center"}>
            <SvgIcone icone={"h_" + props.data.job.icon} />
            <span className={props.data.ban ? "color-red" : ""}>{props.data.citoyen.pseudo}</span>
            {props.data.ban && <SvgIcone icone={"h_ban"} />}
        </span>
    </Option>;
};

const ExpeOption = (props: OptionProps<Expedition>) => {
    return <Option {...props}>
    <span style={{ display: "flex", gap: "5px" }}><span>{props.data.nom}</span><span
        style={{ display: "flex" }}>(<span>{props.data.pa}</span><SvgIcone icone={"h_pa"} /></span>)</span>
    </Option>;
};

export const SoifSingleValue = (props: SingleValueProps<{ value: number, label: string, icon: string }>) => {
    return <SingleValue {...props}>
        <span className={" d-flex gap-1 align-items-center justify-content-center"}>
            <SvgIcone icone={props.data.icon} />
            <span>{props.data.label}</span>
        </span>
    </SingleValue>;
};

export const SoifOption = (props: OptionProps<{ value: number, label: string, icon: string }>) => {
    return <Option {...props}>
        <span className={" d-flex gap-1 align-items-center justify-content-center"}>
            <SvgIcone icone={props.data.icon} />
            <span>{props.data.label}</span>
        </span>
    </Option>;
};

const CustomGroupHeading = (props) => (
    <components.GroupHeading {...props}>
        <span>{props.data.label}</span> - <span>{props.data.options.length}</span>
    </components.GroupHeading>
);

const optionsPa = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
];

const ID_RAMASSAGE = 6;

const intialExpedition: ExpeditionDTO = {
    nom             : "",
    min_pdc         : 0,
    expedition_parts: [
        {
            number          : 1,
            ouverte         : false,
            expeditionnaires: [{
                pa_base      : 6,
                pe_base      : 0,
                job          : null,
                soif         : null,
                preinscrit   : false,
                citoyen      : null,
                sac          : [],
                action_heroic: null,
                position     : 1,
            }],
            trace           : null,
            consignes       : [],
        },
    ],
};

export default function GestionExpeditions() {
    const { t } = useTranslation();
    const { expeditions, ouvriers, expedition, setExpedition, setOngletActuel, optionsSoif, refreshOutils, citoyensUser, setOnDuplicate, calculStockRestant, onUpdateSeletedExpe, setVerrouExpes } = useExpeditionContext();
    const { carte, idClick, setIdClick, setIsSelCase, isSelCase, jourActuel, setConsigneExpes, consigneExpes } = useCarteContext();
    const { general } = useGeneralContext();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const [loadApiSauvegarde, setLoadApiSauvegarde] = React.useState(false);
    const [sauvegardeIndex, setSauvegardeIndex] = React.useState<{ indexExpe: number, indexConsigne: number }>({ indexExpe: -1, indexConsigne: -1 });
    const [showPopUpObjet, setShowPopUpObjet] = React.useState(false);
    const [indexSac, setIndexSac] = React.useState<{ i_part: number, i_exp: number }>(null);
    const [indexDescription, setIndexDescription] = React.useState<number>(null);
    const [indexConsigne, setIndexConsigne] = React.useState<{ i_part: number, i_consigne: number }>(null);
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const [selectionPseudo, setSelectionPseudo] = React.useState<{ indexExpeditionnaire: number, optionSelected: CitoyensDTO }>(null);
    const [affExpedition, setAffExpedition] = React.useState<string[]>([]);
    const [showPartConsigne, setShowPartConsigne] = React.useState<number>(null);
    
    const liste = carte.outilsExpe.liste;
    
    // Gestion des changements sur les expeditionnaires, les expeditions
    const handleExpeditionnaireChange = (indexExpeditionnaire: number, indexExpe: number, updatedProperties: Partial<ExpeditionnaireDTO>) => {
        // on récupère l'expeditionnaire de l'expedition
        const expeditionMod = { ...expedition };
        const expeditionPart = expeditionMod.expedition_parts[indexExpe];
        const expeditionnaire = expeditionPart.expeditionnaires[indexExpeditionnaire];
        // on modifie l'expeditionnaire,
        // on met à jour l'expeditionnaire dans l'expedition
        expeditionPart.expeditionnaires[indexExpeditionnaire] = { ...expeditionnaire, ...updatedProperties };
        // on met à jour l'expedition
        setExpedition(expeditionMod);
    };
    const handleExpeditionChange = (indexExpe: number, updatedProperties: Partial<ExpeditionnaireDTO>) => {
        // On balaye toutes les expeditions part pour mettre à jour l'expeditionnaire sur toutes les parties
        expedition.expedition_parts.forEach((expeditionPart, index) => {
            handleExpeditionnaireChange(indexExpe, index, updatedProperties);
        });
    };
    const handleConsigneExpeditionChange = (indexExpe: number, indexConsigne, updatedProperties: Partial<ConsigneExpeditionDTO>) => {
        const expeditionMod = { ...expedition };
        const expeditionPart = expeditionMod.expedition_parts[indexExpe];
        const consigne = expeditionPart.consignes[indexConsigne];
        expeditionPart.consignes[indexConsigne] = { ...consigne, ...updatedProperties };
        setExpedition(expeditionMod);
    };
    const handleTraceExpeditionChange = (indexExpe: number, updatedProperties: Partial<ExpeditionPartDTO>) => {
        const expeditionMod = { ...expedition };
        
        // Si on est sur une expédition différent de ramassage, et que l'expédition n'a pas de nom, on peut prendre le nom de la trace
        if (expedition.type_expe?.id !== ID_RAMASSAGE && expedition.nom === "") {
            expeditionMod.nom = updatedProperties?.trace?.nom ?? "";
        }
        
        const expeditionPart = expeditionMod.expedition_parts[indexExpe];
        expeditionMod.expedition_parts[indexExpe] = { ...expeditionPart, ...updatedProperties };
        setExpedition(expeditionMod);
    };
    const handleDescriptionChange = (indexExpedition: number, description: string) => {
        const expeditionMod = { ...expedition };
        expeditionMod.expedition_parts[indexExpedition].description = description;
        setExpedition(expeditionMod);
    };
    
    // Ajout d'une consigne à une expédition (part), ajout d'une partie à une expédition, ajout d'un expeditionnaire à une expédition
    const handleAddConsigneExpedition = (indexExpedition: number) => {
        const expeditionMod = { ...expedition };
        const length = expeditionMod.expedition_parts[indexExpedition].consignes.length;
        expeditionMod.expedition_parts[indexExpedition].consignes.push({
            zone          : null,
            text          : "",
            ordre_consigne: length + 1,
        });
        setExpedition(expeditionMod);
    };
    const handleAddExpeditionnaire = () => {
        const expeditionMod = { ...expedition };
        expeditionMod.expedition_parts.forEach((expeditionPart) => {
            expeditionPart.expeditionnaires.push({
                pa_base      : 6,
                pe_base      : 0,
                job          : null,
                soif         : null,
                preinscrit   : false,
                citoyen      : null,
                sac          : [],
                action_heroic: null,
                position     : expeditionPart.expeditionnaires.length + 1,
            });
        });
        setExpedition(expeditionMod);
    };
    const handleAddPartExpedition = () => {
        const expeditionMod = { ...expedition };
        // On reprend les mêmes expeditionnaires pour la nouvelle partie
        expeditionMod.expedition_parts.push({
            number          : expeditionMod.expedition_parts.length + 1,
            ouverte         : false,
            expeditionnaires: expeditionMod.expedition_parts[0].expeditionnaires.map(expeditionnaire => {
                return {
                    pa_base      : null,
                    pe_base      : null,
                    job          : expeditionnaire?.job,
                    job_fige     : expeditionnaire?.job_fige,
                    soif         : expeditionnaire?.soif,
                    soif_fige    : expeditionnaire?.soif_fige,
                    preinscrit   : expeditionnaire?.preinscrit,
                    citoyen      : expeditionnaire?.citoyen,
                    sac          : [],
                    action_heroic: null,
                    position     : expeditionnaire?.position,
                };
            }),
            consignes       : [],
        });
        setExpedition(expeditionMod);
    };
    const handleAddModZoneConsigne = (indexExpedition: number, indexConsigne: number) => {
        setSauvegardeIndex({ indexExpe: indexExpedition, indexConsigne: indexConsigne });
        setIsSelCase(true);
    };
    
    // Suppression d'un expeditionnaire, d'une partie d'expedition, d'une consigne
    const handleDeleteExpeditionnaire = (indexExpe: number) => {
        const expeditionMod = { ...expedition };
        expeditionMod.expedition_parts.forEach((expeditionPart) => {
            expeditionPart.expeditionnaires.splice(indexExpe, 1);
        });
        
        // recalcul des positions des expeditionnaires
        expeditionMod.expedition_parts.forEach((expeditionPart) => {
            expeditionPart.expeditionnaires.forEach((expeditionnaire, index) => {
                expeditionnaire.position = index + 1;
            });
        });
        setExpedition(expeditionMod);
    };
    const handleDeleteExpeditionPart = (indexPart: number) => {
        const expeditionMod = { ...expedition };
        expeditionMod.expedition_parts.splice(indexPart, 1);
        
        // recalcul des positions des expeditions
        expeditionMod.expedition_parts.forEach((expeditionPart, index) => {
            expeditionPart.number = index + 1;
        });
        setExpedition(expeditionMod);
    };
    const handleDeleteConsigneExpedition = (indexExpedition: number, indexConsigne: number) => {
        const expeditionMod = { ...expedition };
        expeditionMod.expedition_parts[indexExpedition].consignes.splice(indexConsigne, 1);
        
        // recalcul des positions des consignes
        expeditionMod.expedition_parts[indexExpedition].consignes.forEach((consigne, index) => {
            consigne.ordre_consigne = index + 1;
        });
        setExpedition(expeditionMod);
    };
    
    // Duplication d'un expéditionnaire
    const handleDuplicateExpeditionnaire = (indexExpeditionnaire: number) => {
        const expeditionMod = { ...expedition };
        expeditionMod.expedition_parts.forEach((expeditionPart) => {
            // On duplique l'expeditionnaire et on supprime le joueur si pré-inscrit
            const expeditionnaireDupl = { ...expeditionPart.expeditionnaires[indexExpeditionnaire] };
            expeditionnaireDupl.citoyen = null;
            expeditionnaireDupl.preinscrit = false;
            expeditionnaireDupl.position = expeditionPart.expeditionnaires.length + 1;
            expeditionnaireDupl.id = null;
            expeditionPart.expeditionnaires.push(expeditionnaireDupl);
        });
        setExpedition(expeditionMod);
    };
    
    // Swap des consignes, des expeditions
    const swapConsigneExpedition = (indexExpedition: number, indexConsigne: number, indexConsigne2: number) => {
        const expeditionMod = { ...expedition };
        // au lieu d'échanger les positions dans le tableau, on va échanger la valeur order de chacune des consignes
        const consigne = expeditionMod.expedition_parts[indexExpedition].consignes[indexConsigne];
        const consigne2 = expeditionMod.expedition_parts[indexExpedition].consignes[indexConsigne2];
        const order = consigne.ordre_consigne;
        consigne.ordre_consigne = consigne2.ordre_consigne;
        consigne2.ordre_consigne = order;
        setExpedition(expeditionMod);
    };
    const swapExpedition = (indexExpedition: number, indexExpedition2: number) => {
        const expeditionMod = { ...expedition };
        const expeditionPart1 = expeditionMod.expedition_parts[indexExpedition];
        const expeditionPart2 = expeditionMod.expedition_parts[indexExpedition2];
        const number = expeditionPart1.number;
        expeditionPart1.number = expeditionPart2.number;
        expeditionPart2.number = number;
        setExpedition(expeditionMod);
    };
    
    // Sauvegarde des expeditions
    const handleSauvegardeExpedition = () => {
        setLoadApiSauvegarde(true);
        setOnDuplicate(false);
        
        const expeditionApi = new ExpeditionApi(carte.ville.map_id);
        const data = {
            map_id    : carte.ville.map_id,
            id_user   : carte.user.id,
            jour      : jourActuel,
            expedition: expedition,
        };
        expeditionApi.sauvegardeExpedition(data).then(() => {
            setLoadApiSauvegarde(false);
            setMessagePopUp(t("Mise à jour ok !", { ns: "ville" }));
            setShowPop(true);
            setStatus(Status_success);
            setTimeout(() => {
                setMessagePopUp("");
                setShowPop(false);
                // On déselectionne les expéditions si on en a sélectionné lors de la création
                expedition.expedition_parts.map((expedition_part) => {
                    if (expedition_part.trace !== null) {
                        onUpdateSeletedExpe(expedition_part.trace?.id, true);
                    }
                });
                setExpedition(JSON.parse(JSON.stringify(intialExpedition)));
                setAffExpedition([]);
                // On efface les consignes de la carte
                setConsigneExpes([]);
                setShowPartConsigne(null);
                refreshOutils(jourActuel);
            }, 2000);
        }).catch((error) => {
            console.error(error);
            setMessagePopUp(error?.data?.error ?? error?.message);
            setShowPop(true);
            setStatus(Status_error);
            setLoadApiSauvegarde(false);
        });
    };
    const handleDeverouillageExpedition = () => {
        if (expedition.id === undefined) {
            return;
        } else {
            const expeditionApi = new ExpeditionApi(carte.ville.map_id);
            const data = {
                map_id       : carte.ville.map_id,
                id_user      : carte.user.id,
                jour         : jourActuel,
                expedition_id: expedition.id,
                verrouillage : false,
            };
            expeditionApi.verouillageExpedition(data).then(() => {
                // On fait l'appel pour récupérer une mise à jour des verouillages
                expeditionApi.getVerrouExpedition({ map_id: carte.ville.map_id, id_user: carte.user.id, jour: jourActuel }).then((response) => {
                    setVerrouExpes(response.data.verrou);
                }).catch((error) => {
                    console.error(error);
                    setMessagePopUp(error?.data?.error ?? error?.message);
                    setShowPop(true);
                    setStatus(Status_error);
                });
            }).catch((error) => {
                console.error(error);
                setMessagePopUp(error?.data?.error ?? error?.message);
                setShowPop(true);
                setStatus(Status_error);
            });
        }
    };
    
    // Gestion des pseudos avec confirmation
    const handlePseudoClick = (indexExpe: number, citoyen: CitoyensDTO) => {
        if (citoyen === null) {
            handleExpeditionChange(indexExpe, { citoyen: null, preinscrit: false });
        } else {
            if (isOptionDisabled(citoyen, expedition)) {
                // On sauvegarde l'info
                setSelectionPseudo({ indexExpeditionnaire: indexExpe, optionSelected: citoyen });
                setShowConfirmation(true);
            } else {
                handleExpeditionChange(indexExpe, { citoyen: citoyen, preinscrit: true });
            }
        }
    };
    
    const handleConfirmationExpedition = () => {
        if (selectionPseudo !== null) {
            handleExpeditionChange(selectionPseudo.indexExpeditionnaire, { citoyen: selectionPseudo.optionSelected, preinscrit: true });
            setSelectionPseudo(null);
        }
        setShowConfirmation(false);
    };
    
    const handleAnnulationExpedition = () => {
        // On remet à null la sélection, y compris dans le select
        const expeditionMod = { ...expedition };
        // on balaye toutes les parties de l'expedition pour metre à jour l'expediteur
        expeditionMod.expedition_parts.map((expedition_part) => {
            expedition_part.expeditionnaires[selectionPseudo.indexExpeditionnaire].citoyen = null;
            if (expedition_part.trace !== null) {
                onUpdateSeletedExpe(expedition_part.trace.id, true);
            }
        });
        setAffExpedition([]);
        // On efface les consignes de la carte
        setConsigneExpes([]);
        setShowPartConsigne(null);
        handleDeverouillageExpedition();
        setExpedition(expeditionMod);
        setSelectionPseudo(null);
        setShowConfirmation(false);
    };
    
    const handleAnnulationSimple = () => {
        // On déselectionne les expéditions si on en a sélectionné lors de la création
        expedition.expedition_parts.map((expedition_part) => {
            if (expedition_part.trace !== null) {
                onUpdateSeletedExpe(expedition_part?.trace?.id, true);
            }
        });
        setAffExpedition([]);
        // On efface les consignes de la carte
        setConsigneExpes([]);
        setShowPartConsigne(null);
        setExpedition(JSON.parse(JSON.stringify(intialExpedition)));
        handleDeverouillageExpedition();
    };
    // Gestion des changements d'état une fois les composants changés
    useEffect(() => {
        // Si on vient changer le type d'expedition, on doit mettre à jour les expeditions afin de supprimer les parties en trop
        if (expedition.type_expe !== null && expedition.type_expe?.id !== ID_RAMASSAGE) {
            setExpedition({ ...expedition, expedition_parts: [expedition.expedition_parts[0]] });
        }
    }, [expedition.type_expe]);
    useEffect(() => {
        // Si on vient de sélectionner une case pour une consigne, on doit mettre à jour la consigne avec la zone
        if (sauvegardeIndex.indexExpe !== -1 && sauvegardeIndex.indexConsigne !== -1) {
            // transformation de l'id de la case en zone id x_y pour récupérer y*100+x pour ensuite récupérer zones(y*100+x)
            const splitCoord = idClick.split("_");
            const y = parseInt(splitCoord[1]);
            const x = parseInt(splitCoord[0]);
            
            // recuperation de la zone
            const zone = carte.ville.zones[y * 100 + x];
            const expeditionMod = { ...expedition };
            expeditionMod.expedition_parts[sauvegardeIndex.indexExpe].consignes[sauvegardeIndex.indexConsigne].zone =
                { x_rel: zone.x_rel, y_rel: zone.y_rel, x: zone.x, y: zone.y, ville: carte.ville };
            setExpedition(expeditionMod);
            // on remet à zéro les index de sauvegarde
            setSauvegardeIndex({ indexExpe: -1, indexConsigne: -1 });
            // on remet à zéro la case cliqué
            setIdClick(null);
            setIsSelCase(false);
        }
    }, [idClick]);
    
    
    const listeExpedition = [
        {
            label  : t("Carte - Gest'Hordes", { ns: "ville" }),
            options: carte.outilsExpe.liste_trace.carte,
        }, {
            label  : t("Outils Expédition", { ns: "ville" }),
            options: carte.outilsExpe.liste_trace.outils,
        }, {
            label  : t("Bibliothèque", { ns: "ville" }),
            options: carte.outilsExpe.liste_trace.biblio,
        },
    ];
    
    
    const isOptionDisabled = (option: CitoyensDTO, expedition?: ExpeditionDTO): boolean => {
        // On récupère la liste des Citoyens pré-inscrits en expédition, l'expéditionnaire doit avoir la valeur preinscrit à true pour être dans le
        // tableau (on ne prend que la partie 0 de l'expédition, les expeditionnaires sont identiques d'une partie à une autre).
        const selectedExpeditionnaire: CitoyensDTO[] = [];
        
        expeditions.map((expeditionOutils: ExpeditionDTO) => {
            if (expedition !== undefined && expedition?.id !== null && expedition.id !== expeditionOutils.id || expedition === undefined ||
                expedition?.id === null) {
                expeditionOutils.expedition_parts[0].expeditionnaires.map((expeditionnaire: ExpeditionnaireDTO) => {
                    if (expeditionnaire.citoyen !== null) {
                        selectedExpeditionnaire.push(expeditionnaire.citoyen);
                    }
                });
            }
        });
        // On fait la même chose pour les ouvriers pré-inscrit où on récupère les citoyens
        const selectedOuvriers: CitoyensDTO[] = [];
        
        ouvriers.map((ouvrierOutils: OuvriersDTO) => {
            selectedOuvriers.push(ouvrierOutils.citoyen);
        });
        
        let selectedOptions: any[];
        if (expedition) {
            // On récupère les expeditionnaires de l'expédition en cours de création/modification
            const selectedExpeditionnaireExpe = expedition.expedition_parts[0].expeditionnaires.filter(expeditionnaire => expeditionnaire.citoyen !== null)
                .flatMap(expeditionnaire => expeditionnaire.citoyen);
            // On fusionne les 3 tableaux en un
            selectedOptions = [...selectedExpeditionnaire, ...selectedExpeditionnaireExpe, ...selectedOuvriers];
        } else {
            selectedOptions = [...selectedExpeditionnaire, ...selectedOuvriers];
        }
        
        return selectedOptions.some(selectedOption => selectedOption?.citoyen.id === option.citoyen.id);
    };
    
    // Fonctin pour trouver l'expédition sélectionnée dans la liste des expéditions
    const findValueExpedition = (traceId) => {
        for (const group of listeExpedition) {
            const foundOption = group.options.find(option => option.id === traceId);
            if (foundOption) {
                return foundOption;
            }
        }
        return null;
    };
    
    const consultation = jourActuel < general.ville.jour;
    
    const controleZoneAlimConsigne = (indexExpe: number): boolean => {
        // On verifie que pour toutes les consignes il y a bien une zone de saisie, sinon on affiche un message d'avertissement pour l'expédition.
        const expeditionPart = expedition.expedition_parts[indexExpe];
        
        if (expeditionPart.consignes.length === 0) {
            // Si il n'y a pas de consigne, on n'affiche pas le message
            return true;
        }
        
        return expeditionPart.consignes.every((consigne) => {
            return consigne.zone !== null;
        });
    };
    
    // Controle pour la sauvegarde pour vérifier si toutes les consignes ont une zone
    const controleZoneAlim = expedition.expedition_parts.every((_expeditionPart, index) => {
        return controleZoneAlimConsigne(index);
    });
    
    
    const calculDisabled = () => {
        if (loadApiSauvegarde) {
            return true;
        }
        if (expedition.expedition_parts.length === 0) {
            return true;
        }
        if (expedition.expedition_parts[0].expeditionnaires.length === 0) {
            return true;
        }
        if (expedition.type_expe === undefined || expedition.type_expe === null) {
            return true;
        }
        if (!controleZoneAlim) {
            return true;
        }
        // // Pour chaque partie d'expédition, on vérifie qu'il y a bien un tracé
        // if (!expedition.expedition_parts.every((expeditionPart) => {
        //     return expeditionPart.trace !== undefined && expeditionPart.trace !== null;
        // })) {
        //     return true;
        // }
        return (expedition.nom?.length ?? 0) === 0;
        
    };
    
    
    const handleAfficherConsignePart = (indexPart: number) => {
        // Si on montre déjà les consignes, on efface les datas
        if (showPartConsigne === indexPart) {
            setShowPartConsigne(null);
            setConsigneExpes([]);
            return;
        }
        setShowPartConsigne(indexPart);
        // On récupère les consignes de la partie de l'expédition pour les transmettre à la carte afin d'afficher les drapeaux
        const arrayTab = [];
        expedition.expedition_parts[indexPart].consignes.map((consigne) => {
            arrayTab.push(consigne.zone.x + "_" + consigne.zone.y);
        });
        setConsigneExpes(arrayTab);
    };
    
    
    return <div id={"outils_expedition_gestion"}>
        <fieldset id={"outils_expedition_carac"}>
            <legend>{t("Caractéristiques", { ns: "ville" })}</legend>
            <div id={"choix_type_expe"}>
                <label>{t("Type expédition :", { ns: "ville" })}</label>
                {!consultation && liste.typeExpe.map((typeExpe, index) => {
                    return <div key={"typeExpe_" + index} className={"radio_type_expe"}>
                        <input type="radio" name="type" value={typeExpe.id} id={"typeExpe_" + index} checked={expedition.type_expe?.id === typeExpe.id}
                               onChange={(event) => {
                                   const typeExpe = liste.typeExpe.find(typeExpe => typeExpe.id === parseInt(event.target.value));
                                   setExpedition({ ...expedition, type_expe: typeExpe });
                               }} />
                        <label htmlFor={"typeExpe_" + index}><SvgIcone icone={typeExpe.icon} /> <span>{t(typeExpe.nom, { ns: "outils" })}</span></label>
                    </div>;
                })}
                {consultation && expedition.type_expe && <span className={"consultation_expedition"}><SvgIcone icone={expedition.type_expe.icon} /> <span>{expedition.type_expe.nom}</span></span>}
            </div>
            <div id={"nom_expe"}>
                <label>{t("Nom expédition :", { ns: "ville" })}</label>
                {consultation ? (
                    <span className={"consultation_expedition"}>{expedition.nom}</span>
                ) : (<input type="text" name="nom" value={expedition.nom} onChange={(event) => {
                    const expeMod = { ...expedition };
                    expeMod.nom = event.target.value;
                    setExpedition(expeMod);
                }} />)}
            </div>
            <div id={"pdc_min"}>
                <label>{t("PDC mini :", { ns: "ville" })}</label>
                {consultation ? (
                    <span className={"consultation_expedition"}>{expedition.min_pdc}</span>
                ) : (<input type="number" name="pdc_min" value={expedition.min_pdc} onChange={(event) => {
                    const expeMod = { ...expedition };
                    expeMod.min_pdc = parseInt(event.target.value);
                    setExpedition(expeMod);
                }} />)}
            </div>
        </fieldset>
        {expedition.expedition_parts.sort((exp1, exp2) => {
            return exp1.number < exp2.number ? -1 : 1;
        }).map((part, index) => {
            return <fieldset key={"part_" + index} className={"expedition_part"}>
                <legend>{expedition.type_expe?.id === ID_RAMASSAGE ? (t("Partie", { ns: "ville" }) + (index + 1)) : t("Expédition", { ns: "ville" })}</legend>
                <table>
                    <thead>
                    <tr style={{ height: "20px" }}>
                        <th className={"xsmall_cols"}>{t("Métier", { ns: "ville" })}</th>
                        <th className={"medium_cols"}>{t("Pseudo", { ns: "ville" })}</th>
                        <th className={"xsmall_cols"}>{t("PA base", { ns: "ville" })}</th>
                        <th className={"xsmall_cols"}>{t("PE base", { ns: "ville" })}</th>
                        <th className={"xsmall_cols"}>{t("Soif", { ns: "ville" })}</th>
                        <th className={"xsmall_cols"}>{t("Banni ?", { ns: "ville" })}</th>
                        <th className={"big_cols"}>{t("Sac", { ns: "ville" })}</th>
                        <th className={"medium_cols"}>{t("Action héroïque", { ns: "ville" })}</th>
                        {!consultation && <th colSpan={2} className={"medium_cols"}>{t("Action", { ns: "ville" })}</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {part.expeditionnaires.sort((exp_a, exp_b) => {
                        return exp_a.position < exp_b.position ? -1 : 1;
                    }).map((expeditionnaire: ExpeditionnaireDTO, indexExpe) => {
                        return <tr key={"expeditionnaire_" + indexExpe} style={{ height: "20px" }}>
                            <td className={"xsmall_cols"}>
                                <div>
                                    {consultation ? (
                                        expeditionnaire.job && <span className={"consultation_expedition"}><SvgIcone icone={"h_" + expeditionnaire.job.icon} /><span>{expeditionnaire.job.nom}</span></span>
                                    ) : (
                                        <Select value={expeditionnaire.job}
                                                id={"job_expeditionnaire_" + indexExpe + "_" + index}
                                                name={"job_expeditionnaire_" + indexExpe + "_" + index}
                                                options={liste.job}
                                                isClearable={true}
                                                components={{ SingleValue: JobSingleValue, Option: JobOption }}
                                                getOptionLabel={(job: JobPrototypeDTO) => job.nom}
                                                getOptionValue={(job: JobPrototypeDTO) => job.id.toString()}
                                                onChange={(optionSelected: JobPrototypeDTO) => {
                                                    if (optionSelected === null) {
                                                        handleExpeditionChange(indexExpe, { job: null, job_fige: false });
                                                    } else {
                                                        if (optionSelected.id === 4) {
                                                            handleExpeditionChange(indexExpe, { pe_base: 2, job: optionSelected, job_fige: true });
                                                        } else {
                                                            handleExpeditionChange(indexExpe, { job: optionSelected, job_fige: true });
                                                        }
                                                    }
                                                }}
                                                styles={customStyle(general.themeUser, styleJob, false)} />
                                    )}
                                </div>
                            </td>
                            <td className={"medium_cols"}>
                                <div>
                                    {consultation ? (
                                        expeditionnaire.citoyen && <span className={"consultation_expedition"}><SvgIcone icone={"h_" + expeditionnaire.citoyen.job.icon} /><span>{expeditionnaire.citoyen.citoyen.pseudo}</span></span>
                                    ) : (
                                        <Select value={expeditionnaire.citoyen}
                                                id={"citoyen_expeditionnaire_" + indexExpe + "_" + index}
                                                name={"citoyen_expeditionnaire_" + indexExpe + "_" + index}
                                                options={citoyensUser}
                                                isClearable={true}
                                                components={{ SingleValue: PseudoSingleValue, Option: PseudoOption }}
                                                getOptionLabel={(citoyen: CitoyensDTO) => {
                                                    return citoyen.citoyen.pseudo;
                                                }}
                                                onChange={(optionSelected: CitoyensDTO) => {
                                                    handlePseudoClick(indexExpe, optionSelected);
                                                }}
                                                styles={customStyleOutils(general.themeUser, stylePseudo, (citoyen) => isOptionDisabled(citoyen, expedition))} />
                                    )}
                                </div>
                            </td>
                            <td className={"xsmall_cols"}>
                                <div>
                                    {consultation ? (
                                        expeditionnaire.pa_base && <span className={"consultation_expedition"}>{expeditionnaire.pa_base}</span>
                                    ) : (
                                        <Select
                                            value={optionsPa.find(option => {
                                                if (expeditionnaire?.pa_base === undefined || expeditionnaire?.pa_base === null) {
                                                    return null;
                                                }
                                                return option.value === expeditionnaire?.pa_base;
                                            })}
                                            id={"pa_base_" + indexExpe + "_" + index}
                                            name={"pa_base_" + indexExpe + "_" + index}
                                            options={optionsPa}
                                            isClearable={true}
                                            onChange={(optionSelected: { value: number, label: number }) => handleExpeditionnaireChange(indexExpe, index, { pa_base: optionSelected?.value })}
                                            styles={customStyle(general.themeUser, styleJob, false)}
                                        />
                                    )}
                                </div>
                            </td>
                            <td className={"xsmall_cols"}>
                                <div>
                                    {consultation ? (
                                        expeditionnaire.pe_base && <span className={"consultation_expedition"}>{expeditionnaire.pe_base ?? 0}</span>
                                    ) : (
                                        <Select
                                            value={optionsPa.find(option => {
                                                if (expeditionnaire?.pe_base === undefined || expeditionnaire?.pe_base === null) {
                                                    return null;
                                                }
                                                return option.value === expeditionnaire?.pe_base;
                                            })}
                                            id={"pe_base_" + indexExpe + "_" + index}
                                            name={"pe_base_" + indexExpe + "_" + index}
                                            options={optionsPa}
                                            isClearable={true}
                                            onChange={(optionSelected: { value: number, label: number }) => handleExpeditionnaireChange(indexExpe, index, { pe_base: optionSelected?.value })}
                                            styles={customStyle(general.themeUser, styleJob, false)}
                                        />
                                    )}
                                </div>
                            </td>
                            <td className={"xsmall_cols"}>
                                <div>
                                    {consultation ? (
                                        expeditionnaire.soif !== null && <span className={"consultation_expedition"}>{expeditionnaire.soif ? t("Oui", { ns: "app" }) : t("Non", { ns: "app" })}</span>
                                    ) : (
                                        <Select value={optionsSoif.find((option: SoifType) => {
                                            if (expeditionnaire?.soif === undefined || expeditionnaire?.soif === null) {
                                                return option.value === -1;
                                            } else {
                                                return option.value === (expeditionnaire.soif ? 1 : 0);
                                            }
                                        })}
                                                id={"soif_expeditionnaire_" + indexExpe + "_" + index}
                                                name={"soif_expeditionnaire_" + indexExpe + "_" + index}
                                                getOptionLabel={(option: SoifType) => option.label}
                                                getOptionValue={(option: SoifType) => option.value.toString()}
                                                components={{ SingleValue: SoifSingleValue, Option: SoifOption }}
                                                options={optionsSoif}
                                                isClearable={true}
                                                onChange={(optionSelected: SoifType) => {
                                                    if (optionSelected === null || optionSelected.value === -1) {
                                                        handleExpeditionChange(indexExpe, { soif: null, soif_fige: false });
                                                    } else {
                                                        handleExpeditionChange(indexExpe, { soif: optionSelected.value === 1, soif_fige: true });
                                                    }
                                                    
                                                }}
                                                styles={customStyle(general.themeUser, styleJob, false)} />
                                    )}
                                </div>
                            </td>
                            <td className={"xsmall_cols"}>
                                <div>
                                    {consultation ? (
                                        expeditionnaire.for_banni !== null && <span className={"consultation_expedition"}>{expeditionnaire.for_banni ? t("Oui", { ns: "app" }) : t("Non", { ns: "ville" })}</span>
                                    ) : (
                                        <CustomSwitchToggle checked={expeditionnaire.for_banni} size={"sm"} onstyle="danger" offstyle={"success"}
                                                            onlabel={t("Oui", { ns: "app" })} offlabel={t("Non", { ns: "app" })} disabled={jourActuel < general.ville.jour}
                                                            onChange={(toggle) => handleExpeditionChange(indexExpe, { for_banni: toggle })} />
                                    )}
                                </div>
                            </td>
                            <td className={"big_cols"}>
                                <div className={"zone_sac_expeditionnaire"}>
                                    <div className={"sac_expeditionnaire"}>{expeditionnaire.sac.map((sacItems) => {
                                        return <div key={`obj_${sacItems.item.id}_${sacItems.broken ? 1 : 0}`}>
                                            <AffNbrIcon
                                                item={sacItems.item}
                                                broken={sacItems.broken}
                                                nbr={sacItems.nbr}
                                                classSpecifique={"itemSacOutilsExpedition"}
                                            />
                                        </div>;
                                    })}</div>
                                    {!consultation && <button className={"btn btn-primary btn-xs"} onClick={() => {
                                        setIndexSac({ i_part: index, i_exp: indexExpe });
                                        setShowPopUpObjet(true);
                                    }}>{t("Modifier sac", { ns: "ville" })}</button>}
                                </div>
                            </td>
                            <td className={"medium_cols"}>
                                <div>
                                    {consultation ? (
                                        expeditionnaire.action_heroic && <span className={"consultation_expedition"}><SvgIcone icone={expeditionnaire.action_heroic.icon} /><span>{expeditionnaire.action_heroic.nom}</span></span>
                                    ) : (
                                        <Select value={expeditionnaire.action_heroic}
                                                id={"ah_expeditionnaire_" + indexExpe + "_" + index}
                                                name={"ah_expeditionnaire_" + indexExpe + "_" + index}
                                                options={liste.pouvoir}
                                                isClearable={true}
                                                components={{ SingleValue: HerosSingleValue, Option: HerosOption }}
                                                getOptionLabel={(pouv: HerosPrototypeDTO) => pouv.nom}
                                                getOptionValue={(pouv: HerosPrototypeDTO) => pouv.id.toString()}
                                                onChange={(optionSelected: HerosPrototypeDTO) => handleExpeditionnaireChange(indexExpe, index, { action_heroic: optionSelected })}
                                                styles={customStyle(general.themeUser, stylePseudo, false)} />
                                    )}
                                </div>
                            </td>
                            {!consultation && <td className={"sm_cols"}>
                                <span className={"d-flex gap-2"}>
                                    <button className={"btn btn-danger btn-xs"} onClick={() => handleDeleteExpeditionnaire(indexExpe)}><i className="fa-solid fa-trash"></i></button>
                                    <button className={"btn btn-warning btn-xs"} onClick={() => handleDuplicateExpeditionnaire(indexExpe)}><i className="fa-solid fa-copy"></i></button>
                                </span>
                            </td>}
                            {!consultation && indexExpe === 0 && <td rowSpan={part.expeditionnaires.length} className={"small_cols"}>
                                {expedition.expedition_parts.length > 1 && <div>
                                    <button className={"btn btn-danger btn-xs"} onClick={() => handleDeleteExpeditionPart(index)}>
                                        <i className="fa-solid fa-trash"></i>
                                    </button>
                                    <button
                                        className={"btn btn-secondary btn-xs"}
                                        onClick={() => swapExpedition(index, index - 1)}
                                        disabled={index === 0}
                                    >
                                        <i className="fa-solid fa-circle-chevron-up"></i>
                                    </button>
                                    <button
                                        className={"btn btn-secondary btn-xs"}
                                        onClick={() => swapExpedition(index, index + 1)}
                                        disabled={index === expedition.expedition_parts.length - 1}
                                    >
                                        <i className="fa-solid fa-circle-chevron-down"></i>
                                    </button>
                                </div>}
                            </td>}
                        </tr>;
                    })}
                    </tbody>
                </table>
                <div className={"zone_choix_trace_expedition_select"}>
                    <label>{t("Tracé d'expédition :", { ns: "ville" })}</label>
                    {part.id && part.trace && <span style={{ display: "flex", gap: "5px" }}><span>{part.trace.nom}</span><span
                        style={{ display: "flex" }}>(<span>{part.trace.pa}</span><SvgIcone icone={"h_pa"} /></span>) </span>}
                    {(!part.id || !part.trace) && <Select value={findValueExpedition(part.trace?.id)}
                                                          id={"trace_part_" + index}
                                                          name={"trace_part_" + index}
                                                          options={listeExpedition}
                                                          isClearable={true}
                                                          components={{
                                                              SingleValue : ExpeSingleValue, Option: ExpeOption,
                                                              GroupHeading: CustomGroupHeading,
                                                          }}
                                                          getOptionLabel={(expedition: Expedition) => expedition.nom}
                                                          onChange={(optionSelected: Expedition) => handleTraceExpeditionChange(index, { trace: optionSelected })}
                                                          styles={customStylesExpe} />
                    }
                    {part.trace && <button type={"button"} className={"btn btn-primary btn-xs"} onClick={() => {
                        onUpdateSeletedExpe(part.trace.id, false);
                        //on récupère les id des expéditions affichées
                        const newAffExpedition = [...affExpedition];
                        if (newAffExpedition.includes(part.trace.id)) {
                            newAffExpedition.splice(newAffExpedition.indexOf(part.trace.id), 1);
                        } else {
                            newAffExpedition.push(part.trace.id);
                        }
                        setAffExpedition(newAffExpedition);
                    }}>{affExpedition.includes(part.trace.id) ? t("Masquer l'expédition", { ns: "ville" }) : t("Afficher l'expédition", { ns: "ville" })}</button>
                    }
                </div>
                <div className={"zone_choix_description_expedition"}>
                    <span className={"bloc_label_bouton_description"}>
                        <label>{t("Description/Commentaire", { ns: "ville" })}<br />{t("Consigne générale", { ns: "ville" })}</label>
                        {!consultation && <button className={"btn btn-primary btn-xs"} onClick={() => {
                            setIndexDescription(index);
                        }}>{t("Modifier description", { ns: "ville" })}</button>}
                    </span>
                    <span className={"consultation_expedition"}>{HTMLParser(part.description ?? "")}</span>
                </div>
                {part.consignes?.length > 0 && <table>
                    <thead>
                    <tr>
                        <th className={"small_cols_cons"}>
                            <span>{t("Case consigne", { ns: "ville" })}</span>
                            <TooltipGH>
                                <span className={"infoBulle"}>
                                    <FontAwesomeIcon icon={faCircleQuestion} />
                                </span>
                                <span className={"info"}>{t("Cliquez sur une case de la carte afin d'y associer la consigne à une case en particulier", { ns: "ville" })}</span>
                            </TooltipGH>
                        </th>
                        <th>{t("Consignes expéditions", { ns: "ville" })}</th>
                        {!consultation && <th className={"small_cols_cons"}>{t("Action", { ns: "ville" })}</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {part.consignes?.sort((consigne1: ConsigneExpeditionDTO, consigne2: ConsigneExpeditionDTO) => {
                        return consigne1.ordre_consigne < consigne2.ordre_consigne ? -1 : 1;
                    }).map((consigne, indexConsigne) => {
                        return <tr key={"consigne_" + indexConsigne}>
                            <td className={"small_cols_cons"}>
                                <div className={"coordonnees"}>
                                    {consigne.zone === null &&
                                        <button className={"btn btn-primary btn-xs"} onClick={() => handleAddModZoneConsigne(index, indexConsigne)}>{(isSelCase &&
                                            sauvegardeIndex.indexConsigne === indexConsigne && sauvegardeIndex.indexExpe === index) ?
                                            (<Spinner animation="border" size="sm" role="status"></Spinner>) :
                                            <i className="fa-solid fa-map-location-dot"></i>}</button>}
                                    {consigne.zone !== null && <><span>({consigne.zone.x_rel}/{consigne.zone.y_rel})</span>
                                        {!consultation && <button className={"btn btn-success btn-xs"} onClick={() => handleAddModZoneConsigne(index, indexConsigne)}>{(isSelCase &&
                                            sauvegardeIndex.indexConsigne === indexConsigne && sauvegardeIndex.indexExpe === index) ?
                                            (<Spinner animation="border" size="sm" role="status"></Spinner>) :
                                            <i className="fa-solid fa-pen-to-square"></i>}</button>}
                                    </>}
                                </div>
                            </td>
                            <td>
                                <div className={"zone_consigne_expedition"}>
                                    <div className={"consultation_expedition"}>{HTMLParser(consigne.text)}</div>
                                    {!consultation && <button className={"btn btn-primary btn-xs"} onClick={() => {
                                        setIndexConsigne({ i_part: index, i_consigne: indexConsigne });
                                    }}>{t("Modifier consigne", { ns: "ville" })}</button>}
                                </div>
                            </td>
                            {!consultation && <td className={"small_cols_cons"}>
                                <button className={"btn btn-danger btn-xs"} onClick={() => handleDeleteConsigneExpedition(index, indexConsigne)}><i className="fa-solid fa-trash"></i></button>
                                {part.consignes.length > 1 && (
                                    <button className={"btn btn-secondary btn-xs"} onClick={() => swapConsigneExpedition(index, indexConsigne, indexConsigne - 1)} disabled={indexConsigne === 0}>
                                        <i className="fa-solid fa-circle-chevron-up"></i>
                                    </button>
                                )}
                                {part.consignes.length > 1 && (
                                    <button className={"btn btn-secondary btn-xs"} onClick={() => swapConsigneExpedition(index, indexConsigne, indexConsigne + 1)} disabled={indexConsigne === part.consignes.length - 1}>
                                        <i className="fa-solid fa-circle-chevron-down"></i>
                                    </button>
                                )}
                            </td>}
                        </tr>;
                    })}
                    </tbody>
                </table>}
                {!controleZoneAlimConsigne(index) && <div className={"alert alert-danger"}>{t("Attention, toutes les consignes n'ont pas de zone associée", { ns: "ville" })}</div>}
                {!consultation && <button type={"button"} className={"btn btn-primary btn-xs"} onClick={() => handleAddConsigneExpedition(index)}>{t("Ajouter une consigne", { ns: "ville" })}</button>}
                {part.consignes.length > 0 && <button type={"button"} className={"btn btn-primary btn-xs"} onClick={() => handleAfficherConsignePart(index)}>{showPartConsigne === index ? t("Masquer les consignes sur la carte", { ns: "ville" }) : t("Afficher les consignes sur la carte", { ns: "ville" })}</button>}
            </fieldset>;
        })}
        <div id={"zone_button"}>
            {consultation ? (
                <button className={"btn btn-warning btn-xs"} onClick={() => {
                    setOngletActuel("list");
                    setExpedition(JSON.parse(JSON.stringify(intialExpedition)));
                }}>{t("Retourner à la liste", { ns: "ville" })}</button>
            ) : (
                <>
                    <div>
                        {expedition.type_expe?.id === ID_RAMASSAGE && <button type={"button"} className={"btn btn-primary btn-xs"} onClick={handleAddPartExpedition}>{t("Ajouter une partie", { ns: "ville" })}</button>}
                        <button type={"button"} className={"btn btn-primary btn-xs"} onClick={handleAddExpeditionnaire}>{t("Ajouter un expéditionnaire", { ns: "ville" })}</button>
                    </div>
                    <button className={"btn btn-success btn-xs"} disabled={calculDisabled()} onClick={() => handleSauvegardeExpedition()}>{loadApiSauvegarde ?
                        <Spinner animation="border" size="sm" role="status"></Spinner> : (expedition.id ? t("Enregistrer les modifications de l'expédition", { ns: "ville" }) : t("Créer une nouvelle expedition", { ns: "ville" }))}</button>
                    {!expedition.id && <button className={"btn btn-warning btn-xs"} onClick={() => {
                        // On réinitialise l'expedition
                        handleAnnulationSimple();
                    }}>{t("Annuler l'expédition", { ns: "ville" })}</button>}
                    {expedition.id && <button className={"btn btn-warning btn-xs"} onClick={() => {
                        setOngletActuel("list");
                        handleAnnulationSimple();
                    }}>{t("Annuler l'édition et revenir à la liste", { ns: "ville" })}</button>}
                </>
            )}
        
        </div>
        <div className={"historique_modification_outils_expe"}>
            {expedition.id !== null && <>
                {expedition.created_by && <span>{HTMLParser(t("Crée par <strong>{createur}</strong> le <em>{date}</em> ", { ns: "ville" }).replace("{createur}", expedition.created_by?.pseudo).replace("{date}", formatInTimeZone(new Date(Date.parse(expedition.created_at)), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))))}</span>}
                {expedition.modify_by && <span>{HTMLParser(t("Modifiée par <strong>{modificateur}</strong> le <em>{date}</em> ", { ns: "ville" }).replace("{modificateur}", expedition.modify_by?.pseudo).replace("{date}", formatInTimeZone(new Date(Date.parse(expedition.modify_at)), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))))}</span>}
            </>}
        </div>
        <CompteursMetiers />
        <Modal show={showPopUpObjet} onHide={() => setShowPopUpObjet(false)} centered key={"modal_sac_expeditionnaire"}>
            {showPopUpObjet && <div id="popUpSacExpeditionnaire">
                <PopUpMajSacExpeditionnaire expeditionnaire={expedition.expedition_parts[indexSac.i_part].expeditionnaires[indexSac.i_exp]}
                                            expeditionnaireInitial={JSON.parse(JSON.stringify(expedition.expedition_parts[indexSac.i_part].expeditionnaires[indexSac.i_exp]))}
                                            popUpMaj={carte.outilsExpe.popUpMaj}
                                            onCancel={(expedionnaireMod) => {
                                                setShowPopUpObjet(false);
                                                setIndexSac(null);
                                                const expeditionMod = { ...expedition };
                                                expeditionMod.expedition_parts[indexSac.i_part].expeditionnaires[indexSac.i_exp] = expedionnaireMod;
                                                setExpedition(expeditionMod);
                                            }}
                                            onSaveCase={(expedionnaireMod) => {
                                                const expeditionMod = { ...expedition };
                                                expeditionMod.expedition_parts[indexSac.i_part].expeditionnaires[indexSac.i_exp] = expedionnaireMod;
                                                setExpedition(expeditionMod);
                                                setShowPopUpObjet(false);
                                            }}
                                            sacRapide={carte.outilsExpe.sacRapide}
                                            stock={calculStockRestant(carte.listItemsBank)}
                />
            </div>}
        </Modal>
        <Modal show={indexDescription !== null} onHide={() => setIndexDescription(null)} centered key={"modal_description_expedition"}>
            {indexDescription !== null && <div id={"popUpConsigne"}>
                <PopUpConsigne translate={{
                    valider: t("Valider", { ns: "ville" }),
                    annuler: t("Annuler", { ns: "ville" }),
                    phrase : t("Phrase pré-faite :", { ns: "ville" }),
                    choix  : t("Choisir une phrase", { ns: "ville" }),
                }}
                               texte={expedition.expedition_parts[indexDescription]?.description}
                               onChange={(text) => {
                                   handleDescriptionChange(indexDescription, text);
                                   setIndexDescription(null);
                               }}
                               onCancel={() => setIndexDescription(null)}
                               lang={general.user.lang}
                               listeConsignes={carte.outilsExpe.liste.phrase}
                />
            </div>}
        </Modal>
        <Modal show={indexConsigne !== null} onHide={() => setIndexConsigne(null)} centered key={"modal_consigne_expedition"}>
            {indexConsigne !== null && <div id={"popUpConsigne"}>
                <PopUpConsigne translate={{
                    valider: t("Valider", { ns: "ville" }),
                    annuler: t("Annuler", { ns: "ville" }),
                    phrase : t("Phrase pré-faite :", { ns: "ville" }),
                    choix  : t("Choisir une phrase", { ns: "ville" }),
                }}
                               texte={expedition.expedition_parts[indexConsigne.i_part]?.consignes[indexConsigne.i_consigne].text}
                               onChange={(textMod) => {
                                   handleConsigneExpeditionChange(indexConsigne.i_part, indexConsigne.i_consigne, { text: textMod });
                                   setIndexConsigne(null);
                               }}
                               onCancel={() => setIndexConsigne(null)}
                               lang={general.user.lang}
                               listeConsignes={carte.outilsExpe.liste.phrase}
                />
            </div>}
        </Modal>
        <ConfirmationDialog
            message={t("Êtes-vous sur de pré-inscrire ce citoyen à nouveau ?", { ns: "hotel" })}
            titre={t("Attention !", { ns: "hotel" })}
            btnAnnuler={t("Annuler", { ns: "ville" })}
            btnConfirmation={t("Confirmer", { ns: "ville" })}
            show={showConfirmation}
            onConfirm={handleConfirmationExpedition}
            onCancel={handleAnnulationExpedition}
        />
    </div>;
    
    
}

