import React, { useEffect, useMemo, useRef }         from "react";
import { ComparatifVilles, OptionsVillesComparatif } from "../../types/components/Villes/VillesType";
import { useGeneralContext }                         from "../../types/Context/GeneralContext";
import CollapsibleFieldset                           from "../../components/utils/CollapsibleFieldset";
import { useTranslation }                            from "react-i18next";
import SvgIcone                                      from "../../components/generality/SvgIcone";
import { CitoyensDTO }                               from "../../types/models/citoyens.dto";
import { Avatar }                                    from "../../components/generality/ComposantGeneral";
import TooltipGH                                     from "../../components/utils/TooltipGH";
import { JournalDTO }                                from "../../types/models/journal.dto";
import HTMLParser                                    from "html-react-parser";
import { ScrutateurLib }                             from "../Hotel/Journal/Journal";
import { AffNbrIconBank }                            from "../Ville/Banque/Banque";
import { ChantierPrototypeDTO }                      from "../../types/models/chantierPrototype.dto";
import { ChantiersDTO }                              from "../../types/models/chantiers.dto";
import chroma                                        from "chroma-js";
import { PlansChantierDTO }                          from "../../types/models/plansChantier.dto";
import { faCircleInfo }                              from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import { FontAwesomeIcon }                           from "@fortawesome/react-fontawesome";
import { ZoneMapDTO }                                from "../../types/models/zoneMap.dto";
import { UserPersoCouleurDTO }                       from "../../types/models/userPersoCouleur.dto";
import { faRuler }                                   from "@fortawesome/free-solid-svg-icons";
import { Link }                                      from "react-router-dom";
import { DragDropContext, Draggable, Droppable }     from "@hello-pangea/dnd";

interface ComparatifsVillesProps {
    villes: ComparatifVilles[];
    options: OptionsVillesComparatif;
}

const getIconePlan = (chantier: ChantierPrototypeDTO) => {
    if (chantier.plan === 0) {
        return null;
    }
    switch (chantier.plan) {
        case 1:
            return "item_bplan_c";
        case 2:
            return "item_bplan_u";
        case 3:
            return "item_bplan_r";
        case 4:
            return "item_bplan_e";
    }
};

const planTrouve = (chantier: ChantierPrototypeDTO, plans: PlansChantierDTO[]) => {
    return plans.find(plan => plan.chantier.id === chantier.id) !== undefined;
};

export default function ComparatifsVilles({ villes, options }: ComparatifsVillesProps) {
    const { general } = useGeneralContext();
    const { t } = useTranslation();
    const [collapseResume, setCollapseResume] = React.useState<boolean>(false);
    const [collapseCitoyens, setCollapseCitoyens] = React.useState<boolean>(false);
    const [collapseBanques, setCollapseBanques] = React.useState<boolean>(false);
    const [collapseChantiers, setCollapseChantiers] = React.useState<boolean>(false);
    const [collapseUpChantiers, setCollapseUpChantiers] = React.useState<boolean>(false);
    const [collapseCarte, setCollapseCarte] = React.useState<boolean>(false);
    const [collapseGazette, setCollapseGazette] = React.useState<boolean>(false);
    const [collapseStats, setCollapseStats] = React.useState<boolean>(false);
    const [orderedVilles, setOrderedVilles] = React.useState(villes);
    const [leftOffset, setLeftOffset] = React.useState(0);
    const contentRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = React.useState<string>("100%");
    const [needsBorder, setNeedsBorder] = React.useState<boolean>(false);
    
    useEffect(() => {
        setOrderedVilles(villes);
    }, [villes]);
    
    // Refs pour chaque type de fieldset
    const resumeRefs = useRef<(HTMLDivElement | null)[]>([]);
    const citoyensRefs = useRef<(HTMLDivElement | null)[]>([]);
    const banquesRefs = useRef<(HTMLDivElement | null)[]>([]);
    const chantiersRefs = useRef<(HTMLDivElement | null)[]>([]);
    const upChantiersRefs = useRef<(HTMLDivElement | null)[]>([]);
    const carteRefs = useRef<(HTMLDivElement | null)[]>([]);
    const gazetteRefs = useRef<(HTMLDivElement | null)[]>([]);
    const statsRefs = useRef<(HTMLDivElement | null)[]>([]);
    
    // État pour stocker les hauteurs calculées
    const [heights, setHeights] = React.useState({
        resume     : 0,
        citoyens   : 0,
        banques    : 0,
        chantiers  : 0,
        upChantiers: 0,
        carte      : 0,
        gazette    : 0,
        stats      : 0,
    });
    
    const synchronizeHeights = () => {
        // Fonction pour trouver la hauteur maximale dans un ensemble de refs
        const getMaxHeight = (refs: (HTMLDivElement | null)[]) => {
            return Math.max(...refs
                .filter(ref => ref !== null)
                .map(ref => {
                    return ref.scrollHeight;
                }),
            );
        };
        
        // Calculer les nouvelles hauteurs maximales
        const newHeights = {
            resume     : getMaxHeight(resumeRefs.current),
            citoyens   : getMaxHeight(citoyensRefs.current),
            banques    : getMaxHeight(banquesRefs.current),
            chantiers  : getMaxHeight(chantiersRefs.current),
            upChantiers: getMaxHeight(upChantiersRefs.current),
            carte      : getMaxHeight(carteRefs.current),
            gazette    : getMaxHeight(gazetteRefs.current),
            stats      : getMaxHeight(statsRefs.current),
        };
        
        setHeights(newHeights);
    };
    
    const calculateLayout = () => {
        const bodyElement = document.getElementById("corps");
        const contentElement = contentRef.current;
        
        if (bodyElement && contentElement) {
            const bodyWidth = bodyElement.clientWidth;
            // Force temporairement max-content pour avoir la vraie largeur
            contentElement.style.width = "max-content";
            const contentWidth = contentElement.scrollWidth;
            
            if (contentWidth > bodyWidth) {
                const bodyRect = bodyElement.getBoundingClientRect();
                setLeftOffset(-bodyRect.left);
                setWidth("max-content");
                setNeedsBorder(true);
            } else {
                setLeftOffset(0);
                setWidth("100%");
                setNeedsBorder(false);
            }
        }
    };
    
    
    // Mettre à jour les hauteurs quand les données changent ou quand un fieldset est déplié
    useEffect(() => {
        synchronizeHeights();
        calculateLayout();
    }, [
        orderedVilles,
        collapseResume,
        collapseCitoyens,
        collapseBanques,
        collapseChantiers,
        collapseUpChantiers,
        collapseCarte,
        collapseGazette,
        collapseStats,
    ]);
    
    
    // Resynchroniser les hauteurs lors du redimensionnement de la fenêtre, et calculer le layout initial
    useEffect(() => {
        const bodyElement = document.getElementById("corps");
        const contentElement = contentRef.current;
        
        if (!contentElement || !bodyElement) {
            return;
        }
        
        const resizeObserver = new ResizeObserver(calculateLayout);
        resizeObserver.observe(contentElement);
        resizeObserver.observe(bodyElement);
        // Gestionnaire de resize
        const handleResize = () => {
            synchronizeHeights();
        };
        
        window.addEventListener("resize", handleResize);
        
        return () => {
            resizeObserver.disconnect();
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    
    
    const getContentLang = ({ journal }: { journal: JournalDTO }) => {
        // En fonction de la langue, on adapte le contenu de la gazette
        switch (general.lang) {
            case "fr":
                return journal.content ?? "";
            case "en":
                return journal.content_en ?? "";
            case "es":
                return journal.content_es ?? "";
            case "de":
                return journal.content_de ?? "";
        }
    };
    
    const getColorText = (build: boolean) => {
        const color = chroma(general.themeUser.primary_font_color);
        if (build) {
            return color.css();
        } else {
            return color.alpha(0.3).css();
        }
    };
    
    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        
        const items = Array.from(orderedVilles);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        
        setOrderedVilles(items);
    };
    
    return (
        <div className={`background-primary ${needsBorder ? "border-solid-1" : ""} ps-2 pe-2`}
             style={{
                 position    : "relative",
                 left        : leftOffset,
                 width       : width,
                 top         : "-11px",
                 marginBottom: "1rem",
                 margin      : "auto",
             }}
             ref={contentRef}
        >
            <h2 style={{ textAlign: "center" }}>{t("Comparatif des villes", { ns: "villes" })}</h2>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="villes" direction="horizontal">
                    {(provided) => (
                        <div className="d-flex gap-2" ref={provided.innerRef}{...provided.droppableProps}>
                            {orderedVilles.map((ville, index) => {
                                return <Draggable
                                    key={ville.resume.ville.map_id}
                                    draggableId={ville.resume.ville.map_id.toString()}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef}{...provided.draggableProps} className={`border-solid-2 background-secondary ${snapshot.isDragging ? "opacity-75" : ""}`} style={{ width: "300px", ...provided.draggableProps.style }}>
                                            <div
                                                {...provided.dragHandleProps}
                                                className="d-flex justify-content-center align-items-center p-1 cursor-grab"
                                            >
                                                <i className="fa-solid fa-grip-horizontal text-gray-400" />
                                            </div>
                                            <h3 style={{ textAlign: "center", textOverflow: "ellipsis", width: "300px", overflow: "hidden", height: "44px" }} className={"text-break"}>{ville.resume.ville.nom_jump ?? ville.resume.ville.map_id}</h3>
                                            <CollapsibleFieldset title={t("Résumé", { ns: "villes" })} className={"ps-1 pe-1"} collapse={collapseResume} actionExterne={(collapse) => setCollapseResume(collapse)}>
                                                <div ref={el => resumeRefs.current[index] = el}
                                                     style={{ minHeight: collapseResume ? undefined : heights.resume }}
                                                     className={"d-flex gap-2 flex-column"}>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Ville", { ns: "villes" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"town"} />
                                                                <div>{t("Nom :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div style={{ width: "200px", textAlign: "end" }}>{ville.resume.ville.nom}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"dexplo"} />
                                                                <div>{t("N° carte :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div><span className={"lien_gh"}><Link to={"/carte/" + ville.resume.ville.map_id} className={"d-flex gap-1 justify-content-start"}><span>{ville.resume.ville.map_id}</span><i className="fa-solid fa-arrow-up-right-from-square"></i></Link></span></div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"recent"} />
                                                                <div>{t("Jour :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.ville.jour}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"alarm"} />
                                                                <div>{t("Etat :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{t(ville.resume.ville.etat, { ns: "game" })}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"fired"} />
                                                                <div>{t("Points :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.ville.points}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"r_wintop"} />
                                                                <div>{t("Saison :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.ville.saison}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Nombre de Citoyens", { ns: "villes" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"citizen"} />
                                                                <div>{t("En vie :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.citoyens.nb_vivant}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"h_ban"} />
                                                                <div>{t("Bannis :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.citoyens.nb_banni}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"death"} />
                                                                <div>{t("Mort :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.citoyens.nb_mort}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Nombre de Provisions", { ns: "villes" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"h_water"} />
                                                                <div>{t("Eau :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.provisions.nb_eau}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_chest_food"} />
                                                                <div>{t("Nourritures :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.provisions.nb_nourriture}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"r_drug"} />
                                                                <div>{t("Drogues :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.provisions.nb_drogues}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"alc"} />
                                                                <div>{t("Alcools :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.provisions.nb_alcool}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_coffee"} />
                                                                <div>{t("Cafés :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.provisions.nb_cafe}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Nombre de Ressources", { ns: "villes" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_wood_bad"} />
                                                                <SvgIcone icone={"item_wood2"} />
                                                                <SvgIcone icone={"item_wood_beam"} />
                                                                <div>{t("Bois :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.ressources.nb_bois}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_metal_bad"} />
                                                                <SvgIcone icone={"item_metal"} />
                                                                <SvgIcone icone={"item_metal_beam"} />
                                                                <div>{t("Métal :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.ressources.nb_metal}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_concrete"} />
                                                                <SvgIcone icone={"item_concrete_wall"} />
                                                                <div>{t("Bétons :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.ressources.nb_beton}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_electro"} />
                                                                <div>{t("Composant électronique :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.ressources.nb_compo}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_meca_parts"} />
                                                                <div>{t("Poignée de vis et d'écrou :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.ressources.nb_pve}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_tube"} />
                                                                <div>{t("Tube de cuivre :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.ressources.nb_tube}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_explo"} />
                                                                <div>{t("Explossifs :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.ressources.nb_explo}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_pile"} />
                                                                <div>{t("Piles :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.ressources.nb_piles}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_lens"} />
                                                                <div>{t("Lentilles :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.ressources.nb_lentille}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_ryebag"} />
                                                                <div>{t("Sac d'herbe :", { ns: "villes" })}</div>
                                                            </div>
                                                            <div>{ville.resume.ressources.nb_sac}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Défense", { ns: "ville" })}</span>
                                                                <span>
                                                <span>(</span>
                                                <span>{ville.resume.defense.total}</span>
                                                <span>)</span>
                                            </span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <div>{t("Muraille :", { ns: "ville" })}</div>
                                                            </div>
                                                            <div>10</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <div>{t("Chantiers :", { ns: "ville" })}</div>
                                                            </div>
                                                            <div>{ville.resume.defense.buildings + ville.resume.defense.upgrades} ➔ ({ville.resume.defense.buildings} + {ville.resume.defense.upgrades})</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <div>{t("Objets de défense :", { ns: "ville" })}</div>
                                                            </div>
                                                            <div className={"d-flex gap-1 align-items-center"}><span>{Math.floor(ville.resume.defense.objet * ville.resume.defense.bonus_od / 10)}</span><span>➔ (</span><span className={"d-flex gap-1 align-items-center"}><span>{ville.resume.defense.objet}</span> <SvgIcone
                                                                icone={"item_plate"} /></span><span> x {ville.resume.defense.bonus_od / 10})</span></div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <div>{t("Maisons des citoyens :", { ns: "ville" })}</div>
                                                            </div>
                                                            <div>{ville.resume.defense.maison_citoyen}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <div>{t("Gardiens :", { ns: "ville" })}</div>
                                                            </div>
                                                            <div>{ville.resume.defense.gardiens}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <div>{t("Veilleurs :", { ns: "ville" })}</div>
                                                            </div>
                                                            <div>{ville.resume.defense.veilleurs}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <div>{t("Temporaires :", { ns: "ville" })}</div>
                                                            </div>
                                                            <div>{ville.resume.defense.tempos}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <div>{t("Âmes :", { ns: "ville" })}</div>
                                                            </div>
                                                            <div>{ville.resume.defense.ames ?? 0}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <div>{t("Morts :", { ns: "ville" })}</div>
                                                            </div>
                                                            <div>{ville.resume.defense.morts ?? 0}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <div>{t("Bonus SD (+{bonusSd}%) :", { ns: "ville" }).replace("{bonusSd}", ville.resume.defense.bonus_sd_pct.toString())}</div>
                                                            </div>
                                                            <div>{ville.resume.defense.def_s_d}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Estimations", { ns: "villes" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        {ville.resume.estim === null && <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>{t("N/A", { ns: "villes" })}</div>}
                                                        {ville.resume.estim !== null && ville.resume.estim.min_jour !== 0 && <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_tagger"} />
                                                                <span>{t("Tour de guet :", { ns: "ville" })}</span>
                                                                {ville.resume.estim.maxed_jour && <span className={"color-red"}>(max)</span>}
                                                                {!ville.resume.estim.maxed_jour && <span>(??%)</span>}
                                                            </div>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <div>{ville.resume.estim.min_jour}</div>
                                                                <div>-</div>
                                                                <div>{ville.resume.estim.max_jour}</div>
                                                            </div>
                                                        </div>}
                                                        {ville.resume.estim !== null && ville.resume.estim.min_planif !== 0 && <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_tagger"} />
                                                                <span>{t("Planificateur :", { ns: "ville" })}</span>
                                                                {ville.resume.estim.maxed_planif && <span className={"color-red"}>(max)</span>}
                                                                {!ville.resume.estim.maxed_planif && <span>(??%)</span>}
                                                            </div>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <div>{ville.resume.estim.min_planif}</div>
                                                                <div>-</div>
                                                                <div>{ville.resume.estim.max_planif}</div>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </CollapsibleFieldset>
                                            <CollapsibleFieldset title={t("Citoyens", { ns: "villes" })} className={"ps-1 pe-1"} collapse={collapseCitoyens} actionExterne={(collapse) => setCollapseCitoyens(collapse)}>
                                                <div ref={el => citoyensRefs.current[index] = el}
                                                     style={{ minHeight: collapseCitoyens ? undefined : heights.citoyens }}
                                                     className={"d-flex gap-2 flex-column"}>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Les citoyens", { ns: "villes" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        {ville.citoyens.sort((cit_a, cit_b) => {
                                                            // On tri par vivant puis par pseudo pour les vivants, pour les morts on tri par jour de mort puis par pseudo
                                                            if (cit_a.mort !== cit_b.mort) {
                                                                return cit_a.mort ? 1 : -1;
                                                            }
                                                            
                                                            // Si les deux sont morts, on trie par jour de mort puis par pseudo
                                                            if (cit_a.mort && cit_b.mort) {
                                                                // On trie par jour de mort
                                                                if (cit_a.death_day !== cit_b.death_day) {
                                                                    return cit_b.death_day - cit_a.death_day;
                                                                }
                                                                
                                                                // Si c'est la même date de mort, on trie par pseudo
                                                                return cit_a.citoyen.pseudo.localeCompare(cit_b.citoyen.pseudo);
                                                            }
                                                            
                                                            // Si les deux sont vivants, on trie par pseudo
                                                            return cit_a.citoyen.pseudo.localeCompare(cit_b.citoyen.pseudo);
                                                            
                                                        }).map((citoyen, index) => (
                                                            <div key={index} className={"d-flex gap-1 align-items-center justify-content-between"}>
                                                                <LigneCitoyenComparaison citoyen={citoyen} x={ville.resume.ville.x} y={ville.resume.ville.y} etatChaos={ville.resume.ville.etat !== "Normal"} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Compteurs métiers", { ns: "villes" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        {options.jobs.sort((job_a, job_b) => {
                                                            const job_a_trad = t(job_a.nom, { ns: "game" });
                                                            const job_b_trad = t(job_b.nom, { ns: "game" });
                                                            return job_a_trad.localeCompare(job_b_trad);
                                                        }).map((job, index) => {
                                                            const nombre = ville.citoyens.filter(citoyen => citoyen.job.id === job.id && !citoyen.mort).length;
                                                            if (nombre === 0) {
                                                                return null;
                                                            }
                                                            return (
                                                                <div key={index} className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                                    <div className={"d-flex gap-1 align-items-center"}>
                                                                        <SvgIcone icone={"h_" + job.icon} />
                                                                        <span>{job.nom}</span>
                                                                    </div>
                                                                    <div>{nombre}</div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Compteurs habitations", { ns: "villes" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        {options.homes.sort((maison_a, maison_b) => {
                                                            const maison_a_trad = t(maison_a.nom, { ns: "game" });
                                                            const maison_b_trad = t(maison_b.nom, { ns: "game" });
                                                            return maison_a_trad.localeCompare(maison_b_trad);
                                                        }).map((maison, index) => {
                                                            const nombre = ville.citoyens.filter(citoyen => citoyen.lvl_maison.id === maison.id && !citoyen.mort).length;
                                                            if (nombre === 0) {
                                                                return null;
                                                            }
                                                            return (
                                                                <div key={index} className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                                    <div className={"d-flex gap-1 align-items-center"}>
                                                                        <SvgIcone icone={"home_" + maison.icon} />
                                                                        <span>{maison.nom}</span>
                                                                    </div>
                                                                    <div>{nombre}</div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </CollapsibleFieldset>
                                            <CollapsibleFieldset title={t("Carte", { ns: "villes" })} className={"ps-1 pe-1"} collapse={collapseCarte} actionExterne={(collapse) => setCollapseCarte(collapse)}>
                                                <div ref={el => carteRefs.current[index] = el}
                                                     style={{ minHeight: collapseCarte ? undefined : heights.carte }}
                                                     className={"d-flex gap-2 flex-column"}>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Carte", { ns: "villes" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-center mt-2"} style={{ height: "280px" }}>
                                                            <MiniMap zones={ville.carte.detail.zones} colorUser={general.themeUser.user_perso_couleur} />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Stats Carte", { ns: "villes" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <FontAwesomeIcon icon={faRuler} />
                                                                <span>{t("Dimensions :", { ns: "villes" })}</span>
                                                            </div>
                                                            <div>{ville.carte.detail.taille}x{ville.carte.detail.taille}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"exploration"} />
                                                                <span>{t("Zones explorées :", { ns: "villes" })}</span>
                                                            </div>
                                                            <div>{ville.carte.detail.nb_decouvert}/{ville.carte.detail.nb_total}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"Small_broken"} />
                                                                <span>{t("Zones épuisées :", { ns: "villes" })}</span>
                                                            </div>
                                                            <div>{ville.carte.detail.nb_epuise}/{ville.carte.detail.nb_total}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"eventpart"} />
                                                                <span>{t("Citoyens dehors :", { ns: "villes" })}</span>
                                                            </div>
                                                            <div>{ville.carte.detail.nb_citoyen}/{ville.citoyens.filter((citoyen) => !citoyen.mort).length}</div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Ruine", { ns: "villes" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        {ville.carte.ruines.map((ruine, index) => {
                                                            return (
                                                                <div key={index} className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                                    <div className={"d-flex gap-1 align-items-center"}>
                                                                        <SvgIcone icone={"ruin"} />
                                                                        <span>{t(ruine.bat.nom, { ns: "bats" })}</span>
                                                                    </div>
                                                                    <div>{ruine.x_rel}/{ruine.y_rel}</div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Bâtiments", { ns: "villes" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        {ville.carte.batiments.sort((bat_a, bat_b) => {
                                                            const bat_a_trad = t(bat_a.bat.nom, { ns: "bats" });
                                                            const bat_b_trad = t(bat_b.bat.nom, { ns: "bats" });
                                                            
                                                            return bat_a_trad.localeCompare(bat_b_trad);
                                                        }).map((ruine, index) => {
                                                            return (
                                                                <div key={index} className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                                    <div className={"d-flex gap-1 align-items-center"}>
                                                                        {ruine.dig !== 0 &&
                                                                            <span className={"d-flex gap-1 align-items-center"}>
                                                            <span>{ruine.dig}</span>
                                                            <SvgIcone icone={"buried"} />
                                                        </span>}
                                                                        {ruine.dig === 0 && ruine.empty &&
                                                                            <TooltipGH>
                                                                                <SvgIcone icone={"home_recycled"} />
                                                                                <span>{t("Bâtiment vide", { ns: "villes" })}</span>
                                                                            </TooltipGH>}
                                                                        {ruine.dig === 0 && !ruine.empty && <SvgIcone icone={"home"} />}
                                                                        <span>{ruine.bat.nom}</span>
                                                                        {ruine.camped && <TooltipGH>
                                                                            <SvgIcone icone={"camp"} />
                                                                            <span>{t("Campé", { ns: "villes" })}</span>
                                                                        </TooltipGH>}
                                                                    </div>
                                                                    <div>{ruine.x_rel}/{ruine.y_rel}</div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </CollapsibleFieldset>
                                            <CollapsibleFieldset title={t("Journal", { ns: "villes" })} className={"ps-1 pe-1"} collapse={collapseGazette} actionExterne={(collapse) => setCollapseGazette(collapse)}>
                                                <div ref={el => gazetteRefs.current[index] = el}
                                                     style={{ minHeight: collapseGazette ? undefined : heights.gazette }}
                                                     className={"d-flex gap-2 flex-column"}>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Contenu de la gazette", { ns: "villes" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        <div>{HTMLParser(getContentLang({ journal: ville.gazette }))}</div>
                                                    </div>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Détail", { ns: "villes" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"def"} />
                                                                <span>{t("Défense :", { ns: "villes" })}</span>
                                                            </div>
                                                            <div>{ville.gazette.def}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"zombie"} />
                                                                <span>{t("Zombie :", { ns: "villes" })}</span>
                                                            </div>
                                                            <div>{ville.gazette.zombie}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"water"} />
                                                                <span>{t("Eau :", { ns: "villes" })}</span>
                                                            </div>
                                                            <div>{ville.gazette.water}</div>
                                                        </div>
                                                        {ville.resume.ville.jour > 1 && <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_gather"} />
                                                                <span>{t("Direction régen :", { ns: "villes" })}</span>
                                                            </div>
                                                            <div className={"d-flex gap-1 align-items-center"}><ScrutateurLib journal={ville.gazette} size={20} /></div>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </CollapsibleFieldset>
                                            <CollapsibleFieldset title={t("Banques", { ns: "villes" })} className={"ps-1 pe-1"} collapse={collapseBanques} actionExterne={(collapse) => setCollapseBanques(collapse)}>
                                                <div ref={el => banquesRefs.current[index] = el}
                                                     style={{ minHeight: collapseBanques ? undefined : heights.banques }}
                                                     className={"d-flex gap-2 flex-column"}>
                                                    {options.categories.map((category, index) => {
                                                        const itemCategories = Object.values(ville.banques).filter(banque => banque.item.category_objet.id === category.id);
                                                        return (
                                                            <div key={index}>
                                                                <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                                    <div className={"d-flex gap-1 align-items-center"}>
                                                                        <SvgIcone icone={"small_hero"} />
                                                                        <span>{t(category.nom, { ns: "items" })}</span>
                                                                        <span>({itemCategories.length})</span>
                                                                        <SvgIcone icone={"small_hero"} />
                                                                    </div>
                                                                </div>
                                                                <div className={"d-flex flex-wrap gap-1"}>
                                                                    {itemCategories.sort((item_a, item_b) => {
                                                                        if (item_a.nombre !== item_b.nombre) {
                                                                            return item_b.nombre - item_a.nombre;
                                                                        }
                                                                        
                                                                        const item_a_trad = t(item_a.item.nom, { ns: "items" });
                                                                        const item_b_trad = t(item_b.item.nom, { ns: "items" });
                                                                        return item_a_trad.localeCompare(item_b_trad);
                                                                        
                                                                    }).map((banque, index) => {
                                                                        return (
                                                                            <AffNbrIconBank key={index} item={banque.item} nombre={banque.nombre} broked={banque.broked} />
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </CollapsibleFieldset>
                                            <CollapsibleFieldset title={t("Chantiers", { ns: "villes" })} className={"ps-1 pe-1"} collapse={collapseChantiers} actionExterne={(collapse) => setCollapseChantiers(collapse)}>
                                                <div ref={el => chantiersRefs.current[index] = el}
                                                     style={{ minHeight: collapseChantiers ? undefined : heights.chantiers }}
                                                     className={"d-flex gap-2 flex-column"}>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Les chantiers", { ns: "chantiers" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        {options.chantiers.map((chantier, index) => {
                                                            return <div className="w-100 d-flex gap-1 align-items-center flex-column justify-content-start arbre_chantier_comparaison" id={"id_" + chantier.id} key={"id_" + chantier.id}>
                                                                <LigneChantierComparaison chantier={chantier} chantierVille={ville.chantiers} plans={ville.plans_chantiers} key={index} build={(builded) => getColorText(builded)} />
                                                            </div>;
                                                        })}
                                                    </div>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Les plans", { ns: "chantiers" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_bplan_c"} />
                                                                <span>{t("Plans commun :", { ns: "villes" })}</span>
                                                            </div>
                                                            <div>{ville.plans_chantiers.filter((plan) => plan.chantier.plan === 1).length}/{options.plans.filter((chantier) => chantier.plan === 1).length}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_bplan_u"} />
                                                                <span>{t("Plans inhab :", { ns: "villes" })}</span>
                                                            </div>
                                                            <div>{ville.plans_chantiers.filter((plan) => plan.chantier.plan === 2).length}/{options.plans.filter((chantier) => chantier.plan === 2).length}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_bplan_r"} />
                                                                <span>{t("Plans rare :", { ns: "villes" })}</span>
                                                            </div>
                                                            <div>{ville.plans_chantiers.filter((plan) => plan.chantier.plan === 3).length}/{options.plans.filter((chantier) => chantier.plan === 3).length}</div>
                                                        </div>
                                                        <div className={"d-flex gap-1 align-items-center justify-content-between ms-3"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"item_bplan_e"} />
                                                                <span>{t("Plans très rare :", { ns: "villes" })}</span>
                                                            </div>
                                                            <div>{ville.plans_chantiers.filter((plan) => plan.chantier.plan === 4).length}/{options.plans.filter((chantier) => chantier.plan === 4).length}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CollapsibleFieldset>
                                            <CollapsibleFieldset title={t("Evolutions", { ns: "villes" })} className={"ps-1 pe-1"} collapse={collapseUpChantiers} actionExterne={(collapse) => setCollapseUpChantiers(collapse)}>
                                                <div ref={el => upChantiersRefs.current[index] = el}
                                                     style={{ minHeight: collapseUpChantiers ? undefined : heights.upChantiers }}
                                                     className={"d-flex gap-2 flex-column"}>
                                                    <div>
                                                        <div className={"d-flex gap-1 align-items-center text-decoration-underline"}>
                                                            <div className={"d-flex gap-1 align-items-center"}>
                                                                <SvgIcone icone={"small_hero"} />
                                                                <span>{t("Les évolutions", { ns: "chantiers" })}</span>
                                                                <SvgIcone icone={"small_hero"} />
                                                            </div>
                                                        </div>
                                                        {Object.values(options.upChantiers).sort((chantier_a, chantier_b) => {
                                                            const chantier_a_trad = t(chantier_a.nom, { ns: "chantiers" });
                                                            const chantier_b_trad = t(chantier_b.nom, { ns: "chantiers" });
                                                            return chantier_a_trad.localeCompare(chantier_b_trad);
                                                        }).map((chantier, index) => {
                                                            const upChantier = ville.up_chantiers.find((up) => up.chantier.id === chantier.id);
                                                            const upChantierLevel = upChantier?.lvl_actuel ?? 0;
                                                            const construitMax = upChantierLevel === chantier.level_max;
                                                            return <div key={index} className={`d-flex gap-1 align-items-center justify-content-between ms-3 ${construitMax ? "color-green" : ""}`}>
                                                                <div className={"d-flex gap-1 align-items-center"}>
                                                                    <SvgIcone icone={chantier.icon} />
                                                                    <span>{t(chantier.nom, { ns: "chantiers" })}</span>
                                                                    {upChantierLevel > 0 && <TooltipGH>
                                                                        <FontAwesomeIcon icon={faCircleInfo} />
                                                                        <span className={"d-flex gap-1 align-items-center"}>
                                                        <span>{t("Jours de vote :", { ns: "ville" })}</span>
                                                        <span>{upChantier.days.map((jour) => jour.join(", ")).join(" ; ")}</span>
                                                    </span>
                                                                    </TooltipGH>}
                                                                </div>
                                                                <div>{upChantierLevel}/{chantier.level_max}</div>
                                                            </div>;
                                                        })}
                                                    </div>
                                                </div>
                                            </CollapsibleFieldset>
                                            <CollapsibleFieldset title={t("Statistiques", { ns: "villes" })} className={"ps-1 pe-1"} collapse={collapseStats} actionExterne={(collapse) => setCollapseStats(collapse)}>
                                                <div ref={el => statsRefs.current[index] = el}
                                                     style={{ minHeight: collapseStats ? undefined : heights.stats }}
                                                     className={"d-flex gap-2 flex-column"}>
                                                    <table>
                                                        <thead className={"background-tertiary border-solid-1"}>
                                                        <tr className={"border-solid-1"}>
                                                            <th className={"border-solid-1"}>{t("Jour", { ns: "villes" })}</th>
                                                            <th className={"border-solid-1"}>{t("Min", { ns: "villes" })}</th>
                                                            <th className={"border-solid-1"}>{t("Max", { ns: "villes" })}</th>
                                                            <th className={"border-solid-1"}>{t("Def", { ns: "villes" })}</th>
                                                            <th className={"border-solid-1"}>{t("Attaque", { ns: "villes" })}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody className={"border-solid-1"}>
                                                        {Object.values(ville.stats.day).map((jour, index) => {
                                                            if (jour > ville.resume.ville.jour) {
                                                                return null;
                                                            }
                                                            return (
                                                                <tr key={index} className={`border-solid-1 ${index % 2 === 0 ? "background-secondary" : "background-primary"}`}>
                                                                    <td className={"border-solid-1"} style={{ textAlign: "center" }}>{jour}</td>
                                                                    <td className={"border-solid-1"} style={{ textAlign: "center" }}>{ville.stats.minEstim[jour] ?? t("NC", { ns: "villes" })}</td>
                                                                    <td className={"border-solid-1"} style={{ textAlign: "center" }}>{ville.stats.maxEstim[jour] ?? t("NC", { ns: "villes" })}</td>
                                                                    <td className={"border-solid-1"} style={{ textAlign: "center" }}>{ville.stats.def[jour] ?? t("NC", { ns: "villes" })}</td>
                                                                    <td className={"border-solid-1"} style={{ textAlign: "center" }}>{ville.stats.attaqueJour[jour] ?? t("NC", { ns: "villes" })}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </CollapsibleFieldset>
                                        </div>
                                    )}
                                </Draggable>;
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}

export function LigneCitoyenComparaison({ citoyen, x, etatChaos, y }: {
    citoyen: CitoyensDTO;
    x: number;
    y: number;
    etatChaos: boolean;
}) {
    const { t } = useTranslation();
    return (
        <div className="fondBlack02 w-100 d-flex gap-1 align-items-center" id={"id_" + citoyen.citoyen.id} key={"id_" + citoyen.citoyen.id}>
            <div className="avatarCitoyenHV">
                <Avatar url={citoyen.citoyen.avatar} />
            </div>
            <div className="zonePseudoMessageCitoyenHV">
                <div className="pseudoMetierCitoyenHV">
                    <span className={`pseudoCitoyenHV`}>
                        <a href={"/ame/" + citoyen.citoyen.id_my_hordes}>
                            <span className={"d-flex gap-1 align-items-center"}>
                                <span className={`${citoyen.ban && !citoyen.mort ? "color-red" : ""}`}>{citoyen.citoyen.pseudo}</span>
                                {citoyen.ban && !citoyen.mort && <SvgIcone icone={"h_ban"} />}
                            </span>
                        </a>
                    </span>
                    {!citoyen.mort && (<span><SvgIcone icone={"h_" + citoyen.job.icon} /></span>)}
                    {citoyen.message !== null && (<TooltipGH>
                        <span className="infoBulle">
                            <span><SvgIcone icone={"small_chat"} /></span>
                        </span>
                        <span className="info infoBulleMessage"><span>{citoyen.message}</span></span>
                    </TooltipGH>)}
                </div>
                {!citoyen.mort && (<div className="positionCitoyenHV"><span><LibCoordonneCitoyenComparaison citoyen={citoyen} x={x} y={y} etatChaos={etatChaos} /></span></div>)}
                {!citoyen.mort && (<div className="niveauHab">
                    {citoyen.lvl_maison && <span className={"d-flex gap-1 align-items-center fs-12-gh"}>
                         <SvgIcone icone={"home_" + citoyen.lvl_maison.icon} />
                        <span className="info">{citoyen.lvl_maison.nom}</span>
                    </span>}
                </div>)}
                {citoyen.mort && (<div className="mortCitoyenHV">{citoyen.job !== null && (<span><SvgIcone icone={"h_" + citoyen.job.icon} /></span>)}
                    <span className="jourMort">{t("Jour", { ns: "hotel" })} {citoyen.death_day}</span>
                    {citoyen.death_type !== null && (<TooltipGH>
                        <span className="infoBulle cause_mort_citoyen">
                            <SvgIcone icone={"h_death"} />
                        </span>
                        <span className="info">{t("Cause :", { ns: "hotel" })} {citoyen.death_type.label}{citoyen.clean_up_type !== null && (
                            <React.Fragment>
                                <br />
                                <span>{citoyen.clean_up_type.label} {t("par", { ns: "hotel" })}{" "}<span className="nom_cleaneur_citoyen">{citoyen.clean_up_name === null ? t("inconnu", { ns: "hotel" }) : citoyen.clean_up_name.pseudo}</span></span>
                            </React.Fragment>)}
                        </span>
                    </TooltipGH>)}
                </div>)}
            </div>
        </div>
    );
}

export function LibCoordonneCitoyenComparaison({ citoyen, x, y, etatChaos }: { citoyen: CitoyensDTO, x: number, y: number, etatChaos: boolean }) {
    const { t } = useTranslation();
    
    let x_rel: number | null = null;
    let y_rel: number | null = null;
    if (citoyen.x !== null && citoyen.y !== null) {
        x_rel = citoyen.x - x;
        y_rel = y - citoyen.y;
    }
    
    const conditions = [
        { check: etatChaos || x_rel === null || y_rel === null, result: "(??/??)", icon: "r_explor" },
        { check: !citoyen.dehors, result: t("Aux portes", { ns: "ville" }), icon: "h_home" },
        { check: citoyen.dehors && x_rel === 0 && y_rel === 0, result: t("En ville", { ns: "ville" }), icon: "h_home" },
        { check: true, result: `${x_rel}/${y_rel}`, icon: "r_explor" },
    ];
    
    for (const condition of conditions) {
        if (condition.check) {
            return (<div className={"d-flex gap-1 align-items-center fs-12-gh"}>
                    <SvgIcone icone={condition.icon} />
                    <span className="info">{condition.result}</span>
                </div>
            );
        }
    }
    
    
}

export function LigneChantierComparaison({ chantier, chantierVille, plans, niveau = 0, build }: { chantier: ChantierPrototypeDTO, chantierVille: ChantiersDTO[], plans: PlansChantierDTO[], niveau?: number, build: (builded: boolean) => string }) {
    const { t } = useTranslation();
    const chantierFiltre = chantierVille.filter((chant) => chant.chantier.id === chantier.id);
    const chantierConstruit = chantierFiltre.length > 0;
    return (<React.Fragment>
            <div className={"w-100 d-flex gap-1 justify-content-between"} style={{ color: build(chantierConstruit) }}>
                <div className={"d-flex gap-1 chantier_nom " + ((niveau > 0) ? ("lv-" + Math.min(niveau, 6)) : "root")}>
                    <SvgIcone icone={chantier.icon} />
                    <span>{t(chantier.nom, { ns: "chantiers" })}</span>
                    {chantier.temp && <SvgIcone icone={"warning"} style={{ opacity: chantierConstruit ? 1 : 0.3 }} />}
                    {chantier.plan > 0 && <SvgIcone icone={getIconePlan(chantier)} style={{ opacity: planTrouve(chantier, plans) ? 1 : 0.3 }} />}
                </div>
                {chantierConstruit && <div>{chantierFiltre[0].jour_construction.map((jour) => `${t("J", { ns: "villes" })}${jour}`).join(",")}</div>}
            </div>
            {chantier.children.length > 0 && chantier.children.sort((ch_a, ch_b) => ch_a.order_by_listing < ch_b.order_by_listing ? -1 : 1).map((child, index) => {
                return (
                    <LigneChantierComparaison chantier={child} chantierVille={chantierVille} plans={plans} niveau={niveau + 1} key={index} build={(builded) => build(builded)} />
                );
            })}
        </React.Fragment>
    );
}

export function MiniMap({ zones, colorUser }: { zones: ZoneMapDTO[][], colorUser: UserPersoCouleurDTO }) {
    const colorBlack = chroma("black");
// Utilisation d'un objet de mapping pour les couleurs de danger
    const dangerColorMap = useMemo(() => ({
        0: colorUser.couleur_danger0,
        1: colorUser.couleur_danger1,
        2: colorUser.couleur_danger2,
        3: colorUser.couleur_danger3,
        4: colorUser.couleur_danger4,
    }), [colorUser.couleur_danger0, colorUser.couleur_danger1, colorUser.couleur_danger2, colorUser.couleur_danger3, colorUser.couleur_danger4]);
    
    // Fonction optimisée pour obtenir la couleur de la zone
    const getColorZone = React.useCallback((zone: ZoneMapDTO): string => {
        if (zone.vue === -2) {
            return colorUser.color_town;
        }
        if (zone.vue === -1) {
            return colorUser.color_non_vu;
        }
        if (zone.vue === 1) {
            return dangerColorMap[zone.danger] || "transparent";
        }
        return "transparent";
    }, [dangerColorMap, colorUser.color_town, colorUser.color_non_vu]);
    
    return (
        <div className={"border-solid-1"}>
            {zones.map((ligne, rowIndex) => (
                <div key={`row-${rowIndex}`} className={"d-flex"}>
                    {ligne.map((zone, colIndex) => (
                        <div key={`cell-${rowIndex}-${colIndex}`} style={{ width: "8px", height: "8px", backgroundColor: getColorZone(zone), border: `1px solid ${colorBlack.alpha(0.3).css()}` }} className={"d-flex justify-content-center align-items-center"}>
                            {zone.bat !== null && <div style={{ width: "4px", height: "4px", backgroundColor: colorUser.couleur_bat }}></div>}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
