import React, { useEffect, useState } from "react";
import { VillesType }                 from "../../types/components/Villes/VillesType";
import Badge                          from "react-bootstrap/Badge";
import SvgIcone                       from "../../components/generality/SvgIcone";
import { VilleApi }                   from "../../services/api/VilleApi";
import Spinner                        from "react-bootstrap/Spinner";
import { Link, useNavigate }          from "react-router-dom";
import TriangleSort                   from "../../components/generality/ComposantGeneral/TriangleSort";
import { VilleDTO }                   from "../../types/models/ville.dto";
import { useTranslation }             from "react-i18next";
import { faLanguage }                 from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon }            from "@fortawesome/react-fontawesome";
import SvgDrapeau                     from "../../components/generality/SvgDrapeau";
import TooltipGH                      from "../../components/utils/TooltipGH";
import { useSelector }                from "react-redux";
import { RootState }                  from "../../store/store";
import ColumnFilterManager            from "./ColumnFilterManager";
import Button                         from "../../components/utils/Button";

interface VillesProps {
    villes: VillesType;
}

const communauties = ["de", "en", "es", "fr", "multi"];

export default function Villes(props: VillesProps) {
    const villes = props.villes;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [top30, setTop30] = useState(villes.top30);
    const [villesSearch, setVillesSearch] = useState([]);
    const [listVilles, setListVilles] = useState(villes.listVilles);
    const [affTop30, setAffTop30] = useState(true);
    const [jourAff, setJourAff] = useState(0);
    const [chargement, setChargement] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);
    const [inSearch, setInSearch] = useState(false);
    const [form, setForm] = useState({ nom: "", type: null, etat: null, saison: -1, lang: null });
    const [formErreur, setFormErreur] = useState({ general: null, type: null, etat: null, saison: null, lang: null });
    const [sortedOrderColumn, setSortedOrderColumn] = useState(localStorage.getItem("ville_sortedOrderColumn") || "date");
    const [sortOrder, setSortOrder] = useState(localStorage.getItem("ville_sortOrder") || "asc");
    const colonnesMasquees = useSelector((state: RootState) => state.gestionVilles.colonneMasquer);
    const [openFiltre, setOpenFiltre] = useState(true);
    const [openColonne, setOpenColonne] = useState(false);
    const [openComparaison, setOpenComparaison] = useState(false);
    const [listVillesComp, setListVillesComp] = useState<number[]>([]);
    const [showComparatif, setShowComparatif] = useState(false);
    const [lienComparatif, setLienComparatif] = useState<string | null>(null);
    
    useEffect(() => {
        setTop30(villes.top30);
        setListVilles(villes.listVilles);
    }, [props.villes]);
    
    useEffect(() => {
        // Sauvegarde de l'état de tri en local storage
        localStorage.setItem("ville_sortedOrderColumn", sortedOrderColumn);
        localStorage.setItem("ville_sortOrder", sortOrder);
    }, [sortedOrderColumn, sortOrder]);
    
    useEffect(() => {
        // On va récupérer les map_ids des villes de chaque event pour avoir un lien de comparaison
        const mapIds = [];
        listVillesComp.map((ville) => {
            mapIds.push(ville);
        });
        
        if (mapIds.length > 0) {
            setShowComparatif(true);
            setLienComparatif(`/villes/comparatif?cities=${mapIds.join(",")}`);
        }
    }, [listVillesComp]);
    
    const villesApi = new VilleApi(0);
    
    const handleAllVilles = () => {
        setChargement(true);
        setIsLoaded(false);
        setFormErreur({ general: null, type: null, etat: null, saison: null, lang: null });
        
        villesApi.all().then((resultat) => {
            setListVilles(resultat.data.listVilles);
            setIsLoaded(true);
            setChargement(false);
        });
    };
    
    const handleReinit = () => {
        setForm({ nom: null, type: null, etat: null, saison: -1, lang: null });
        setFormErreur({ general: null, type: null, etat: null, saison: null, lang: null });
        setListVilles(villes.listVilles);
        setIsLoaded(true);
        setChargement(false);
        setInSearch(false);
    };
    
    const handleCheckboxChange = (checkboxName: "type" | "etat" | "lang", checkboxValue: string) => {
        setForm((currentForm) => {
            const updatedCheckboxValues = currentForm[checkboxName]?.includes(checkboxValue)
                ? currentForm[checkboxName].filter((value: string) => value !== checkboxValue)
                : [...(currentForm[checkboxName] || []), checkboxValue];
            
            return { ...currentForm, [checkboxName]: updatedCheckboxValues };
        });
    };
    
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newName = event.target.value;
        setForm(prevForm => ({
            ...prevForm,
            nom: newName,
        }));
    };
    
    const handleFiltre = () => {
        setChargement(true);
        setIsLoaded(false);
        setFormErreur({ general: null, type: null, etat: null, saison: null, lang: null });
        
        villesApi.search({ nom: form.nom, etat: form.etat, type: form.type, saison: form.saison, lang: form.lang }).then(response => {
            setVillesSearch(response.data.listVilles);
            setIsLoaded(true);
            setChargement(false);
            setInSearch(true);
        }).catch(error => {
            setVillesSearch([]);
            setIsLoaded(true);
            setChargement(true);
            setInSearch(false);
            setFormErreur({
                general: error.data.errors.form || null,
                type   : error.data.errors.type || null,
                etat   : error.data.errors.etat || null,
                saison : error.data.errors.saison || null,
                lang   : error.data.errors.lang || null,
            });
        });
    };
    
    const handleRedirect = (mapId: number) => {
        villesApi.mapId(mapId).then(() => {
            navigate("/carte/" + mapId);
        });
    };
    
    // Cette fonction remplace la méthode handleSortDataObjets du composant de classe
    const handleSortData = (column: string) => {
        // Déterminer le nouvel ordre
        let newOrder = "asc";
        if (column === sortedOrderColumn) {
            if (sortOrder === "asc") {
                newOrder = "desc";
            } else if (sortOrder === "desc") {
                newOrder = "default";
            }
        }
        
        // Si on doit réinitialiser la colonne
        const newSortedColumn = newOrder === "default" ? "" : column;
        
        // Mettre à jour les états
        setSortedOrderColumn(newSortedColumn);
        setSortOrder(newOrder);
    };
    
    const getSortIndicatorClass = (column: string) => {
        if (column === sortedOrderColumn) {
            return sortOrder === "asc" ? "asc" : "desc";
        } else {
            return "default";
        }
    };
    
    const dayOfTown = Object.keys(listVilles);
    const listingVilles: VilleDTO[] = (inSearch) ? villesSearch : ((affTop30) ? top30 : listVilles[jourAff]);
    
    const sortedListingVilles = listingVilles.sort((a: VilleDTO, b: VilleDTO) => {
        
        if (sortedOrderColumn === "jour" && (affTop30 || inSearch)) {
            
            // Si les dates sont égales et qu'affTop30 est vrai, comparer par jour
            if (a.jour !== b.jour) {
                
                if (sortOrder === "asc") {
                    return a.jour < b.jour ? 1 : -1;
                }
                return a.jour > b.jour ? 1 : -1;
            }
            
            // Si les jours sont égaux, on compare par nombre de points
            if (a.nombre_point_saison !== b.nombre_point_saison) {
                return a.nombre_point_saison < b.nombre_point_saison ? 1 : -1;
            }
            
            // En dernier recours, comparer par map_id
            return a.map_id < b.map_id ? 1 : -1;
            
        }
        if (sortedOrderColumn === "pts") {
            
            // Si les dates sont égales et qu'affTop30 est vrai, comparer par jour
            if (a.nombre_point_saison !== b.nombre_point_saison) {
                
                if (sortOrder === "asc") {
                    return a.nombre_point_saison < b.nombre_point_saison ? 1 : -1;
                }
                return a.nombre_point_saison > b.nombre_point_saison ? 1 : -1;
            }
            
            // Si les points de saison sont égaux sont égales et qu'affTop30 est vrai, comparer par jour
            if ((affTop30) && a.jour !== b.jour) {
                return a.jour < b.jour ? 1 : -1;
            }
            
            // En dernier recours, comparer par map_id
            return a.map_id < b.map_id ? 1 : -1;
            
        }
        if (sortedOrderColumn === "etat") {
            // Calcul de l'état de la ville
            const etatA = (a.devast) ? 2 : ((a.chaos) ? 1 : 0);
            const etatB = (b.devast) ? 2 : ((b.chaos) ? 1 : 0);
            // Si les dates sont égales et qu'affTop30 est vrai, comparer par jour
            if (etatA !== etatB) {
                
                if (sortOrder === "asc") {
                    return etatA > etatB ? 1 : -1;
                }
                return etatA < etatB ? 1 : -1;
            }
            
            // Si l'état est égale et qu'affTop30 est vrai, comparer par jour
            if ((affTop30) && a.jour !== b.jour) {
                return a.jour < b.jour ? 1 : -1;
            }
            
            // En dernier recours, comparer par map_id
            return a.map_id < b.map_id ? 1 : -1;
            
        }
        if (sortedOrderColumn === "cit") {
            if (a.nb_vivants !== b.nb_vivants) {
                if (sortOrder === "asc") {
                    return a.nb_vivants < b.nb_vivants ? 1 : -1;
                } else {
                    return a.nb_vivants > b.nb_vivants ? 1 : -1;
                }
            }
            if (a.nombre_point_saison !== b.nombre_point_saison) {
                return a.nombre_point_saison < b.nombre_point_saison ? 1 : -1;
            }
            return a.map_id < b.map_id ? 1 : -1;
        }
        if (sortedOrderColumn === "date" || sortedOrderColumn === "") {
            const aDate = new Date(Date.parse(a.date_time)).setHours(0, 0, 0, 0);
            const bDate = new Date(Date.parse(b.date_time)).setHours(0, 0, 0, 0);
            
            // Comparaison de dates en premier
            if (aDate !== bDate) {
                if (sortOrder === "asc") {
                    return aDate < bDate ? 1 : -1;
                }
                return aDate > bDate ? 1 : -1;
            }
            
            // Si les dates sont égales et qu'affTop30 est vrai, comparer par jour
            if ((affTop30) && a.jour !== b.jour) {
                return a.jour < b.jour ? 1 : -1;
            }
            
            // Si les jours sont égaux ou affTop30 est faux, comparer par nombre de points
            if (a.nombre_point_saison !== b.nombre_point_saison) {
                return a.nombre_point_saison < b.nombre_point_saison ? 1 : -1;
            }
            
            // En dernier recours, comparer par map_id
            return a.map_id < b.map_id ? 1 : -1;
            
        }
        
    });
    
    const isColumnVisible = (columnId: string) => !colonnesMasquees.includes(columnId);
    
    const handleChangeComparaison = (mapId: number) => {
        // On controle si la ville n'est pas déjà dans la liste
        if (listVillesComp.includes(mapId)) {
            setListVillesComp(listVillesComp.filter((id) => id !== mapId));
        } else {
            // On verifie si on a pas déjà le max des villes dans la liste
            if (villes.maxVilles >= listVillesComp.length) {
                setListVillesComp([...listVillesComp, mapId]);
            }
        }
    };
    
    return <React.Fragment>
        <div id="formRechercheVilles" className={"mb-2"}>
            <div className={"d-flex gap-5 justify-content-center align-items-center p-2"}>
                <Button couleur={"primary"} taille={"xs"} onClick={() => setOpenFiltre(!openFiltre)}>{openFiltre ? t("Masquer la recherche de villes", { ns: "villes" }) : t("Afficher la recherche de villes", { ns: "villes" })}</Button>
                <Button couleur={"success"} taille={"xs"} onClick={() => setOpenColonne(!openColonne)}>{openColonne ? t("Masquer gestion colonnes", { ns: "villes" }) : t("Afficher gestion colonnes", { ns: "villes" })}</Button>
                <Button couleur={"warning"} taille={"xs"} onClick={() => setOpenComparaison(!openComparaison)}>{openComparaison ? t("Masquer le menu de comparaison", { ns: "villes" }) : t("Afficher le menu de comparaison", { ns: "villes" })}</Button>
            </div>
            {openFiltre && <div id="inputRecherche" className="fondWhite02">
                <fieldset className={"d-flex gap-1 justify-content-start align-items-start w-100"}>
                    <legend>{t("Recherche de villes", { ns: "villes" })}</legend>
                    <div id="nomVille">
                        <div className={"mb-1"}>
                            <label htmlFor={"nom_ville"}>{t("Nom de la ville :", { ns: "villes" })}</label>
                            <input type={"text"} id={"nom_ville"} value={form.nom} onChange={handleNameChange} placeholder={t("Nom...", { ns: "villes" })} />
                        </div>
                        <div id="boutonVilles">
                            <Button couleur={"primary"} taille={"xs"} onClick={handleFiltre}><span>{t("Filtrer", { ns: "villes" })}</span></Button>
                            <Button couleur={"warning"} taille={"xs"} onClick={handleAllVilles}><span>{t("Tout afficher", { ns: "villes" })}</span></Button>
                            <Button couleur={"danger"} taille={"xs"} onClick={() => handleReinit()}><span>{t("Reinitialiser", { ns: "villes" })}</span></Button>
                        </div>
                        {formErreur.general !== null && <div className={"erreur-form"}>{formErreur.general}</div>}
                    </div>
                    <div id="checkboxVille">
                        <div id="checkBoxType">
                            <div>
                                <label className="required">{t("Type de ville :", { ns: "villes" })}</label>
                                <div id="form_type">
                                    <input type={"checkbox"} id="form_type_0" checked={form.type?.includes("RNE") || false} onChange={() => handleCheckboxChange("type", "RNE")} />
                                    <label htmlFor="form_type_0">{t("Région non-éloignée", { ns: "villes" })}</label>
                                    <input type={"checkbox"} id="form_type_1" checked={form.type?.includes("RE") || false} onChange={() => handleCheckboxChange("type", "RE")} />
                                    <label htmlFor="form_type_1">{t("Région éloignée", { ns: "villes" })}</label>
                                    <input type={"checkbox"} id="form_type_2" checked={form.type?.includes("Pandé") || false} onChange={() => handleCheckboxChange("type", "Pandé")} />
                                    <label htmlFor="form_type_2">{t("Pandémonium", { ns: "villes" })}</label>
                                </div>
                            </div>
                            {formErreur.type !== null && <div className={"erreur-form"}>{formErreur.type}</div>}
                        </div>
                        <div id="checkBoxStatut">
                            <div>
                                <label className="required">{t("Statut de la ville :", { ns: "villes" })}</label>
                                <div id="form_etat">
                                    <input type={"checkbox"} id="form_etat_0" checked={form.etat?.includes("Normal") || false} onChange={() => handleCheckboxChange("etat", "Normal")} />
                                    <label htmlFor="form_etat_0">{t("Normal", { ns: "villes" })}</label>
                                    <input type={"checkbox"} id="form_etat_1" checked={form.etat?.includes("Chaos") || false} onChange={() => handleCheckboxChange("etat", "Chaos")} />
                                    <label htmlFor="form_etat_1">{t("Chaos", { ns: "villes" })}</label>
                                    <input type={"checkbox"} id="form_etat_2" checked={form.etat?.includes("Dévasté") || false} onChange={() => handleCheckboxChange("etat", "Dévasté")} />
                                    <label htmlFor="form_etat_2">{t("Dévasté", { ns: "villes" })}</label>
                                </div>
                            </div>
                            {formErreur.etat !== null && <div className={"erreur-form"}>{formErreur.etat}</div>}
                        </div>
                        <div id="checkBoxCommu">
                            <div className={"d-flex justify-content-start align-items-center align-self-center gap-2"}>
                                <label className="required">{t("Communauté de la ville :", { ns: "villes" })}</label>
                                <div id="form_lang" className={"d-flex justify-content-start align-items-center align-self-center gap-2"}>
                                    {communauties.map((communaute) => {
                                        return <div key={"communaute_" + communaute} className={"d-flex justify-content-start align-items-center align-self-center gap-0"}>
                                            <input type={"checkbox"} id={"form_lang_" + communaute} checked={form.lang?.includes(communaute) || false} onChange={() => handleCheckboxChange("lang", communaute)} />
                                            <label htmlFor={"form_lang_" + communaute}>
                                                <div className={"d-flex justify-content-center align-items-center align-self-center"} style={{ height: "22px", width: "24px" }}>{<SvgDrapeau drapeau={communaute} />}</div>
                                            </label>
                                        </div>;
                                    })}
                                </div>
                            </div>
                            {formErreur.lang !== null && <div className={"erreur-form"}>{formErreur.lang}</div>}
                        </div>
                        <div id="checkBoxStatut">
                            <div>
                                <label className="required">{t("Saison de la ville :", { ns: "villes" })}</label>
                                <select value={form.saison} onChange={(event) => setForm(form => ({ ...form, saison: parseInt(event.target.value, 10) }))}>
                                    <option value={-1}>{t("Toutes saisons", { ns: "villes" })}</option>
                                    {Object.values(villes.listSaison).map((saison) => {
                                        return <option value={saison.type} key={"saison_" + saison.type}>{saison.nom}</option>;
                                    })}
                                </select>
                            </div>
                            {formErreur.etat !== null && <div className={"erreur-form"}>{formErreur.etat}</div>}
                        </div>
                    </div>
                </fieldset>
            </div>}
            {openColonne && <ColumnFilterManager />}
            {openComparaison && <div id="comparaisonVilles" className={"fondWhite02 mb-2"}>
                <fieldset className={"d-flex gap-1 justify-content-start align-items-start"}>
                    <legend>{t("Comparaison de villes", { ns: "villes" })}</legend>
                    <div id="comparaisonVilles">
                        <div className={"d-flex gap-1 justify-content-center align-items-start"}>
                            <div>{t("Liste des villes pour la comparaison :", { ns: "villes" })}</div>
                            <div>{listVillesComp.join(", ")}</div>
                        </div>
                        {listVillesComp.length === villes.maxVilles && <div className={"color-red"}>{t("Vous avez atteint le maximum de villes à comparer", { ns: "villes" })}</div>}
                        {showComparatif && <div id="boutonComparaison">
                            <Button couleur={"primary"} taille={"xs"}><Link to={lienComparatif} style={{ textDecoration: "none", color: "inherit" }}><span>{t("Comparer", { ns: "villes" })}</span></Link></Button>
                        </div>}
                    </div>
                </fieldset>
            </div>}
        </div>
        {isLoaded && !chargement && <div id="listVille">
            {!inSearch && <div id="listVilleOnglet">
                    <span className={"caseChoixVilles fondWhite02" + ((jourAff === 0) ? " villesSelected" : "")}
                          id="choix_top15"
                          onClick={() => {
                              setJourAff(0);
                              setAffTop30(true);
                          }}
                    >
                        <SvgIcone icone={"r_heroac"} />
                    </span>
                {dayOfTown.map(day => {
                    const jour = parseInt(day, 10);
                    return <span className={"caseChoixVilles fondWhite02" + ((jourAff === jour) ? " villesSelected" : "")}
                                 id={"choix_" + jour}
                                 key={"choix_" + jour}
                                 onClick={() => {
                                     setJourAff(jour);
                                     setAffTop30(false);
                                     setSortedOrderColumn("date");
                                 }}>
							<span className={"villes_jours"}>{"J" + jour}</span>
							<Badge bg="primary" pill>{Object.values(listVilles[jour]).length}</Badge>
						</span>;
                })}
            </div>}
            <div className={"tabVille villesVisible"}>
                <table className={"listVilles fondWhite02"}>
                    <thead>
                    <tr>
                        {isColumnVisible("villesVisu") && <th className="villesVisu">{t("Voir", { ns: "ville" })}</th>}
                        {isColumnVisible("villesNum") && <th className="villesNum">{t("N° ville", { ns: "ville" })}</th>}
                        {isColumnVisible("villesNom") && <th className="villesNom">{t("Nom de la ville", { ns: "ville" })}</th>}
                        {isColumnVisible("villesJour") && (affTop30 || inSearch) && <th className="villesJour">
                            <div className={"entete_tri"} onClick={() => handleSortData("jour")}>
                                <div>{t("Jour", { ns: "ville" })}</div>
                                <TriangleSort direction={getSortIndicatorClass("jour")} />
                            </div>
                        </th>}
                        {isColumnVisible("villesType") && <th className="villesType">{t("Type", { ns: "ville" })}</th>}
                        {isColumnVisible("villesEtat") && <th className="villesEtat">
                            <div className={"entete_tri"} onClick={() => handleSortData("etat")}>
                                <div>
                                    <span className="d-flex gap-1 align-items-center justify-content-center">{t("Etat", { ns: "ville" })}</span>
                                </div>
                                <TriangleSort direction={getSortIndicatorClass("etat")} />
                            </div>
                        </th>}
                        {isColumnVisible("villesLarg") && <th className="villesLarg">{t("Largeur", { ns: "ville" })}</th>}
                        {isColumnVisible("villesLang") && <th className="villesLang">
                            <TooltipGH>
                                <span className="infoBulle">
                                    <FontAwesomeIcon icon={faLanguage} />
                                </span>
                                <span className="info">{t("Communauté", { ns: "ville" })}</span>
                            </TooltipGH>
                        </th>}
                        {isColumnVisible("villesSaison") && <th className="villesSaison">
                            <TooltipGH>
                                <span className="infoBulle d-flex gap-1 align-items-center justify-content-center">
                                    <SvgIcone icone={"r_wintop"} />
                                </span>
                                <span className="info">{t("Saison", { ns: "ville" })}</span>
                            </TooltipGH>
                        </th>}
                        {isColumnVisible("villesPointSaison") && <th className="villesPointSaison">
                            <div className={"entete_tri"} onClick={() => handleSortData("pts")}>
                                <div>
                                    <TooltipGH>
                                        <span className="infoBulle">
                                            <SvgIcone icone={"item_soul_red"} />
                                        </span>
                                        <span className="info">{t("Points de saison", { ns: "ville" })}</span>
                                    </TooltipGH>
                                </div>
                                <TriangleSort direction={getSortIndicatorClass("pts")} />
                            </div>
                        </th>}
                        {isColumnVisible("villesCit") && <th className="villesCit">
                            <div className={"entete_tri"} onClick={() => handleSortData("cit")}>
                                <div>
                                    <TooltipGH>
                                        <span className="infoBulle">
                                            <SvgIcone icone={"h_human"} />
                                        </span>
                                        <span className="info">{t("Citoyens en vie", { ns: "ville" })}</span>
                                    </TooltipGH>
                                </div>
                                <TriangleSort direction={getSortIndicatorClass("cit")} />
                            </div>

                        </th>}
                        {isColumnVisible("villeHero") && <th className="villeHero">
                            <TooltipGH>
                                <span className="infoBulle d-flex gap-1 align-items-center justify-content-center">
                                    <SvgIcone icone={"r_heroac"} />
                                </span>
                                <span className="info">{t("Nombre de Héros", { ns: "ville" })}</span>
                            </TooltipGH>
                        </th>}
                        {isColumnVisible("villeDate") && <th className="villeDate">
                            <div className={"entete_tri"} onClick={() => handleSortData("date")}>
                                <div>{t("Dernière maj", { ns: "ville" })}</div>
                                <TriangleSort direction={getSortIndicatorClass("date")} />
                            </div>
                        </th>}
                        {openComparaison && <th className={"villesComp"}>
                            <span>{t("Comparer", { ns: "villes" })}</span>
                        </th>}
                    </tr>
                    </thead>
                    <tbody>
                    {Object.values(sortedListingVilles).map((ville, index) => {
                        return <LigneVille ville={ville} aff_detail={(affTop30 || inSearch)} onRedirect={handleRedirect} key={"ligne_ville_" + index} colonnesMasquees={colonnesMasquees} openComp={openComparaison} compChange={(mapId) => handleChangeComparaison(mapId)} listVillesComp={listVillesComp} />;
                    })}
                    </tbody>
                </table>
            </div>
        </div>}
        {!isLoaded && chargement && <div id="listChargement">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">{t("Chargement...", { ns: "app" })}</span>
            </Spinner>
            <p>{t("Veuillez patienter, récupération en cours...", { ns: "villes" })}</p>
        </div>}
    </React.Fragment>;
    
}

export function LigneVille(props: { ville: VilleDTO, aff_detail: boolean, onRedirect: (mapId: number) => void, colonnesMasquees: string[], openComp: boolean, compChange: (mapId: number) => void, listVillesComp: number[] }) {
    
    const { t } = useTranslation();
    const { ville, aff_detail, colonnesMasquees } = props;
    
    const typeLibelle = (ville.hard) ? t("Pandemonium", { ns: "app" }) : ((ville.height > 20) ? t("Région éloignée", { ns: "app" }) : t("Région non-éloignée", { ns: "app" }));
    const etatLibelle = (ville.devast) ? t("Ville dévastée", { ns: "app" }) : ((ville.chaos) ? t("Ville en chaos", { ns: "app" }) : t("Ville normale", { ns: "app" }));
    
    let date_maj_string: string;
    if (ville.date_time !== null) {
        const date_maj = new Date(Date.parse(ville.date_time));
        date_maj_string = date_maj.toLocaleDateString();
    }
    
    const isColumnVisible = (columnId: string) => !colonnesMasquees.includes(columnId);
    
    return <tr>
        {isColumnVisible("villesVisu") && <td className="villesVisu"><span className={"d-flex gap-1 align-items-center justify-content-center"}><Link to={"/carte/" + ville.map_id}><SvgIcone icone={"r_explo2"} /></Link></span></td>}
        {isColumnVisible("villesNum") && <td className="villesNum">{ville.map_id}</td>}
        {isColumnVisible("villesNom") && <td className="villesNom">{ville.nom}</td>}
        {isColumnVisible("villesJour") && aff_detail && <td className="villesJour">{ville.jour}</td>}
        {isColumnVisible("villesType") && <td className="villesType">
            <TooltipGH>
                <span className="infoBulle d-flex gap-1 align-items-center justify-content-center">
                    <SvgIcone icone={ville.type_icon} />
                </span>
                <span className="info">{typeLibelle}</span>
            </TooltipGH>
        </td>}
        {isColumnVisible("villesEtat") && <td className="villesEtat">
            <TooltipGH>
                <span className="infoBulle d-flex gap-1 align-items-center justify-content-center">
                    <SvgIcone icone={ville.devast_icon} />
                </span>
                <span className="info">{etatLibelle}</span>
            </TooltipGH>
        </td>
        }
        {isColumnVisible("villesLarg") && <td className="villesLarg">{ville.weight}</td>}
        {isColumnVisible("villesLang") && <td className="villesLang">
            <div className={"d-flex justify-content-center align-items-center align-self-center"}>{<SvgDrapeau drapeau={ville.lang} />}</div>
        </td>}
        {isColumnVisible("villesSaison") && <td className="villesSaison">{ville.saison}</td>}
        {isColumnVisible("villesPointSaison") && <td className="villesPointSaison">{ville.nombre_point_saison}</td>}
        {isColumnVisible("villesCit") && <td className="villesCit">{ville.nb_vivants}</td>}
        {isColumnVisible("villeHero") && <td className="villeHero">{ville.nb_heros}</td>}
        {isColumnVisible("villeDate") && <td className="villeDate">{date_maj_string}</td>}
        {props.openComp && <td className={"villesComp"} style={{ textAlign: "center" }}>
            <input type={"checkbox"} id={"comp_" + ville.map_id} checked={props.listVillesComp.includes(ville.map_id)} onChange={() => {
                console.log("check");
                props.compChange(ville.map_id);
            }} />
        </td>}
    </tr>;
}
