import * as React                                                                                                                                                                                             from "react";
import { ArrayInput, Create, Datagrid, DateTimeInput, Edit, EditButton, List, NumberField, NumberInput, ReferenceInput, SelectInput, SimpleForm, SimpleFormIterator, TextField, TextInput, useRecordContext } from "react-admin";
import { Status_error, Status_success, usePopUp }                                                                                                                                                             from "../../../types/Context/PopUpContext";
import { AdminApi }                                                                                                                                                                                           from "../../../services/api/AdminApi";
import { RichTextEditorInput }                                                                                                                                                                                from "../InputFieds/Inputs/RichTextEditorInput";

const type = [{ name: "FEATURE", type: 1 }, { name: "FIX", type: 2 }, { name: "OTHER", type: 3 }];

const MajJson = () => {
    const adminApi = new AdminApi();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const handleUpdate = () => {
        adminApi.maj_versions_json().then((response) => {
            setStatus(Status_success);
            setMessagePopUp(response.data.libRetour);
            setShowPop(true);
            setTimeout(() => {
                setShowPop(false);
                setMessagePopUp("");
            }, 1000);
        }).catch((error) => {
            setStatus(Status_error);
            setMessagePopUp(error?.data?.error ?? error?.message ?? "Erreur inconnue");
            setShowPop(true);
        });
    };
    return (
        <button className={"btn btn-primary"} onClick={handleUpdate}>
            Mettre à jour le fichier JSON
        </button>
    );
};

export const VersionManagerList = (props) => {
    const postFilters = [
        <TextInput source="q" label="Search" alwaysOn key={"search_versions"} />,
        <ReferenceInput source="id" label="Version" reference="version" key={"search_version_id"} />,
    ];
    
    return <>
        <MajJson />
        <List {...props} filters={postFilters}>
            <Datagrid>
                <NumberField source="id" />
                <TextField source="titre" />
                <TextField source="version_majeur" />
                <TextField source="version_mineur" />
                <TextField source="version_corrective" />
                <EditButton />
            </Datagrid>
        </List>
    
    </>;
};

export const VersionManagerEdit = (props) => {
    
    const PostTitle = () => {
        const record = useRecordContext();
        return <span>Edition de {record ? `"${record.titre}"` : ""}</span>;
    };
    
    return (<Edit {...props} title={<PostTitle />}>
        <SimpleForm>
            <div id={"versions_form_prototype"}>
                <NumberInput source={"id"} label={"Id"} disabled={true} />
                <TextInput source={"titre"} label={"Titre"} />
                <NumberInput source={"version_majeur"} label={"Version majeur"} />
                <NumberInput source={"version_mineur"} label={"Version mineur"} />
                <NumberInput source={"version_corrective"} label={"Version corrective"} />
                <DateTimeInput source={"date_version"} label={"Date de la version"} />
            </div>
            <div id={"version_form_contents"}>
                <ArrayInput source={"contents"}>
                    <SimpleFormIterator>
                        <div className={"d-flex gap-1"}>
                            <SelectInput
                                source="type_content"
                                label="Tye Content"
                                choices={type}
                                optionText="name"
                                optionValue="type"
                            />
                            <RichTextEditorInput source={"contents"} label={"Contenu"} />
                            <NumberInput source={"id_relatif"} label={"Id relatif"} />
                        </div>
                    </SimpleFormIterator>
                </ArrayInput>
            </div>
        </SimpleForm>
    </Edit>);
};

export const VersionManagerCreate = (props) => {
    
    
    return (<Create {...props} title="Créer une nouvelle version" resource={"versions"} redirect={"/admin/versions"}>
        <SimpleForm>
            <div id={"versions_form_prototype"}>
                <NumberInput source={"id"} label={"Id"} disabled={true} />
                <TextInput source={"titre"} label={"Titre"} />
                <NumberInput source={"version_majeur"} label={"Version majeur"} />
                <NumberInput source={"version_mineur"} label={"Version mineur"} />
                <NumberInput source={"version_corrective"} label={"Version corrective"} />
            </div>
            <div id={"version_form_contents"}>
                <ArrayInput source={"contents"}>
                    <SimpleFormIterator>
                        <div className={"d-flex gap-1"}>
                            <SelectInput
                                source="type_content"
                                label="Tye Content"
                                choices={type}
                                optionText="name"
                                optionValue="type"
                            />
                            <RichTextEditorInput source={"contents"} label={"Contenu"} />
                        </div>
                    </SimpleFormIterator>
                </ArrayInput>
            </div>
        </SimpleForm>
    </Create>);
};