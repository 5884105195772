import React                    from "react";
import ColorPickerGH            from "../../components/generality/ColorPickerGH";
import { ColorPickerItemProps } from "../../types/components/OptionsPerso/OptionsPersoType";
import TooltipGH                from "../utils/TooltipGH";


export default function ColorPickerItem({ label, color, onChangeColor, tooltip }: ColorPickerItemProps) {
    
    return <div className={"gestion_option_couleur_carte"}>
        {(tooltip) ? <span className={"d-flex gap-1 align-items-center"}>
            <span className={"option_specifique"}>{label}</span>
            <TooltipGH>
                <span className={"option_specifique infoBulle"}>
                    <i className="fa fa-circle-info"></i>
                </span>
                <span className="option_specifique infoHelpPerso">{tooltip}</span>
            </TooltipGH>
        </span> : <span>{label}</span>}
        <ColorPickerGH color={color} onChangeColor={onChangeColor} />
    </div>;
}
