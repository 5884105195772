import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AffNbrIcon }                                       from "../../../components/generality/ComposantGeneral";
import { OuvriersDTO }                                      from "../../../types/models/ouvriers.dto";
import { SacExpeditionnaireDTO }                            from "../../../types/models/sacExpeditionnaire.dto";
import { useTranslation }                                   from "react-i18next";
import { ExpeditionDTO }                                    from "../../../types/models/expedition.dto";
import Button                                               from "../../../components/utils/Button";

interface PopUpSacStockProps {
    ouvriers: OuvriersDTO[];
    expeditions: ExpeditionDTO[];
    onFermer: () => void;
}

export default function PopUpSacStock({ ouvriers, expeditions, onFermer }: PopUpSacStockProps) {
    const { t } = useTranslation();
    const [utilisationsSac, setUtilisationsSac] = useState<SacExpeditionnaireDTO[]>([]);
    
    // Fonction utilitaire pour agréger les sacs
    const aggregateSacs = useCallback((sacs: SacExpeditionnaireDTO[]) => {
        return sacs.reduce((acc, sac) => {
            const key = `${sac.item.id}-${sac.broken}`;
            if (acc[key]) {
                acc[key].nbr += sac.nbr;
            } else {
                acc[key] = { ...sac };
            }
            return acc;
        }, {} as Record<string, SacExpeditionnaireDTO>);
    }, []);
    
    useEffect(() => {
        // Extraire tous les sacs des ouvriers
        const ouvriersSacs = ouvriers.flatMap(ouvrier =>
            Object.values(ouvrier.sac),
        );
        
        // Extraire tous les sacs des expéditions
        const expeditionsSacs = expeditions.flatMap(expedition =>
            expedition.expedition_parts.flatMap(part =>
                part.expeditionnaires.flatMap(exped =>
                    Object.values(exped.sac),
                ),
            ),
        );
        
        // Combiner et agréger tous les sacs
        const allSacs = [...ouvriersSacs, ...expeditionsSacs];
        const aggregatedSacs = aggregateSacs(allSacs);
        
        setUtilisationsSac(Object.values(aggregatedSacs));
    }, [ouvriers, expeditions, aggregateSacs]);
    
    // Mémoriser le tri des sacs pour éviter des re-calculs inutiles
    const sortedUtilisationsSac = useMemo(() => {
        return [...utilisationsSac].sort((a, b) => {
            const aNom = t(a.item.nom, { ns: "items" });
            const bNom = t(b.item.nom, { ns: "items" });
            return aNom.localeCompare(bNom);
        });
    }, [utilisationsSac, t]);
    
    return (
        <div id="zoneInfoSacExpeOuvrier">
            <div>
                <h2 className={"mb-1 mt-1 text-center"}>{t("Totalités des objets dans les sacs", { ns: "outils" })}</h2>
            </div>
            <div className={"d-flex gap-1 justify-content-start align-items-center flex-wrap mb-2"}>
                {sortedUtilisationsSac.map((item) => (
                    <AffNbrIcon
                        item={item.item}
                        broken={item.broken}
                        nbr={item.nbr}
                        key={`obj_${item.item.id}_${item.broken ? 1 : 0}`}
                    />
                ))}
            </div>
            <div className={"d-flex gap-1 justify-content-center align-items-center flex-wrap mb-2"}>
                <Button taille={"xs"} couleur={"primary"} onClick={() => onFermer()}>{t("Fermer", { ns: "outils" })}</Button>
            </div>
        </div>
    );
}