import React, { useEffect, useState }          from "react";
import reactCSS                                from "reactcss";
import { ColorPicker, ColorService, useColor } from "react-color-palette";
import "react-color-palette/css";

interface ColorProps {
    color: string;
    onChangeColor: (color: string) => void,
}

export default function ColorPickerGH(props: ColorProps) {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useColor(props.color);
    const colorRgb = color.rgb;
    
    const handleClick = () => {
        setDisplayColorPicker(displayColorPicker => !displayColorPicker);
    };
    const handleClose = () => {
        setDisplayColorPicker(false);
    };
    const handleChange = (colorMod: any) => {
        setColor(colorMod);
        let colorModHex = colorMod.hex;
        if (colorModHex.length === 7) {
            colorModHex = colorMod.hex + "FF";
        }
        props.onChangeColor(colorModHex);
    };
    
    useEffect(() => {
        setColor(ColorService.convert("hex", props.color));
    }, [props.color]);
    
    const styles = reactCSS({
        "default": {
            color  : {
                width       : "35px",
                height      : "14px",
                border      : "1px solid black",
                borderRadius: "2px",
                background  : `rgba(${colorRgb.r}, ${colorRgb.g}, ${colorRgb.b}, ${colorRgb.a})`,
            },
            swatch : {
                padding     : "2px",
                background  : "#fff",
                borderRadius: "1px",
                boxShadow   : "0 0 0 1px rgba(0,0,0,.1)",
                display     : "inline-block",
                cursor      : "pointer",
            },
            popover: {
                position: "absolute" as "absolute",
                zIndex  : "200",
            },
            cover  : {
                position: "fixed" as "fixed",
                top     : "0px",
                right   : "0px",
                bottom  : "0px",
                left    : "0px",
            },
        },
    });
    
    return (
        <div>
            <div style={styles.swatch} onClick={handleClick}>
                <div style={styles.color} />
            </div>
            {displayColorPicker ? <div style={styles.popover}>
                <div style={styles.cover} onClick={handleClose} />
                <ColorPicker hideInput={["rgb", "hsv"]} color={color} onChange={handleChange} onChangeComplete={handleChange} />
            </div> : null}
        </div>
    );
}